import _process2 from "process";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var _process = _process2;
Object.defineProperty(exports, "__esModule", {
  value: !0
});

function __awaiter(a, b, c, d) {
  return new (c || (c = Promise))(function (e, f) {
    function g(a) {
      try {
        l(d.next(a));
      } catch (m) {
        f(m);
      }
    }

    function h(a) {
      try {
        l(d["throw"](a));
      } catch (m) {
        f(m);
      }
    }

    function l(a) {
      a.done ? e(a.value) : new c(function (b) {
        b(a.value);
      }).then(g, h);
    }

    l((d = d.apply(a, b || [])).next());
  });
}

function __generator(a, b) {
  function c(a) {
    return function (b) {
      return d([a, b]);
    };
  }

  function d(c) {
    if (f) throw new TypeError("Generator is already executing.");

    for (; e;) try {
      if (f = 1, g && (h = c[0] & 2 ? g["return"] : c[0] ? g["throw"] || ((h = g["return"]) && h.call(g), 0) : g.next) && !(h = h.call(g, c[1])).done) return h;
      if (g = 0, h) c = [c[0] & 2, h.value];

      switch (c[0]) {
        case 0:
        case 1:
          h = c;
          break;

        case 4:
          return e.label++, {
            value: c[1],
            done: !1
          };

        case 5:
          e.label++;
          g = c[1];
          c = [0];
          continue;

        case 7:
          c = e.ops.pop();
          e.trys.pop();
          continue;

        default:
          if (!(h = e.trys, h = 0 < h.length && h[h.length - 1]) && (6 === c[0] || 2 === c[0])) {
            e = 0;
            continue;
          }

          if (3 === c[0] && (!h || c[1] > h[0] && c[1] < h[3])) e.label = c[1];else if (6 === c[0] && e.label < h[1]) e.label = h[1], h = c;else if (h && e.label < h[2]) e.label = h[2], e.ops.push(c);else {
            h[2] && e.ops.pop();
            e.trys.pop();
            continue;
          }
      }

      c = b.call(a, e);
    } catch (m) {
      c = [6, m], g = 0;
    } finally {
      f = h = 0;
    }

    if (c[0] & 5) throw c[1];
    return {
      value: c[0] ? c[1] : void 0,
      done: !0
    };
  }

  var e = {
    label: 0,
    sent: function () {
      if (h[0] & 1) throw h[1];
      return h[1];
    },
    trys: [],
    ops: []
  },
      f,
      g,
      h,
      l;
  return l = {
    next: c(0),
    "throw": c(1),
    "return": c(2)
  }, "function" === typeof Symbol && (l[Symbol.iterator] = function () {
    return this;
  }), l;
}

function __values(a) {
  var b = "function" === typeof Symbol && a[Symbol.iterator],
      c = 0;
  return b ? b.call(a) : {
    next: function () {
      a && c >= a.length && (a = void 0);
      return {
        value: a && a[c++],
        done: !a
      };
    }
  };
}

function __read(a, b) {
  var c = "function" === typeof Symbol && a[Symbol.iterator];
  if (!c) return a;
  a = c.call(a);
  var d,
      e = [];

  try {
    for (; (void 0 === b || 0 < b--) && !(d = a.next()).done;) e.push(d.value);
  } catch (g) {
    var f = {
      error: g
    };
  } finally {
    try {
      d && !d.done && (c = a["return"]) && c.call(a);
    } finally {
      if (f) throw f.error;
    }
  }

  return e;
}

function __spread() {
  for (var a = [], b = 0; b < arguments.length; b++) a = a.concat(__read(arguments[b]));

  return a;
}

var commonjsGlobal = "undefined" !== typeof globalThis ? globalThis : "undefined" !== typeof window ? window : "undefined" !== typeof _global ? _global : "undefined" !== typeof self ? self : {};

function unwrapExports(a) {
  return a && a.__esModule && Object.prototype.hasOwnProperty.call(a, "default") ? a["default"] : a;
}

function createCommonjsModule(a, b) {
  return b = {
    exports: {}
  }, a(b, b.exports), b.exports;
}

var constants = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  b.constants = {
    O_RDONLY: 0,
    O_WRONLY: 1,
    O_RDWR: 2,
    S_IFMT: 61440,
    S_IFREG: 32768,
    S_IFDIR: 16384,
    S_IFCHR: 8192,
    S_IFBLK: 24576,
    S_IFIFO: 4096,
    S_IFLNK: 40960,
    S_IFSOCK: 49152,
    O_CREAT: 64,
    O_EXCL: 128,
    O_NOCTTY: 256,
    O_TRUNC: 512,
    O_APPEND: 1024,
    O_DIRECTORY: 65536,
    O_NOATIME: 262144,
    O_NOFOLLOW: 131072,
    O_SYNC: 1052672,
    O_DIRECT: 16384,
    O_NONBLOCK: 2048,
    S_IRWXU: 448,
    S_IRUSR: 256,
    S_IWUSR: 128,
    S_IXUSR: 64,
    S_IRWXG: 56,
    S_IRGRP: 32,
    S_IWGRP: 16,
    S_IXGRP: 8,
    S_IRWXO: 7,
    S_IROTH: 4,
    S_IWOTH: 2,
    S_IXOTH: 1,
    F_OK: 0,
    R_OK: 4,
    W_OK: 2,
    X_OK: 1,
    UV_FS_SYMLINK_DIR: 1,
    UV_FS_SYMLINK_JUNCTION: 2,
    UV_FS_COPYFILE_EXCL: 1,
    UV_FS_COPYFILE_FICLONE: 2,
    UV_FS_COPYFILE_FICLONE_FORCE: 4,
    COPYFILE_EXCL: 1,
    COPYFILE_FICLONE: 2,
    COPYFILE_FICLONE_FORCE: 4
  };
});
unwrapExports(constants);
var constants_1 = constants.constants,
    getBigInt = createCommonjsModule(function (a, b) {
  b.default = "function" === typeof BigInt ? BigInt : function () {
    throw Error("BigInt is not supported in this environment.");
  };
}),
    Stats_1 = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  var c = constants.constants.S_IFMT,
      d = constants.constants.S_IFDIR,
      e = constants.constants.S_IFREG,
      f = constants.constants.S_IFBLK,
      g = constants.constants.S_IFCHR,
      h = constants.constants.S_IFLNK,
      l = constants.constants.S_IFIFO,
      k = constants.constants.S_IFSOCK;

  a = function () {
    function a() {}

    a.build = function (b, c) {
      void 0 === c && (c = !1);
      var d = new a(),
          e = b.gid,
          f = b.atime,
          g = b.mtime,
          k = b.ctime;
      c = c ? getBigInt.default : function (a) {
        return a;
      };
      d.uid = c(b.uid);
      d.gid = c(e);
      d.rdev = c(0);
      d.blksize = c(4096);
      d.ino = c(b.ino);
      d.size = c(b.getSize());
      d.blocks = c(1);
      d.atime = f;
      d.mtime = g;
      d.ctime = k;
      d.birthtime = k;
      d.atimeMs = c(f.getTime());
      d.mtimeMs = c(g.getTime());
      e = c(k.getTime());
      d.ctimeMs = e;
      d.birthtimeMs = e;
      d.dev = c(0);
      d.mode = c(b.mode);
      d.nlink = c(b.nlink);
      return d;
    };

    a.prototype._checkModeProperty = function (a) {
      return (Number(this.mode) & c) === a;
    };

    a.prototype.isDirectory = function () {
      return this._checkModeProperty(d);
    };

    a.prototype.isFile = function () {
      return this._checkModeProperty(e);
    };

    a.prototype.isBlockDevice = function () {
      return this._checkModeProperty(f);
    };

    a.prototype.isCharacterDevice = function () {
      return this._checkModeProperty(g);
    };

    a.prototype.isSymbolicLink = function () {
      return this._checkModeProperty(h);
    };

    a.prototype.isFIFO = function () {
      return this._checkModeProperty(l);
    };

    a.prototype.isSocket = function () {
      return this._checkModeProperty(k);
    };

    return a;
  }();

  b.Stats = a;
  b.default = a;
});
unwrapExports(Stats_1);
var Stats_2 = Stats_1.Stats,
    global$1 = "undefined" !== typeof _global ? _global : "undefined" !== typeof self ? self : "undefined" !== typeof window ? window : {},
    lookup = [],
    revLookup = [],
    Arr = "undefined" !== typeof Uint8Array ? Uint8Array : Array,
    inited = !1;

function init() {
  inited = !0;

  for (var a = 0; 64 > a; ++a) lookup[a] = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/"[a], revLookup["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charCodeAt(a)] = a;

  revLookup[45] = 62;
  revLookup[95] = 63;
}

function toByteArray(a) {
  inited || init();
  var b = a.length;
  if (0 < b % 4) throw Error("Invalid string. Length must be a multiple of 4");
  var c = "=" === a[b - 2] ? 2 : "=" === a[b - 1] ? 1 : 0;
  var d = new Arr(3 * b / 4 - c);
  var e = 0 < c ? b - 4 : b;
  var f = 0;

  for (b = 0; b < e; b += 4) {
    var g = revLookup[a.charCodeAt(b)] << 18 | revLookup[a.charCodeAt(b + 1)] << 12 | revLookup[a.charCodeAt(b + 2)] << 6 | revLookup[a.charCodeAt(b + 3)];
    d[f++] = g >> 16 & 255;
    d[f++] = g >> 8 & 255;
    d[f++] = g & 255;
  }

  2 === c ? (g = revLookup[a.charCodeAt(b)] << 2 | revLookup[a.charCodeAt(b + 1)] >> 4, d[f++] = g & 255) : 1 === c && (g = revLookup[a.charCodeAt(b)] << 10 | revLookup[a.charCodeAt(b + 1)] << 4 | revLookup[a.charCodeAt(b + 2)] >> 2, d[f++] = g >> 8 & 255, d[f++] = g & 255);
  return d;
}

function tripletToBase64(a) {
  return lookup[a >> 18 & 63] + lookup[a >> 12 & 63] + lookup[a >> 6 & 63] + lookup[a & 63];
}

function encodeChunk(a, b, c) {
  for (var d = [], e = b; e < c; e += 3) b = (a[e] << 16) + (a[e + 1] << 8) + a[e + 2], d.push(tripletToBase64(b));

  return d.join("");
}

function fromByteArray(a) {
  inited || init();

  for (var b = a.length, c = b % 3, d = "", e = [], f = 0, g = b - c; f < g; f += 16383) e.push(encodeChunk(a, f, f + 16383 > g ? g : f + 16383));

  1 === c ? (a = a[b - 1], d += lookup[a >> 2], d += lookup[a << 4 & 63], d += "==") : 2 === c && (a = (a[b - 2] << 8) + a[b - 1], d += lookup[a >> 10], d += lookup[a >> 4 & 63], d += lookup[a << 2 & 63], d += "=");
  e.push(d);
  return e.join("");
}

function read(a, b, c, d, e) {
  var f = 8 * e - d - 1;
  var g = (1 << f) - 1,
      h = g >> 1,
      l = -7;
  e = c ? e - 1 : 0;
  var k = c ? -1 : 1,
      m = a[b + e];
  e += k;
  c = m & (1 << -l) - 1;
  m >>= -l;

  for (l += f; 0 < l; c = 256 * c + a[b + e], e += k, l -= 8);

  f = c & (1 << -l) - 1;
  c >>= -l;

  for (l += d; 0 < l; f = 256 * f + a[b + e], e += k, l -= 8);

  if (0 === c) c = 1 - h;else {
    if (c === g) return f ? NaN : Infinity * (m ? -1 : 1);
    f += Math.pow(2, d);
    c -= h;
  }
  return (m ? -1 : 1) * f * Math.pow(2, c - d);
}

function write(a, b, c, d, e, f) {
  var g,
      h = 8 * f - e - 1,
      l = (1 << h) - 1,
      k = l >> 1,
      m = 23 === e ? Math.pow(2, -24) - Math.pow(2, -77) : 0;
  f = d ? 0 : f - 1;
  var p = d ? 1 : -1,
      r = 0 > b || 0 === b && 0 > 1 / b ? 1 : 0;
  b = Math.abs(b);
  isNaN(b) || Infinity === b ? (b = isNaN(b) ? 1 : 0, d = l) : (d = Math.floor(Math.log(b) / Math.LN2), 1 > b * (g = Math.pow(2, -d)) && (d--, g *= 2), b = 1 <= d + k ? b + m / g : b + m * Math.pow(2, 1 - k), 2 <= b * g && (d++, g /= 2), d + k >= l ? (b = 0, d = l) : 1 <= d + k ? (b = (b * g - 1) * Math.pow(2, e), d += k) : (b = b * Math.pow(2, k - 1) * Math.pow(2, e), d = 0));

  for (; 8 <= e; a[c + f] = b & 255, f += p, b /= 256, e -= 8);

  d = d << e | b;

  for (h += e; 0 < h; a[c + f] = d & 255, f += p, d /= 256, h -= 8);

  a[c + f - p] |= 128 * r;
}

var toString = {}.toString,
    isArray = Array.isArray || function (a) {
  return "[object Array]" == toString.call(a);
},
    INSPECT_MAX_BYTES = 50;

Buffer.TYPED_ARRAY_SUPPORT = void 0 !== global$1.TYPED_ARRAY_SUPPORT ? global$1.TYPED_ARRAY_SUPPORT : !0;

var _kMaxLength = kMaxLength();

function kMaxLength() {
  return Buffer.TYPED_ARRAY_SUPPORT ? 2147483647 : 1073741823;
}

function createBuffer(a, b) {
  if (kMaxLength() < b) throw new RangeError("Invalid typed array length");
  Buffer.TYPED_ARRAY_SUPPORT ? (a = new Uint8Array(b), a.__proto__ = Buffer.prototype) : (null === a && (a = new Buffer(b)), a.length = b);
  return a;
}

function Buffer(a, b, c) {
  if (!(Buffer.TYPED_ARRAY_SUPPORT || this instanceof Buffer)) return new Buffer(a, b, c);

  if ("number" === typeof a) {
    if ("string" === typeof b) throw Error("If encoding is specified then the first argument must be a string");
    return allocUnsafe(this, a);
  }

  return from(this, a, b, c);
}

Buffer.poolSize = 8192;

Buffer._augment = function (a) {
  a.__proto__ = Buffer.prototype;
  return a;
};

function from(a, b, c, d) {
  if ("number" === typeof b) throw new TypeError("\"value\" argument must not be a number");
  return "undefined" !== typeof ArrayBuffer && b instanceof ArrayBuffer ? fromArrayBuffer(a, b, c, d) : "string" === typeof b ? fromString(a, b, c) : fromObject(a, b);
}

Buffer.from = function (a, b, c) {
  return from(null, a, b, c);
};

Buffer.TYPED_ARRAY_SUPPORT && (Buffer.prototype.__proto__ = Uint8Array.prototype, Buffer.__proto__ = Uint8Array);

function assertSize(a) {
  if ("number" !== typeof a) throw new TypeError("\"size\" argument must be a number");
  if (0 > a) throw new RangeError("\"size\" argument must not be negative");
}

function alloc(a, b, c, d) {
  assertSize(b);
  return 0 >= b ? createBuffer(a, b) : void 0 !== c ? "string" === typeof d ? createBuffer(a, b).fill(c, d) : createBuffer(a, b).fill(c) : createBuffer(a, b);
}

Buffer.alloc = function (a, b, c) {
  return alloc(null, a, b, c);
};

function allocUnsafe(a, b) {
  assertSize(b);
  a = createBuffer(a, 0 > b ? 0 : checked(b) | 0);
  if (!Buffer.TYPED_ARRAY_SUPPORT) for (var c = 0; c < b; ++c) a[c] = 0;
  return a;
}

Buffer.allocUnsafe = function (a) {
  return allocUnsafe(null, a);
};

Buffer.allocUnsafeSlow = function (a) {
  return allocUnsafe(null, a);
};

function fromString(a, b, c) {
  if ("string" !== typeof c || "" === c) c = "utf8";
  if (!Buffer.isEncoding(c)) throw new TypeError("\"encoding\" must be a valid string encoding");
  var d = byteLength(b, c) | 0;
  a = createBuffer(a, d);
  b = a.write(b, c);
  b !== d && (a = a.slice(0, b));
  return a;
}

function fromArrayLike(a, b) {
  var c = 0 > b.length ? 0 : checked(b.length) | 0;
  a = createBuffer(a, c);

  for (var d = 0; d < c; d += 1) a[d] = b[d] & 255;

  return a;
}

function fromArrayBuffer(a, b, c, d) {
  b.byteLength;
  if (0 > c || b.byteLength < c) throw new RangeError("'offset' is out of bounds");
  if (b.byteLength < c + (d || 0)) throw new RangeError("'length' is out of bounds");
  b = void 0 === c && void 0 === d ? new Uint8Array(b) : void 0 === d ? new Uint8Array(b, c) : new Uint8Array(b, c, d);
  Buffer.TYPED_ARRAY_SUPPORT ? (a = b, a.__proto__ = Buffer.prototype) : a = fromArrayLike(a, b);
  return a;
}

function fromObject(a, b) {
  if (internalIsBuffer(b)) {
    var c = checked(b.length) | 0;
    a = createBuffer(a, c);
    if (0 === a.length) return a;
    b.copy(a, 0, 0, c);
    return a;
  }

  if (b) {
    if ("undefined" !== typeof ArrayBuffer && b.buffer instanceof ArrayBuffer || "length" in b) return "number" !== typeof b.length || isnan(b.length) ? createBuffer(a, 0) : fromArrayLike(a, b);
    if ("Buffer" === b.type && isArray(b.data)) return fromArrayLike(a, b.data);
  }

  throw new TypeError("First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.");
}

function checked(a) {
  if (a >= kMaxLength()) throw new RangeError("Attempt to allocate Buffer larger than maximum size: 0x" + kMaxLength().toString(16) + " bytes");
  return a | 0;
}

function SlowBuffer(a) {
  +a != a && (a = 0);
  return Buffer.alloc(+a);
}

Buffer.isBuffer = isBuffer;

function internalIsBuffer(a) {
  return !(null == a || !a._isBuffer);
}

Buffer.compare = function (a, b) {
  if (!internalIsBuffer(a) || !internalIsBuffer(b)) throw new TypeError("Arguments must be Buffers");
  if (a === b) return 0;

  for (var c = a.length, d = b.length, e = 0, f = Math.min(c, d); e < f; ++e) if (a[e] !== b[e]) {
    c = a[e];
    d = b[e];
    break;
  }

  return c < d ? -1 : d < c ? 1 : 0;
};

Buffer.isEncoding = function (a) {
  switch (String(a).toLowerCase()) {
    case "hex":
    case "utf8":
    case "utf-8":
    case "ascii":
    case "latin1":
    case "binary":
    case "base64":
    case "ucs2":
    case "ucs-2":
    case "utf16le":
    case "utf-16le":
      return !0;

    default:
      return !1;
  }
};

Buffer.concat = function (a, b) {
  if (!isArray(a)) throw new TypeError("\"list\" argument must be an Array of Buffers");
  if (0 === a.length) return Buffer.alloc(0);
  var c;
  if (void 0 === b) for (c = b = 0; c < a.length; ++c) b += a[c].length;
  b = Buffer.allocUnsafe(b);
  var d = 0;

  for (c = 0; c < a.length; ++c) {
    var e = a[c];
    if (!internalIsBuffer(e)) throw new TypeError("\"list\" argument must be an Array of Buffers");
    e.copy(b, d);
    d += e.length;
  }

  return b;
};

function byteLength(a, b) {
  if (internalIsBuffer(a)) return a.length;
  if ("undefined" !== typeof ArrayBuffer && "function" === typeof ArrayBuffer.isView && (ArrayBuffer.isView(a) || a instanceof ArrayBuffer)) return a.byteLength;
  "string" !== typeof a && (a = "" + a);
  var c = a.length;
  if (0 === c) return 0;

  for (var d = !1;;) switch (b) {
    case "ascii":
    case "latin1":
    case "binary":
      return c;

    case "utf8":
    case "utf-8":
    case void 0:
      return utf8ToBytes(a).length;

    case "ucs2":
    case "ucs-2":
    case "utf16le":
    case "utf-16le":
      return 2 * c;

    case "hex":
      return c >>> 1;

    case "base64":
      return base64ToBytes(a).length;

    default:
      if (d) return utf8ToBytes(a).length;
      b = ("" + b).toLowerCase();
      d = !0;
  }
}

Buffer.byteLength = byteLength;

function slowToString(a, b, c) {
  var d = !1;
  if (void 0 === b || 0 > b) b = 0;
  if (b > this.length) return "";
  if (void 0 === c || c > this.length) c = this.length;
  if (0 >= c) return "";
  c >>>= 0;
  b >>>= 0;
  if (c <= b) return "";

  for (a || (a = "utf8");;) switch (a) {
    case "hex":
      return hexSlice(this, b, c);

    case "utf8":
    case "utf-8":
      return utf8Slice(this, b, c);

    case "ascii":
      return asciiSlice(this, b, c);

    case "latin1":
    case "binary":
      return latin1Slice(this, b, c);

    case "base64":
      return base64Slice(this, b, c);

    case "ucs2":
    case "ucs-2":
    case "utf16le":
    case "utf-16le":
      return utf16leSlice(this, b, c);

    default:
      if (d) throw new TypeError("Unknown encoding: " + a);
      a = (a + "").toLowerCase();
      d = !0;
  }
}

Buffer.prototype._isBuffer = !0;

function swap(a, b, c) {
  var d = a[b];
  a[b] = a[c];
  a[c] = d;
}

Buffer.prototype.swap16 = function () {
  var a = this.length;
  if (0 !== a % 2) throw new RangeError("Buffer size must be a multiple of 16-bits");

  for (var b = 0; b < a; b += 2) swap(this, b, b + 1);

  return this;
};

Buffer.prototype.swap32 = function () {
  var a = this.length;
  if (0 !== a % 4) throw new RangeError("Buffer size must be a multiple of 32-bits");

  for (var b = 0; b < a; b += 4) swap(this, b, b + 3), swap(this, b + 1, b + 2);

  return this;
};

Buffer.prototype.swap64 = function () {
  var a = this.length;
  if (0 !== a % 8) throw new RangeError("Buffer size must be a multiple of 64-bits");

  for (var b = 0; b < a; b += 8) swap(this, b, b + 7), swap(this, b + 1, b + 6), swap(this, b + 2, b + 5), swap(this, b + 3, b + 4);

  return this;
};

Buffer.prototype.toString = function () {
  var a = this.length | 0;
  return 0 === a ? "" : 0 === arguments.length ? utf8Slice(this, 0, a) : slowToString.apply(this, arguments);
};

Buffer.prototype.equals = function (a) {
  if (!internalIsBuffer(a)) throw new TypeError("Argument must be a Buffer");
  return this === a ? !0 : 0 === Buffer.compare(this, a);
};

Buffer.prototype.inspect = function () {
  var a = "",
      b = INSPECT_MAX_BYTES;
  0 < this.length && (a = this.toString("hex", 0, b).match(/.{2}/g).join(" "), this.length > b && (a += " ... "));
  return "<Buffer " + a + ">";
};

Buffer.prototype.compare = function (a, b, c, d, e) {
  if (!internalIsBuffer(a)) throw new TypeError("Argument must be a Buffer");
  void 0 === b && (b = 0);
  void 0 === c && (c = a ? a.length : 0);
  void 0 === d && (d = 0);
  void 0 === e && (e = this.length);
  if (0 > b || c > a.length || 0 > d || e > this.length) throw new RangeError("out of range index");
  if (d >= e && b >= c) return 0;
  if (d >= e) return -1;
  if (b >= c) return 1;
  b >>>= 0;
  c >>>= 0;
  d >>>= 0;
  e >>>= 0;
  if (this === a) return 0;
  var f = e - d,
      g = c - b,
      h = Math.min(f, g);
  d = this.slice(d, e);
  a = a.slice(b, c);

  for (b = 0; b < h; ++b) if (d[b] !== a[b]) {
    f = d[b];
    g = a[b];
    break;
  }

  return f < g ? -1 : g < f ? 1 : 0;
};

function bidirectionalIndexOf(a, b, c, d, e) {
  if (0 === a.length) return -1;
  "string" === typeof c ? (d = c, c = 0) : 2147483647 < c ? c = 2147483647 : -2147483648 > c && (c = -2147483648);
  c = +c;
  isNaN(c) && (c = e ? 0 : a.length - 1);
  0 > c && (c = a.length + c);

  if (c >= a.length) {
    if (e) return -1;
    c = a.length - 1;
  } else if (0 > c) if (e) c = 0;else return -1;

  "string" === typeof b && (b = Buffer.from(b, d));
  if (internalIsBuffer(b)) return 0 === b.length ? -1 : arrayIndexOf(a, b, c, d, e);
  if ("number" === typeof b) return b &= 255, Buffer.TYPED_ARRAY_SUPPORT && "function" === typeof Uint8Array.prototype.indexOf ? e ? Uint8Array.prototype.indexOf.call(a, b, c) : Uint8Array.prototype.lastIndexOf.call(a, b, c) : arrayIndexOf(a, [b], c, d, e);
  throw new TypeError("val must be string, number or Buffer");
}

function arrayIndexOf(a, b, c, d, e) {
  function f(a, b) {
    return 1 === g ? a[b] : a.readUInt16BE(b * g);
  }

  var g = 1,
      h = a.length,
      l = b.length;

  if (void 0 !== d && (d = String(d).toLowerCase(), "ucs2" === d || "ucs-2" === d || "utf16le" === d || "utf-16le" === d)) {
    if (2 > a.length || 2 > b.length) return -1;
    g = 2;
    h /= 2;
    l /= 2;
    c /= 2;
  }

  if (e) {
    for (d = -1; c < h; c++) if (f(a, c) === f(b, -1 === d ? 0 : c - d)) {
      if (-1 === d && (d = c), c - d + 1 === l) return d * g;
    } else -1 !== d && (c -= c - d), d = -1;
  } else for (c + l > h && (c = h - l); 0 <= c; c--) {
    h = !0;

    for (d = 0; d < l; d++) if (f(a, c + d) !== f(b, d)) {
      h = !1;
      break;
    }

    if (h) return c;
  }
  return -1;
}

Buffer.prototype.includes = function (a, b, c) {
  return -1 !== this.indexOf(a, b, c);
};

Buffer.prototype.indexOf = function (a, b, c) {
  return bidirectionalIndexOf(this, a, b, c, !0);
};

Buffer.prototype.lastIndexOf = function (a, b, c) {
  return bidirectionalIndexOf(this, a, b, c, !1);
};

function hexWrite(a, b, c, d) {
  c = Number(c) || 0;
  var e = a.length - c;
  d ? (d = Number(d), d > e && (d = e)) : d = e;
  e = b.length;
  if (0 !== e % 2) throw new TypeError("Invalid hex string");
  d > e / 2 && (d = e / 2);

  for (e = 0; e < d; ++e) {
    var f = parseInt(b.substr(2 * e, 2), 16);
    if (isNaN(f)) break;
    a[c + e] = f;
  }

  return e;
}

function utf8Write(a, b, c, d) {
  return blitBuffer(utf8ToBytes(b, a.length - c), a, c, d);
}

function asciiWrite(a, b, c, d) {
  return blitBuffer(asciiToBytes(b), a, c, d);
}

function latin1Write(a, b, c, d) {
  return asciiWrite(a, b, c, d);
}

function base64Write(a, b, c, d) {
  return blitBuffer(base64ToBytes(b), a, c, d);
}

function ucs2Write(a, b, c, d) {
  return blitBuffer(utf16leToBytes(b, a.length - c), a, c, d);
}

Buffer.prototype.write = function (a, b, c, d) {
  if (void 0 === b) d = "utf8", c = this.length, b = 0;else if (void 0 === c && "string" === typeof b) d = b, c = this.length, b = 0;else if (isFinite(b)) b |= 0, isFinite(c) ? (c |= 0, void 0 === d && (d = "utf8")) : (d = c, c = void 0);else throw Error("Buffer.write(string, encoding, offset[, length]) is no longer supported");
  var e = this.length - b;
  if (void 0 === c || c > e) c = e;
  if (0 < a.length && (0 > c || 0 > b) || b > this.length) throw new RangeError("Attempt to write outside buffer bounds");
  d || (d = "utf8");

  for (e = !1;;) switch (d) {
    case "hex":
      return hexWrite(this, a, b, c);

    case "utf8":
    case "utf-8":
      return utf8Write(this, a, b, c);

    case "ascii":
      return asciiWrite(this, a, b, c);

    case "latin1":
    case "binary":
      return latin1Write(this, a, b, c);

    case "base64":
      return base64Write(this, a, b, c);

    case "ucs2":
    case "ucs-2":
    case "utf16le":
    case "utf-16le":
      return ucs2Write(this, a, b, c);

    default:
      if (e) throw new TypeError("Unknown encoding: " + d);
      d = ("" + d).toLowerCase();
      e = !0;
  }
};

Buffer.prototype.toJSON = function () {
  return {
    type: "Buffer",
    data: Array.prototype.slice.call(this._arr || this, 0)
  };
};

function base64Slice(a, b, c) {
  return 0 === b && c === a.length ? fromByteArray(a) : fromByteArray(a.slice(b, c));
}

function utf8Slice(a, b, c) {
  c = Math.min(a.length, c);

  for (var d = []; b < c;) {
    var e = a[b],
        f = null,
        g = 239 < e ? 4 : 223 < e ? 3 : 191 < e ? 2 : 1;
    if (b + g <= c) switch (g) {
      case 1:
        128 > e && (f = e);
        break;

      case 2:
        var h = a[b + 1];
        128 === (h & 192) && (e = (e & 31) << 6 | h & 63, 127 < e && (f = e));
        break;

      case 3:
        h = a[b + 1];
        var l = a[b + 2];
        128 === (h & 192) && 128 === (l & 192) && (e = (e & 15) << 12 | (h & 63) << 6 | l & 63, 2047 < e && (55296 > e || 57343 < e) && (f = e));
        break;

      case 4:
        h = a[b + 1];
        l = a[b + 2];
        var k = a[b + 3];
        128 === (h & 192) && 128 === (l & 192) && 128 === (k & 192) && (e = (e & 15) << 18 | (h & 63) << 12 | (l & 63) << 6 | k & 63, 65535 < e && 1114112 > e && (f = e));
    }
    null === f ? (f = 65533, g = 1) : 65535 < f && (f -= 65536, d.push(f >>> 10 & 1023 | 55296), f = 56320 | f & 1023);
    d.push(f);
    b += g;
  }

  return decodeCodePointsArray(d);
}

var MAX_ARGUMENTS_LENGTH = 4096;

function decodeCodePointsArray(a) {
  var b = a.length;
  if (b <= MAX_ARGUMENTS_LENGTH) return String.fromCharCode.apply(String, a);

  for (var c = "", d = 0; d < b;) c += String.fromCharCode.apply(String, a.slice(d, d += MAX_ARGUMENTS_LENGTH));

  return c;
}

function asciiSlice(a, b, c) {
  var d = "";

  for (c = Math.min(a.length, c); b < c; ++b) d += String.fromCharCode(a[b] & 127);

  return d;
}

function latin1Slice(a, b, c) {
  var d = "";

  for (c = Math.min(a.length, c); b < c; ++b) d += String.fromCharCode(a[b]);

  return d;
}

function hexSlice(a, b, c) {
  var d = a.length;
  if (!b || 0 > b) b = 0;
  if (!c || 0 > c || c > d) c = d;

  for (d = ""; b < c; ++b) d += toHex(a[b]);

  return d;
}

function utf16leSlice(a, b, c) {
  a = a.slice(b, c);
  b = "";

  for (c = 0; c < a.length; c += 2) b += String.fromCharCode(a[c] + 256 * a[c + 1]);

  return b;
}

Buffer.prototype.slice = function (a, b) {
  var c = this.length;
  a = ~~a;
  b = void 0 === b ? c : ~~b;
  0 > a ? (a += c, 0 > a && (a = 0)) : a > c && (a = c);
  0 > b ? (b += c, 0 > b && (b = 0)) : b > c && (b = c);
  b < a && (b = a);
  if (Buffer.TYPED_ARRAY_SUPPORT) b = this.subarray(a, b), b.__proto__ = Buffer.prototype;else {
    c = b - a;
    b = new Buffer(c, void 0);

    for (var d = 0; d < c; ++d) b[d] = this[d + a];
  }
  return b;
};

function checkOffset(a, b, c) {
  if (0 !== a % 1 || 0 > a) throw new RangeError("offset is not uint");
  if (a + b > c) throw new RangeError("Trying to access beyond buffer length");
}

Buffer.prototype.readUIntLE = function (a, b, c) {
  a |= 0;
  b |= 0;
  c || checkOffset(a, b, this.length);
  c = this[a];

  for (var d = 1, e = 0; ++e < b && (d *= 256);) c += this[a + e] * d;

  return c;
};

Buffer.prototype.readUIntBE = function (a, b, c) {
  a |= 0;
  b |= 0;
  c || checkOffset(a, b, this.length);
  c = this[a + --b];

  for (var d = 1; 0 < b && (d *= 256);) c += this[a + --b] * d;

  return c;
};

Buffer.prototype.readUInt8 = function (a, b) {
  b || checkOffset(a, 1, this.length);
  return this[a];
};

Buffer.prototype.readUInt16LE = function (a, b) {
  b || checkOffset(a, 2, this.length);
  return this[a] | this[a + 1] << 8;
};

Buffer.prototype.readUInt16BE = function (a, b) {
  b || checkOffset(a, 2, this.length);
  return this[a] << 8 | this[a + 1];
};

Buffer.prototype.readUInt32LE = function (a, b) {
  b || checkOffset(a, 4, this.length);
  return (this[a] | this[a + 1] << 8 | this[a + 2] << 16) + 16777216 * this[a + 3];
};

Buffer.prototype.readUInt32BE = function (a, b) {
  b || checkOffset(a, 4, this.length);
  return 16777216 * this[a] + (this[a + 1] << 16 | this[a + 2] << 8 | this[a + 3]);
};

Buffer.prototype.readIntLE = function (a, b, c) {
  a |= 0;
  b |= 0;
  c || checkOffset(a, b, this.length);
  c = this[a];

  for (var d = 1, e = 0; ++e < b && (d *= 256);) c += this[a + e] * d;

  c >= 128 * d && (c -= Math.pow(2, 8 * b));
  return c;
};

Buffer.prototype.readIntBE = function (a, b, c) {
  a |= 0;
  b |= 0;
  c || checkOffset(a, b, this.length);
  c = b;

  for (var d = 1, e = this[a + --c]; 0 < c && (d *= 256);) e += this[a + --c] * d;

  e >= 128 * d && (e -= Math.pow(2, 8 * b));
  return e;
};

Buffer.prototype.readInt8 = function (a, b) {
  b || checkOffset(a, 1, this.length);
  return this[a] & 128 ? -1 * (255 - this[a] + 1) : this[a];
};

Buffer.prototype.readInt16LE = function (a, b) {
  b || checkOffset(a, 2, this.length);
  a = this[a] | this[a + 1] << 8;
  return a & 32768 ? a | 4294901760 : a;
};

Buffer.prototype.readInt16BE = function (a, b) {
  b || checkOffset(a, 2, this.length);
  a = this[a + 1] | this[a] << 8;
  return a & 32768 ? a | 4294901760 : a;
};

Buffer.prototype.readInt32LE = function (a, b) {
  b || checkOffset(a, 4, this.length);
  return this[a] | this[a + 1] << 8 | this[a + 2] << 16 | this[a + 3] << 24;
};

Buffer.prototype.readInt32BE = function (a, b) {
  b || checkOffset(a, 4, this.length);
  return this[a] << 24 | this[a + 1] << 16 | this[a + 2] << 8 | this[a + 3];
};

Buffer.prototype.readFloatLE = function (a, b) {
  b || checkOffset(a, 4, this.length);
  return read(this, a, !0, 23, 4);
};

Buffer.prototype.readFloatBE = function (a, b) {
  b || checkOffset(a, 4, this.length);
  return read(this, a, !1, 23, 4);
};

Buffer.prototype.readDoubleLE = function (a, b) {
  b || checkOffset(a, 8, this.length);
  return read(this, a, !0, 52, 8);
};

Buffer.prototype.readDoubleBE = function (a, b) {
  b || checkOffset(a, 8, this.length);
  return read(this, a, !1, 52, 8);
};

function checkInt(a, b, c, d, e, f) {
  if (!internalIsBuffer(a)) throw new TypeError("\"buffer\" argument must be a Buffer instance");
  if (b > e || b < f) throw new RangeError("\"value\" argument is out of bounds");
  if (c + d > a.length) throw new RangeError("Index out of range");
}

Buffer.prototype.writeUIntLE = function (a, b, c, d) {
  a = +a;
  b |= 0;
  c |= 0;
  d || checkInt(this, a, b, c, Math.pow(2, 8 * c) - 1, 0);
  d = 1;
  var e = 0;

  for (this[b] = a & 255; ++e < c && (d *= 256);) this[b + e] = a / d & 255;

  return b + c;
};

Buffer.prototype.writeUIntBE = function (a, b, c, d) {
  a = +a;
  b |= 0;
  c |= 0;
  d || checkInt(this, a, b, c, Math.pow(2, 8 * c) - 1, 0);
  d = c - 1;
  var e = 1;

  for (this[b + d] = a & 255; 0 <= --d && (e *= 256);) this[b + d] = a / e & 255;

  return b + c;
};

Buffer.prototype.writeUInt8 = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 1, 255, 0);
  Buffer.TYPED_ARRAY_SUPPORT || (a = Math.floor(a));
  this[b] = a & 255;
  return b + 1;
};

function objectWriteUInt16(a, b, c, d) {
  0 > b && (b = 65535 + b + 1);

  for (var e = 0, f = Math.min(a.length - c, 2); e < f; ++e) a[c + e] = (b & 255 << 8 * (d ? e : 1 - e)) >>> 8 * (d ? e : 1 - e);
}

Buffer.prototype.writeUInt16LE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 2, 65535, 0);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a & 255, this[b + 1] = a >>> 8) : objectWriteUInt16(this, a, b, !0);
  return b + 2;
};

Buffer.prototype.writeUInt16BE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 2, 65535, 0);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a >>> 8, this[b + 1] = a & 255) : objectWriteUInt16(this, a, b, !1);
  return b + 2;
};

function objectWriteUInt32(a, b, c, d) {
  0 > b && (b = 4294967295 + b + 1);

  for (var e = 0, f = Math.min(a.length - c, 4); e < f; ++e) a[c + e] = b >>> 8 * (d ? e : 3 - e) & 255;
}

Buffer.prototype.writeUInt32LE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 4, 4294967295, 0);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b + 3] = a >>> 24, this[b + 2] = a >>> 16, this[b + 1] = a >>> 8, this[b] = a & 255) : objectWriteUInt32(this, a, b, !0);
  return b + 4;
};

Buffer.prototype.writeUInt32BE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 4, 4294967295, 0);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a >>> 24, this[b + 1] = a >>> 16, this[b + 2] = a >>> 8, this[b + 3] = a & 255) : objectWriteUInt32(this, a, b, !1);
  return b + 4;
};

Buffer.prototype.writeIntLE = function (a, b, c, d) {
  a = +a;
  b |= 0;
  d || (d = Math.pow(2, 8 * c - 1), checkInt(this, a, b, c, d - 1, -d));
  d = 0;
  var e = 1,
      f = 0;

  for (this[b] = a & 255; ++d < c && (e *= 256);) 0 > a && 0 === f && 0 !== this[b + d - 1] && (f = 1), this[b + d] = (a / e >> 0) - f & 255;

  return b + c;
};

Buffer.prototype.writeIntBE = function (a, b, c, d) {
  a = +a;
  b |= 0;
  d || (d = Math.pow(2, 8 * c - 1), checkInt(this, a, b, c, d - 1, -d));
  d = c - 1;
  var e = 1,
      f = 0;

  for (this[b + d] = a & 255; 0 <= --d && (e *= 256);) 0 > a && 0 === f && 0 !== this[b + d + 1] && (f = 1), this[b + d] = (a / e >> 0) - f & 255;

  return b + c;
};

Buffer.prototype.writeInt8 = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 1, 127, -128);
  Buffer.TYPED_ARRAY_SUPPORT || (a = Math.floor(a));
  0 > a && (a = 255 + a + 1);
  this[b] = a & 255;
  return b + 1;
};

Buffer.prototype.writeInt16LE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 2, 32767, -32768);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a & 255, this[b + 1] = a >>> 8) : objectWriteUInt16(this, a, b, !0);
  return b + 2;
};

Buffer.prototype.writeInt16BE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 2, 32767, -32768);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a >>> 8, this[b + 1] = a & 255) : objectWriteUInt16(this, a, b, !1);
  return b + 2;
};

Buffer.prototype.writeInt32LE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 4, 2147483647, -2147483648);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a & 255, this[b + 1] = a >>> 8, this[b + 2] = a >>> 16, this[b + 3] = a >>> 24) : objectWriteUInt32(this, a, b, !0);
  return b + 4;
};

Buffer.prototype.writeInt32BE = function (a, b, c) {
  a = +a;
  b |= 0;
  c || checkInt(this, a, b, 4, 2147483647, -2147483648);
  0 > a && (a = 4294967295 + a + 1);
  Buffer.TYPED_ARRAY_SUPPORT ? (this[b] = a >>> 24, this[b + 1] = a >>> 16, this[b + 2] = a >>> 8, this[b + 3] = a & 255) : objectWriteUInt32(this, a, b, !1);
  return b + 4;
};

function checkIEEE754(a, b, c, d, e, f) {
  if (c + d > a.length) throw new RangeError("Index out of range");
  if (0 > c) throw new RangeError("Index out of range");
}

function writeFloat(a, b, c, d, e) {
  e || checkIEEE754(a, b, c, 4);
  write(a, b, c, d, 23, 4);
  return c + 4;
}

Buffer.prototype.writeFloatLE = function (a, b, c) {
  return writeFloat(this, a, b, !0, c);
};

Buffer.prototype.writeFloatBE = function (a, b, c) {
  return writeFloat(this, a, b, !1, c);
};

function writeDouble(a, b, c, d, e) {
  e || checkIEEE754(a, b, c, 8);
  write(a, b, c, d, 52, 8);
  return c + 8;
}

Buffer.prototype.writeDoubleLE = function (a, b, c) {
  return writeDouble(this, a, b, !0, c);
};

Buffer.prototype.writeDoubleBE = function (a, b, c) {
  return writeDouble(this, a, b, !1, c);
};

Buffer.prototype.copy = function (a, b, c, d) {
  c || (c = 0);
  d || 0 === d || (d = this.length);
  b >= a.length && (b = a.length);
  b || (b = 0);
  0 < d && d < c && (d = c);
  if (d === c || 0 === a.length || 0 === this.length) return 0;
  if (0 > b) throw new RangeError("targetStart out of bounds");
  if (0 > c || c >= this.length) throw new RangeError("sourceStart out of bounds");
  if (0 > d) throw new RangeError("sourceEnd out of bounds");
  d > this.length && (d = this.length);
  a.length - b < d - c && (d = a.length - b + c);
  var e = d - c;
  if (this === a && c < b && b < d) for (d = e - 1; 0 <= d; --d) a[d + b] = this[d + c];else if (1000 > e || !Buffer.TYPED_ARRAY_SUPPORT) for (d = 0; d < e; ++d) a[d + b] = this[d + c];else Uint8Array.prototype.set.call(a, this.subarray(c, c + e), b);
  return e;
};

Buffer.prototype.fill = function (a, b, c, d) {
  if ("string" === typeof a) {
    "string" === typeof b ? (d = b, b = 0, c = this.length) : "string" === typeof c && (d = c, c = this.length);

    if (1 === a.length) {
      var e = a.charCodeAt(0);
      256 > e && (a = e);
    }

    if (void 0 !== d && "string" !== typeof d) throw new TypeError("encoding must be a string");
    if ("string" === typeof d && !Buffer.isEncoding(d)) throw new TypeError("Unknown encoding: " + d);
  } else "number" === typeof a && (a &= 255);

  if (0 > b || this.length < b || this.length < c) throw new RangeError("Out of range index");
  if (c <= b) return this;
  b >>>= 0;
  c = void 0 === c ? this.length : c >>> 0;
  a || (a = 0);
  if ("number" === typeof a) for (d = b; d < c; ++d) this[d] = a;else for (a = internalIsBuffer(a) ? a : utf8ToBytes(new Buffer(a, d).toString()), e = a.length, d = 0; d < c - b; ++d) this[d + b] = a[d % e];
  return this;
};

var INVALID_BASE64_RE = /[^+\/0-9A-Za-z-_]/g;

function base64clean(a) {
  a = stringtrim(a).replace(INVALID_BASE64_RE, "");
  if (2 > a.length) return "";

  for (; 0 !== a.length % 4;) a += "=";

  return a;
}

function stringtrim(a) {
  return a.trim ? a.trim() : a.replace(/^\s+|\s+$/g, "");
}

function toHex(a) {
  return 16 > a ? "0" + a.toString(16) : a.toString(16);
}

function utf8ToBytes(a, b) {
  b = b || Infinity;

  for (var c, d = a.length, e = null, f = [], g = 0; g < d; ++g) {
    c = a.charCodeAt(g);

    if (55295 < c && 57344 > c) {
      if (!e) {
        if (56319 < c) {
          -1 < (b -= 3) && f.push(239, 191, 189);
          continue;
        } else if (g + 1 === d) {
          -1 < (b -= 3) && f.push(239, 191, 189);
          continue;
        }

        e = c;
        continue;
      }

      if (56320 > c) {
        -1 < (b -= 3) && f.push(239, 191, 189);
        e = c;
        continue;
      }

      c = (e - 55296 << 10 | c - 56320) + 65536;
    } else e && -1 < (b -= 3) && f.push(239, 191, 189);

    e = null;

    if (128 > c) {
      if (0 > --b) break;
      f.push(c);
    } else if (2048 > c) {
      if (0 > (b -= 2)) break;
      f.push(c >> 6 | 192, c & 63 | 128);
    } else if (65536 > c) {
      if (0 > (b -= 3)) break;
      f.push(c >> 12 | 224, c >> 6 & 63 | 128, c & 63 | 128);
    } else if (1114112 > c) {
      if (0 > (b -= 4)) break;
      f.push(c >> 18 | 240, c >> 12 & 63 | 128, c >> 6 & 63 | 128, c & 63 | 128);
    } else throw Error("Invalid code point");
  }

  return f;
}

function asciiToBytes(a) {
  for (var b = [], c = 0; c < a.length; ++c) b.push(a.charCodeAt(c) & 255);

  return b;
}

function utf16leToBytes(a, b) {
  for (var c, d, e = [], f = 0; f < a.length && !(0 > (b -= 2)); ++f) c = a.charCodeAt(f), d = c >> 8, c %= 256, e.push(c), e.push(d);

  return e;
}

function base64ToBytes(a) {
  return toByteArray(base64clean(a));
}

function blitBuffer(a, b, c, d) {
  for (var e = 0; e < d && !(e + c >= b.length || e >= a.length); ++e) b[e + c] = a[e];

  return e;
}

function isnan(a) {
  return a !== a;
}

function isBuffer(a) {
  return null != a && (!!a._isBuffer || isFastBuffer(a) || isSlowBuffer(a));
}

function isFastBuffer(a) {
  return !!a.constructor && "function" === typeof a.constructor.isBuffer && a.constructor.isBuffer(a);
}

function isSlowBuffer(a) {
  return "function" === typeof a.readFloatLE && "function" === typeof a.slice && isFastBuffer(a.slice(0, 0));
}

var bufferEs6 = Object.freeze({
  __proto__: null,
  INSPECT_MAX_BYTES,
  kMaxLength: _kMaxLength,
  Buffer,
  SlowBuffer,
  isBuffer
}),
    buffer = createCommonjsModule(function (a, b) {
  function c(a) {
    for (var b = [], c = 1; c < arguments.length; c++) b[c - 1] = arguments[c];

    return new (bufferEs6.Buffer.bind.apply(bufferEs6.Buffer, d([void 0, a], b)))();
  }

  var d = commonjsGlobal && commonjsGlobal.__spreadArrays || function () {
    for (var a = 0, b = 0, c = arguments.length; b < c; b++) a += arguments[b].length;

    a = Array(a);
    var d = 0;

    for (b = 0; b < c; b++) for (var l = arguments[b], k = 0, m = l.length; k < m; k++, d++) a[d] = l[k];

    return a;
  };

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  b.Buffer = bufferEs6.Buffer;
  b.bufferAllocUnsafe = bufferEs6.Buffer.allocUnsafe || c;
  b.bufferFrom = bufferEs6.Buffer.from || c;
});
unwrapExports(buffer);
var buffer_2 = buffer.Buffer,
    buffer_3 = buffer.bufferAllocUnsafe,
    buffer_4 = buffer.bufferFrom;

function defaultSetTimout() {
  throw Error("setTimeout has not been defined");
}

function defaultClearTimeout() {
  throw Error("clearTimeout has not been defined");
}

var cachedSetTimeout = defaultSetTimout,
    cachedClearTimeout = defaultClearTimeout;
"function" === typeof global$1.setTimeout && (cachedSetTimeout = setTimeout);
"function" === typeof global$1.clearTimeout && (cachedClearTimeout = clearTimeout);

function runTimeout(a) {
  if (cachedSetTimeout === setTimeout) return setTimeout(a, 0);
  if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) return cachedSetTimeout = setTimeout, setTimeout(a, 0);

  try {
    return cachedSetTimeout(a, 0);
  } catch (b) {
    try {
      return cachedSetTimeout.call(null, a, 0);
    } catch (c) {
      return cachedSetTimeout.call(this, a, 0);
    }
  }
}

function runClearTimeout(a) {
  if (cachedClearTimeout === clearTimeout) return clearTimeout(a);
  if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) return cachedClearTimeout = clearTimeout, clearTimeout(a);

  try {
    return cachedClearTimeout(a);
  } catch (b) {
    try {
      return cachedClearTimeout.call(null, a);
    } catch (c) {
      return cachedClearTimeout.call(this, a);
    }
  }
}

var queue = [],
    draining = !1,
    currentQueue,
    queueIndex = -1;

function cleanUpNextTick() {
  draining && currentQueue && (draining = !1, currentQueue.length ? queue = currentQueue.concat(queue) : queueIndex = -1, queue.length && drainQueue());
}

function drainQueue() {
  if (!draining) {
    var a = runTimeout(cleanUpNextTick);
    draining = !0;

    for (var b = queue.length; b;) {
      currentQueue = queue;

      for (queue = []; ++queueIndex < b;) currentQueue && currentQueue[queueIndex].run();

      queueIndex = -1;
      b = queue.length;
    }

    currentQueue = null;
    draining = !1;
    runClearTimeout(a);
  }
}

function nextTick(a) {
  var b = Array(arguments.length - 1);
  if (1 < arguments.length) for (var c = 1; c < arguments.length; c++) b[c - 1] = arguments[c];
  queue.push(new Item(a, b));
  1 !== queue.length || draining || runTimeout(drainQueue);
}

function Item(a, b) {
  this.fun = a;
  this.array = b;
}

Item.prototype.run = function () {
  this.fun.apply(null, this.array);
};

var title = "browser",
    platform = "browser",
    browser = !0,
    env = {},
    argv = [],
    version = "",
    versions = {},
    release = {},
    config = {};

function noop() {}

var on = noop,
    addListener = noop,
    once = noop,
    off = noop,
    removeListener = noop,
    removeAllListeners = noop,
    emit = noop;

function binding(a) {
  throw Error("process.binding is not supported");
}

function cwd() {
  return "/";
}

function chdir(a) {
  throw Error("process.chdir is not supported");
}

function umask() {
  return 0;
}

var performance = global$1.performance || {},
    performanceNow = performance.now || performance.mozNow || performance.msNow || performance.oNow || performance.webkitNow || function () {
  return new Date().getTime();
};

function hrtime(a) {
  var b = 0.001 * performanceNow.call(performance),
      c = Math.floor(b);
  b = Math.floor(b % 1 * 1000000000);
  a && (c -= a[0], b -= a[1], 0 > b && (c--, b += 1000000000));
  return [c, b];
}

var startTime = new Date();

function uptime() {
  return (new Date() - startTime) / 1000;
}

var process = {
  nextTick,
  title,
  browser,
  env,
  argv,
  version,
  versions,
  on,
  addListener,
  once,
  off,
  removeListener,
  removeAllListeners,
  emit,
  binding,
  cwd,
  chdir,
  umask,
  hrtime,
  platform,
  release,
  config,
  uptime
},
    inherits,
    inherits$1 = inherits = "function" === typeof Object.create ? function (a, b) {
  a.super_ = b;
  a.prototype = Object.create(b.prototype, {
    constructor: {
      value: a,
      enumerable: !1,
      writable: !0,
      configurable: !0
    }
  });
} : function (a, b) {
  a.super_ = b;

  var c = function () {};

  c.prototype = b.prototype;
  a.prototype = new c();
  a.prototype.constructor = a;
},
    formatRegExp = /%[sdj%]/g;

function format(a) {
  if (!isString(a)) {
    for (var b = [], c = 0; c < arguments.length; c++) b.push(inspect(arguments[c]));

    return b.join(" ");
  }

  c = 1;
  var d = arguments,
      e = d.length;
  b = String(a).replace(formatRegExp, function (a) {
    if ("%%" === a) return "%";
    if (c >= e) return a;

    switch (a) {
      case "%s":
        return String(d[c++]);

      case "%d":
        return Number(d[c++]);

      case "%j":
        try {
          return JSON.stringify(d[c++]);
        } catch (h) {
          return "[Circular]";
        }

      default:
        return a;
    }
  });

  for (var f = d[c]; c < e; f = d[++c]) b = isNull(f) || !isObject(f) ? b + (" " + f) : b + (" " + inspect(f));

  return b;
}

function deprecate(a, b) {
  if (isUndefined(global$1.process)) return function () {
    return deprecate(a, b).apply(this, arguments);
  };
  if (!0 === process.noDeprecation) return a;
  var c = !1;
  return function () {
    if (!c) {
      if (process.throwDeprecation) throw Error(b);
      process.traceDeprecation ? console.trace(b) : console.error(b);
      c = !0;
    }

    return a.apply(this, arguments);
  };
}

var debugs = {},
    debugEnviron;

function debuglog(a) {
  isUndefined(debugEnviron) && (debugEnviron = process.env.NODE_DEBUG || "");
  a = a.toUpperCase();
  debugs[a] || (new RegExp("\\b" + a + "\\b", "i").test(debugEnviron) ? debugs[a] = function () {
    var b = format.apply(null, arguments);
    console.error("%s %d: %s", a, 0, b);
  } : debugs[a] = function () {});
  return debugs[a];
}

function inspect(a, b) {
  var c = {
    seen: [],
    stylize: stylizeNoColor
  };
  3 <= arguments.length && (c.depth = arguments[2]);
  4 <= arguments.length && (c.colors = arguments[3]);
  isBoolean(b) ? c.showHidden = b : b && _extend(c, b);
  isUndefined(c.showHidden) && (c.showHidden = !1);
  isUndefined(c.depth) && (c.depth = 2);
  isUndefined(c.colors) && (c.colors = !1);
  isUndefined(c.customInspect) && (c.customInspect = !0);
  c.colors && (c.stylize = stylizeWithColor);
  return formatValue(c, a, c.depth);
}

inspect.colors = {
  bold: [1, 22],
  italic: [3, 23],
  underline: [4, 24],
  inverse: [7, 27],
  white: [37, 39],
  grey: [90, 39],
  black: [30, 39],
  blue: [34, 39],
  cyan: [36, 39],
  green: [32, 39],
  magenta: [35, 39],
  red: [31, 39],
  yellow: [33, 39]
};
inspect.styles = {
  special: "cyan",
  number: "yellow",
  "boolean": "yellow",
  undefined: "grey",
  "null": "bold",
  string: "green",
  date: "magenta",
  regexp: "red"
};

function stylizeWithColor(a, b) {
  return (b = inspect.styles[b]) ? "\x1B[" + inspect.colors[b][0] + "m" + a + "\x1B[" + inspect.colors[b][1] + "m" : a;
}

function stylizeNoColor(a, b) {
  return a;
}

function arrayToHash(a) {
  var b = {};
  a.forEach(function (a, d) {
    b[a] = !0;
  });
  return b;
}

function formatValue(a, b, c) {
  if (a.customInspect && b && isFunction(b.inspect) && b.inspect !== inspect && (!b.constructor || b.constructor.prototype !== b)) {
    var d = b.inspect(c, a);
    isString(d) || (d = formatValue(a, d, c));
    return d;
  }

  if (d = formatPrimitive(a, b)) return d;
  var e = Object.keys(b),
      f = arrayToHash(e);
  a.showHidden && (e = Object.getOwnPropertyNames(b));
  if (isError(b) && (0 <= e.indexOf("message") || 0 <= e.indexOf("description"))) return formatError(b);

  if (0 === e.length) {
    if (isFunction(b)) return a.stylize("[Function" + (b.name ? ": " + b.name : "") + "]", "special");
    if (isRegExp(b)) return a.stylize(RegExp.prototype.toString.call(b), "regexp");
    if (isDate(b)) return a.stylize(Date.prototype.toString.call(b), "date");
    if (isError(b)) return formatError(b);
  }

  d = "";
  var g = !1,
      h = ["{", "}"];
  isArray$1(b) && (g = !0, h = ["[", "]"]);
  isFunction(b) && (d = " [Function" + (b.name ? ": " + b.name : "") + "]");
  isRegExp(b) && (d = " " + RegExp.prototype.toString.call(b));
  isDate(b) && (d = " " + Date.prototype.toUTCString.call(b));
  isError(b) && (d = " " + formatError(b));
  if (0 === e.length && (!g || 0 == b.length)) return h[0] + d + h[1];
  if (0 > c) return isRegExp(b) ? a.stylize(RegExp.prototype.toString.call(b), "regexp") : a.stylize("[Object]", "special");
  a.seen.push(b);
  e = g ? formatArray(a, b, c, f, e) : e.map(function (d) {
    return formatProperty(a, b, c, f, d, g);
  });
  a.seen.pop();
  return reduceToSingleString(e, d, h);
}

function formatPrimitive(a, b) {
  if (isUndefined(b)) return a.stylize("undefined", "undefined");
  if (isString(b)) return b = "'" + JSON.stringify(b).replace(/^"|"$/g, "").replace(/'/g, "\\'").replace(/\\"/g, "\"") + "'", a.stylize(b, "string");
  if (isNumber(b)) return a.stylize("" + b, "number");
  if (isBoolean(b)) return a.stylize("" + b, "boolean");
  if (isNull(b)) return a.stylize("null", "null");
}

function formatError(a) {
  return "[" + Error.prototype.toString.call(a) + "]";
}

function formatArray(a, b, c, d, e) {
  for (var f = [], g = 0, h = b.length; g < h; ++g) hasOwnProperty(b, String(g)) ? f.push(formatProperty(a, b, c, d, String(g), !0)) : f.push("");

  e.forEach(function (e) {
    e.match(/^\d+$/) || f.push(formatProperty(a, b, c, d, e, !0));
  });
  return f;
}

function formatProperty(a, b, c, d, e, f) {
  var g, h;
  b = Object.getOwnPropertyDescriptor(b, e) || {
    value: b[e]
  };
  b.get ? h = b.set ? a.stylize("[Getter/Setter]", "special") : a.stylize("[Getter]", "special") : b.set && (h = a.stylize("[Setter]", "special"));
  hasOwnProperty(d, e) || (g = "[" + e + "]");
  h || (0 > a.seen.indexOf(b.value) ? (h = isNull(c) ? formatValue(a, b.value, null) : formatValue(a, b.value, c - 1), -1 < h.indexOf("\n") && (h = f ? h.split("\n").map(function (a) {
    return "  " + a;
  }).join("\n").substr(2) : "\n" + h.split("\n").map(function (a) {
    return "   " + a;
  }).join("\n"))) : h = a.stylize("[Circular]", "special"));

  if (isUndefined(g)) {
    if (f && e.match(/^\d+$/)) return h;
    g = JSON.stringify("" + e);
    g.match(/^"([a-zA-Z_][a-zA-Z_0-9]*)"$/) ? (g = g.substr(1, g.length - 2), g = a.stylize(g, "name")) : (g = g.replace(/'/g, "\\'").replace(/\\"/g, "\"").replace(/(^"|"$)/g, "'"), g = a.stylize(g, "string"));
  }

  return g + ": " + h;
}

function reduceToSingleString(a, b, c) {
  return 60 < a.reduce(function (a, b) {
    b.indexOf("\n");
    return a + b.replace(/\u001b\[\d\d?m/g, "").length + 1;
  }, 0) ? c[0] + ("" === b ? "" : b + "\n ") + " " + a.join(",\n  ") + " " + c[1] : c[0] + b + " " + a.join(", ") + " " + c[1];
}

function isArray$1(a) {
  return Array.isArray(a);
}

function isBoolean(a) {
  return "boolean" === typeof a;
}

function isNull(a) {
  return null === a;
}

function isNullOrUndefined(a) {
  return null == a;
}

function isNumber(a) {
  return "number" === typeof a;
}

function isString(a) {
  return "string" === typeof a;
}

function isSymbol(a) {
  return "symbol" === typeof a;
}

function isUndefined(a) {
  return void 0 === a;
}

function isRegExp(a) {
  return isObject(a) && "[object RegExp]" === objectToString(a);
}

function isObject(a) {
  return "object" === typeof a && null !== a;
}

function isDate(a) {
  return isObject(a) && "[object Date]" === objectToString(a);
}

function isError(a) {
  return isObject(a) && ("[object Error]" === objectToString(a) || a instanceof Error);
}

function isFunction(a) {
  return "function" === typeof a;
}

function isPrimitive(a) {
  return null === a || "boolean" === typeof a || "number" === typeof a || "string" === typeof a || "symbol" === typeof a || "undefined" === typeof a;
}

function isBuffer$1(a) {
  return isBuffer(a);
}

function objectToString(a) {
  return Object.prototype.toString.call(a);
}

function pad(a) {
  return 10 > a ? "0" + a.toString(10) : a.toString(10);
}

var months = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ");

function timestamp() {
  var a = new Date(),
      b = [pad(a.getHours()), pad(a.getMinutes()), pad(a.getSeconds())].join(":");
  return [a.getDate(), months[a.getMonth()], b].join(" ");
}

function log() {
  console.log("%s - %s", timestamp(), format.apply(null, arguments));
}

function _extend(a, b) {
  if (!b || !isObject(b)) return a;

  for (var c = Object.keys(b), d = c.length; d--;) a[c[d]] = b[c[d]];

  return a;
}

function hasOwnProperty(a, b) {
  return Object.prototype.hasOwnProperty.call(a, b);
}

var util = {
  inherits: inherits$1,
  _extend,
  log,
  isBuffer: isBuffer$1,
  isPrimitive,
  isFunction,
  isError,
  isDate,
  isObject,
  isRegExp,
  isUndefined,
  isSymbol,
  isString,
  isNumber,
  isNullOrUndefined,
  isNull,
  isBoolean,
  isArray: isArray$1,
  inspect,
  deprecate,
  format,
  debuglog
};

function compare(a, b) {
  if (a === b) return 0;

  for (var c = a.length, d = b.length, e = 0, f = Math.min(c, d); e < f; ++e) if (a[e] !== b[e]) {
    c = a[e];
    d = b[e];
    break;
  }

  return c < d ? -1 : d < c ? 1 : 0;
}

var hasOwn = Object.prototype.hasOwnProperty,
    objectKeys = Object.keys || function (a) {
  var b = [],
      c;

  for (c in a) hasOwn.call(a, c) && b.push(c);

  return b;
},
    pSlice = Array.prototype.slice,
    _functionsHaveNames;

function functionsHaveNames() {
  return "undefined" !== typeof _functionsHaveNames ? _functionsHaveNames : _functionsHaveNames = function () {
    return "foo" === function () {}.name;
  }();
}

function pToString(a) {
  return Object.prototype.toString.call(a);
}

function isView(a) {
  return isBuffer(a) || "function" !== typeof global$1.ArrayBuffer ? !1 : "function" === typeof ArrayBuffer.isView ? ArrayBuffer.isView(a) : a ? a instanceof DataView || a.buffer && a.buffer instanceof ArrayBuffer ? !0 : !1 : !1;
}

function assert(a, b) {
  a || fail(a, !0, b, "==", ok);
}

var regex = /\s*function\s+([^\(\s]*)\s*/;

function getName(a) {
  if (isFunction(a)) return functionsHaveNames() ? a.name : (a = a.toString().match(regex)) && a[1];
}

assert.AssertionError = AssertionError;

function AssertionError(a) {
  this.name = "AssertionError";
  this.actual = a.actual;
  this.expected = a.expected;
  this.operator = a.operator;
  a.message ? (this.message = a.message, this.generatedMessage = !1) : (this.message = getMessage(this), this.generatedMessage = !0);
  var b = a.stackStartFunction || fail;
  Error.captureStackTrace ? Error.captureStackTrace(this, b) : (a = Error(), a.stack && (a = a.stack, b = getName(b), b = a.indexOf("\n" + b), 0 <= b && (b = a.indexOf("\n", b + 1), a = a.substring(b + 1)), this.stack = a));
}

inherits$1(AssertionError, Error);

function truncate(a, b) {
  return "string" === typeof a ? a.length < b ? a : a.slice(0, b) : a;
}

function inspect$1(a) {
  if (functionsHaveNames() || !isFunction(a)) return inspect(a);
  a = getName(a);
  return "[Function" + (a ? ": " + a : "") + "]";
}

function getMessage(a) {
  return truncate(inspect$1(a.actual), 128) + " " + a.operator + " " + truncate(inspect$1(a.expected), 128);
}

function fail(a, b, c, d, e) {
  throw new AssertionError({
    message: c,
    actual: a,
    expected: b,
    operator: d,
    stackStartFunction: e
  });
}

assert.fail = fail;

function ok(a, b) {
  a || fail(a, !0, b, "==", ok);
}

assert.ok = ok;
assert.equal = equal;

function equal(a, b, c) {
  a != b && fail(a, b, c, "==", equal);
}

assert.notEqual = notEqual;

function notEqual(a, b, c) {
  a == b && fail(a, b, c, "!=", notEqual);
}

assert.deepEqual = deepEqual;

function deepEqual(a, b, c) {
  _deepEqual(a, b, !1) || fail(a, b, c, "deepEqual", deepEqual);
}

assert.deepStrictEqual = deepStrictEqual;

function deepStrictEqual(a, b, c) {
  _deepEqual(a, b, !0) || fail(a, b, c, "deepStrictEqual", deepStrictEqual);
}

function _deepEqual(a, b, c, d) {
  if (a === b) return !0;
  if (isBuffer(a) && isBuffer(b)) return 0 === compare(a, b);
  if (isDate(a) && isDate(b)) return a.getTime() === b.getTime();
  if (isRegExp(a) && isRegExp(b)) return a.source === b.source && a.global === b.global && a.multiline === b.multiline && a.lastIndex === b.lastIndex && a.ignoreCase === b.ignoreCase;

  if (null !== a && "object" === typeof a || null !== b && "object" === typeof b) {
    if (!isView(a) || !isView(b) || pToString(a) !== pToString(b) || a instanceof Float32Array || a instanceof Float64Array) {
      if (isBuffer(a) !== isBuffer(b)) return !1;
      d = d || {
        actual: [],
        expected: []
      };
      var e = d.actual.indexOf(a);
      if (-1 !== e && e === d.expected.indexOf(b)) return !0;
      d.actual.push(a);
      d.expected.push(b);
      return objEquiv(a, b, c, d);
    }

    return 0 === compare(new Uint8Array(a.buffer), new Uint8Array(b.buffer));
  }

  return c ? a === b : a == b;
}

function isArguments(a) {
  return "[object Arguments]" == Object.prototype.toString.call(a);
}

function objEquiv(a, b, c, d) {
  if (null === a || void 0 === a || null === b || void 0 === b) return !1;
  if (isPrimitive(a) || isPrimitive(b)) return a === b;
  if (c && Object.getPrototypeOf(a) !== Object.getPrototypeOf(b)) return !1;
  var e = isArguments(a),
      f = isArguments(b);
  if (e && !f || !e && f) return !1;
  if (e) return a = pSlice.call(a), b = pSlice.call(b), _deepEqual(a, b, c);
  e = objectKeys(a);
  var g = objectKeys(b);
  if (e.length !== g.length) return !1;
  e.sort();
  g.sort();

  for (f = e.length - 1; 0 <= f; f--) if (e[f] !== g[f]) return !1;

  for (f = e.length - 1; 0 <= f; f--) if (g = e[f], !_deepEqual(a[g], b[g], c, d)) return !1;

  return !0;
}

assert.notDeepEqual = notDeepEqual;

function notDeepEqual(a, b, c) {
  _deepEqual(a, b, !1) && fail(a, b, c, "notDeepEqual", notDeepEqual);
}

assert.notDeepStrictEqual = notDeepStrictEqual;

function notDeepStrictEqual(a, b, c) {
  _deepEqual(a, b, !0) && fail(a, b, c, "notDeepStrictEqual", notDeepStrictEqual);
}

assert.strictEqual = strictEqual;

function strictEqual(a, b, c) {
  a !== b && fail(a, b, c, "===", strictEqual);
}

assert.notStrictEqual = notStrictEqual;

function notStrictEqual(a, b, c) {
  a === b && fail(a, b, c, "!==", notStrictEqual);
}

function expectedException(a, b) {
  if (!a || !b) return !1;
  if ("[object RegExp]" == Object.prototype.toString.call(b)) return b.test(a);

  try {
    if (a instanceof b) return !0;
  } catch (c) {}

  return Error.isPrototypeOf(b) ? !1 : !0 === b.call({}, a);
}

function _tryBlock(a) {
  try {
    a();
  } catch (c) {
    var b = c;
  }

  return b;
}

function _throws(a, b, c, d) {
  if ("function" !== typeof b) throw new TypeError("\"block\" argument must be a function");
  "string" === typeof c && (d = c, c = null);
  b = _tryBlock(b);
  d = (c && c.name ? " (" + c.name + ")." : ".") + (d ? " " + d : ".");
  a && !b && fail(b, c, "Missing expected exception" + d);
  var e = "string" === typeof d,
      f = !a && isError(b),
      g = !a && b && !c;
  (f && e && expectedException(b, c) || g) && fail(b, c, "Got unwanted exception" + d);
  if (a && b && c && !expectedException(b, c) || !a && b) throw b;
}

assert.throws = throws;

function throws(a, b, c) {
  _throws(!0, a, b, c);
}

assert.doesNotThrow = doesNotThrow;

function doesNotThrow(a, b, c) {
  _throws(!1, a, b, c);
}

assert.ifError = ifError;

function ifError(a) {
  if (a) throw a;
}

var errors = createCommonjsModule(function (a, b) {
  function c(a) {
    return function (a) {
      function b(b) {
        for (var c = [], e = 1; e < arguments.length; e++) c[e - 1] = arguments[e];

        c = a.call(this, d(b, c)) || this;
        c.code = b;
        c[h] = b;
        c.name = a.prototype.name + " [" + c[h] + "]";
        return c;
      }

      g(b, a);
      return b;
    }(a);
  }

  function d(a, b) {
    assert.strictEqual(typeof a, "string");
    var c = l[a];
    assert(c, "An invalid error message key was used: " + a + ".");
    if ("function" === typeof c) a = c;else {
      a = util.format;
      if (void 0 === b || 0 === b.length) return c;
      b.unshift(c);
    }
    return String(a.apply(null, b));
  }

  function e(a, b) {
    l[a] = "function" === typeof b ? b : String(b);
  }

  function f(a, b) {
    assert(a, "expected is required");
    assert("string" === typeof b, "thing is required");

    if (Array.isArray(a)) {
      var c = a.length;
      assert(0 < c, "At least one expected value needs to be specified");
      a = a.map(function (a) {
        return String(a);
      });
      return 2 < c ? "one of " + b + " " + a.slice(0, c - 1).join(", ") + ", or " + a[c - 1] : 2 === c ? "one of " + b + " " + a[0] + " or " + a[1] : "of " + b + " " + a[0];
    }

    return "of " + b + " " + String(a);
  }

  var g = commonjsGlobal && commonjsGlobal.__extends || function () {
    var a = function (b, c) {
      a = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (a, b) {
        a.__proto__ = b;
      } || function (a, b) {
        for (var c in b) b.hasOwnProperty(c) && (a[c] = b[c]);
      };

      return a(b, c);
    };

    return function (b, c) {
      function d() {
        this.constructor = b;
      }

      a(b, c);
      b.prototype = null === c ? Object.create(c) : (d.prototype = c.prototype, new d());
    };
  }();

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  var h = "undefined" === typeof Symbol ? "_kCode" : Symbol("code"),
      l = {};

  a = function (a) {
    function c(c) {
      if ("object" !== typeof c || null === c) throw new b.TypeError("ERR_INVALID_ARG_TYPE", "options", "object");
      var d = c.message ? a.call(this, c.message) || this : a.call(this, util.inspect(c.actual).slice(0, 128) + " " + (c.operator + " " + util.inspect(c.expected).slice(0, 128))) || this;
      d.generatedMessage = !c.message;
      d.name = "AssertionError [ERR_ASSERTION]";
      d.code = "ERR_ASSERTION";
      d.actual = c.actual;
      d.expected = c.expected;
      d.operator = c.operator;
      b.Error.captureStackTrace(d, c.stackStartFunction);
      return d;
    }

    g(c, a);
    return c;
  }(commonjsGlobal.Error);

  b.AssertionError = a;
  b.message = d;
  b.E = e;
  b.Error = c(commonjsGlobal.Error);
  b.TypeError = c(commonjsGlobal.TypeError);
  b.RangeError = c(commonjsGlobal.RangeError);
  e("ERR_ARG_NOT_ITERABLE", "%s must be iterable");
  e("ERR_ASSERTION", "%s");
  e("ERR_BUFFER_OUT_OF_BOUNDS", function (a, b) {
    return b ? "Attempt to write outside buffer bounds" : "\"" + a + "\" is outside of buffer bounds";
  });
  e("ERR_CHILD_CLOSED_BEFORE_REPLY", "Child closed before reply received");
  e("ERR_CONSOLE_WRITABLE_STREAM", "Console expects a writable stream instance for %s");
  e("ERR_CPU_USAGE", "Unable to obtain cpu usage %s");
  e("ERR_DNS_SET_SERVERS_FAILED", function (a, b) {
    return "c-ares failed to set servers: \"" + a + "\" [" + b + "]";
  });
  e("ERR_FALSY_VALUE_REJECTION", "Promise was rejected with falsy value");
  e("ERR_ENCODING_NOT_SUPPORTED", function (a) {
    return "The \"" + a + "\" encoding is not supported";
  });
  e("ERR_ENCODING_INVALID_ENCODED_DATA", function (a) {
    return "The encoded data was not valid for encoding " + a;
  });
  e("ERR_HTTP_HEADERS_SENT", "Cannot render headers after they are sent to the client");
  e("ERR_HTTP_INVALID_STATUS_CODE", "Invalid status code: %s");
  e("ERR_HTTP_TRAILER_INVALID", "Trailers are invalid with this transfer encoding");
  e("ERR_INDEX_OUT_OF_RANGE", "Index out of range");
  e("ERR_INVALID_ARG_TYPE", function (a, b, c) {
    assert(a, "name is required");

    if (b.includes("not ")) {
      var d = "must not be";
      b = b.split("not ")[1];
    } else d = "must be";

    if (Array.isArray(a)) d = "The " + a.map(function (a) {
      return "\"" + a + "\"";
    }).join(", ") + " arguments " + d + " " + f(b, "type");else if (a.includes(" argument")) d = "The " + a + " " + d + " " + f(b, "type");else {
      var e = a.includes(".") ? "property" : "argument";
      d = "The \"" + a + "\" " + e + " " + d + " " + f(b, "type");
    }
    3 <= arguments.length && (d += ". Received type " + (null !== c ? typeof c : "null"));
    return d;
  });
  e("ERR_INVALID_ARRAY_LENGTH", function (a, b, c) {
    assert.strictEqual(typeof c, "number");
    return "The array \"" + a + "\" (length " + c + ") must be of length " + b + ".";
  });
  e("ERR_INVALID_BUFFER_SIZE", "Buffer size must be a multiple of %s");
  e("ERR_INVALID_CALLBACK", "Callback must be a function");
  e("ERR_INVALID_CHAR", "Invalid character in %s");
  e("ERR_INVALID_CURSOR_POS", "Cannot set cursor row without setting its column");
  e("ERR_INVALID_FD", "\"fd\" must be a positive integer: %s");
  e("ERR_INVALID_FILE_URL_HOST", "File URL host must be \"localhost\" or empty on %s");
  e("ERR_INVALID_FILE_URL_PATH", "File URL path %s");
  e("ERR_INVALID_HANDLE_TYPE", "This handle type cannot be sent");
  e("ERR_INVALID_IP_ADDRESS", "Invalid IP address: %s");
  e("ERR_INVALID_OPT_VALUE", function (a, b) {
    return "The value \"" + String(b) + "\" is invalid for option \"" + a + "\"";
  });
  e("ERR_INVALID_OPT_VALUE_ENCODING", function (a) {
    return "The value \"" + String(a) + "\" is invalid for option \"encoding\"";
  });
  e("ERR_INVALID_REPL_EVAL_CONFIG", "Cannot specify both \"breakEvalOnSigint\" and \"eval\" for REPL");
  e("ERR_INVALID_SYNC_FORK_INPUT", "Asynchronous forks do not support Buffer, Uint8Array or string input: %s");
  e("ERR_INVALID_THIS", "Value of \"this\" must be of type %s");
  e("ERR_INVALID_TUPLE", "%s must be an iterable %s tuple");
  e("ERR_INVALID_URL", "Invalid URL: %s");
  e("ERR_INVALID_URL_SCHEME", function (a) {
    return "The URL must be " + f(a, "scheme");
  });
  e("ERR_IPC_CHANNEL_CLOSED", "Channel closed");
  e("ERR_IPC_DISCONNECTED", "IPC channel is already disconnected");
  e("ERR_IPC_ONE_PIPE", "Child process can have only one IPC pipe");
  e("ERR_IPC_SYNC_FORK", "IPC cannot be used with synchronous forks");
  e("ERR_MISSING_ARGS", function () {
    for (var a = [], b = 0; b < arguments.length; b++) a[b] = arguments[b];

    assert(0 < a.length, "At least one arg needs to be specified");
    b = "The ";
    var c = a.length;
    a = a.map(function (a) {
      return "\"" + a + "\"";
    });

    switch (c) {
      case 1:
        b += a[0] + " argument";
        break;

      case 2:
        b += a[0] + " and " + a[1] + " arguments";
        break;

      default:
        b += a.slice(0, c - 1).join(", "), b += ", and " + a[c - 1] + " arguments";
    }

    return b + " must be specified";
  });
  e("ERR_MULTIPLE_CALLBACK", "Callback called multiple times");
  e("ERR_NAPI_CONS_FUNCTION", "Constructor must be a function");
  e("ERR_NAPI_CONS_PROTOTYPE_OBJECT", "Constructor.prototype must be an object");
  e("ERR_NO_CRYPTO", "Node.js is not compiled with OpenSSL crypto support");
  e("ERR_NO_LONGER_SUPPORTED", "%s is no longer supported");
  e("ERR_PARSE_HISTORY_DATA", "Could not parse history data in %s");
  e("ERR_SOCKET_ALREADY_BOUND", "Socket is already bound");
  e("ERR_SOCKET_BAD_PORT", "Port should be > 0 and < 65536");
  e("ERR_SOCKET_BAD_TYPE", "Bad socket type specified. Valid types are: udp4, udp6");
  e("ERR_SOCKET_CANNOT_SEND", "Unable to send data");
  e("ERR_SOCKET_CLOSED", "Socket is closed");
  e("ERR_SOCKET_DGRAM_NOT_RUNNING", "Not running");
  e("ERR_STDERR_CLOSE", "process.stderr cannot be closed");
  e("ERR_STDOUT_CLOSE", "process.stdout cannot be closed");
  e("ERR_STREAM_WRAP", "Stream has StringDecoder set or is in objectMode");
  e("ERR_TLS_CERT_ALTNAME_INVALID", "Hostname/IP does not match certificate's altnames: %s");
  e("ERR_TLS_DH_PARAM_SIZE", function (a) {
    return "DH parameter size " + a + " is less than 2048";
  });
  e("ERR_TLS_HANDSHAKE_TIMEOUT", "TLS handshake timeout");
  e("ERR_TLS_RENEGOTIATION_FAILED", "Failed to renegotiate");
  e("ERR_TLS_REQUIRED_SERVER_NAME", "\"servername\" is required parameter for Server.addContext");
  e("ERR_TLS_SESSION_ATTACK", "TSL session renegotiation attack detected");
  e("ERR_TRANSFORM_ALREADY_TRANSFORMING", "Calling transform done when still transforming");
  e("ERR_TRANSFORM_WITH_LENGTH_0", "Calling transform done when writableState.length != 0");
  e("ERR_UNKNOWN_ENCODING", "Unknown encoding: %s");
  e("ERR_UNKNOWN_SIGNAL", "Unknown signal: %s");
  e("ERR_UNKNOWN_STDIN_TYPE", "Unknown stdin file type");
  e("ERR_UNKNOWN_STREAM_TYPE", "Unknown stream file type");
  e("ERR_V8BREAKITERATOR", "Full ICU data not installed. See https://github.com/nodejs/node/wiki/Intl");
});
unwrapExports(errors);
var errors_1 = errors.AssertionError,
    errors_2 = errors.message,
    errors_3 = errors.E,
    errors_4 = errors.Error,
    errors_5 = errors.TypeError,
    errors_6 = errors.RangeError,
    encoding = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  b.ENCODING_UTF8 = "utf8";

  b.assertEncoding = function (a) {
    if (a && !buffer.Buffer.isEncoding(a)) throw new errors.TypeError("ERR_INVALID_OPT_VALUE_ENCODING", a);
  };

  b.strToEncoding = function (a, d) {
    return d && d !== b.ENCODING_UTF8 ? "buffer" === d ? new buffer.Buffer(a) : new buffer.Buffer(a).toString(d) : a;
  };
});
unwrapExports(encoding);
var encoding_1 = encoding.ENCODING_UTF8,
    encoding_2 = encoding.assertEncoding,
    encoding_3 = encoding.strToEncoding,
    Dirent_1 = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  var c = constants.constants.S_IFMT,
      d = constants.constants.S_IFDIR,
      e = constants.constants.S_IFREG,
      f = constants.constants.S_IFBLK,
      g = constants.constants.S_IFCHR,
      h = constants.constants.S_IFLNK,
      l = constants.constants.S_IFIFO,
      k = constants.constants.S_IFSOCK;

  a = function () {
    function a() {
      this.name = "";
      this.mode = 0;
    }

    a.build = function (b, c) {
      var d = new a(),
          e = b.getNode().mode;
      d.name = encoding.strToEncoding(b.getName(), c);
      d.mode = e;
      return d;
    };

    a.prototype._checkModeProperty = function (a) {
      return (this.mode & c) === a;
    };

    a.prototype.isDirectory = function () {
      return this._checkModeProperty(d);
    };

    a.prototype.isFile = function () {
      return this._checkModeProperty(e);
    };

    a.prototype.isBlockDevice = function () {
      return this._checkModeProperty(f);
    };

    a.prototype.isCharacterDevice = function () {
      return this._checkModeProperty(g);
    };

    a.prototype.isSymbolicLink = function () {
      return this._checkModeProperty(h);
    };

    a.prototype.isFIFO = function () {
      return this._checkModeProperty(l);
    };

    a.prototype.isSocket = function () {
      return this._checkModeProperty(k);
    };

    return a;
  }();

  b.Dirent = a;
  b.default = a;
});
unwrapExports(Dirent_1);
var Dirent_2 = Dirent_1.Dirent;

function normalizeArray(a, b) {
  for (var c = 0, d = a.length - 1; 0 <= d; d--) {
    var e = a[d];
    "." === e ? a.splice(d, 1) : ".." === e ? (a.splice(d, 1), c++) : c && (a.splice(d, 1), c--);
  }

  if (b) for (; c--; c) a.unshift("..");
  return a;
}

var splitPathRe = /^(\/?|)([\s\S]*?)((?:\.{1,2}|[^\/]+?|)(\.[^.\/]*|))(?:[\/]*)$/,
    splitPath = function (a) {
  return splitPathRe.exec(a).slice(1);
};

function resolve() {
  for (var a = "", b = !1, c = arguments.length - 1; -1 <= c && !b; c--) {
    var d = 0 <= c ? arguments[c] : "/";
    if ("string" !== typeof d) throw new TypeError("Arguments to path.resolve must be strings");
    d && (a = d + "/" + a, b = "/" === d.charAt(0));
  }

  a = normalizeArray(filter(a.split("/"), function (a) {
    return !!a;
  }), !b).join("/");
  return (b ? "/" : "") + a || ".";
}

function normalize(a) {
  var b = isAbsolute(a),
      c = "/" === substr(a, -1);
  (a = normalizeArray(filter(a.split("/"), function (a) {
    return !!a;
  }), !b).join("/")) || b || (a = ".");
  a && c && (a += "/");
  return (b ? "/" : "") + a;
}

function isAbsolute(a) {
  return "/" === a.charAt(0);
}

function join() {
  var a = Array.prototype.slice.call(arguments, 0);
  return normalize(filter(a, function (a, c) {
    if ("string" !== typeof a) throw new TypeError("Arguments to path.join must be strings");
    return a;
  }).join("/"));
}

function relative(a, b) {
  function c(a) {
    for (var b = 0; b < a.length && "" === a[b]; b++);

    for (var c = a.length - 1; 0 <= c && "" === a[c]; c--);

    return b > c ? [] : a.slice(b, c - b + 1);
  }

  a = resolve(a).substr(1);
  b = resolve(b).substr(1);
  a = c(a.split("/"));
  b = c(b.split("/"));

  for (var d = Math.min(a.length, b.length), e = d, f = 0; f < d; f++) if (a[f] !== b[f]) {
    e = f;
    break;
  }

  d = [];

  for (f = e; f < a.length; f++) d.push("..");

  d = d.concat(b.slice(e));
  return d.join("/");
}

var sep = "/",
    delimiter = ":";

function dirname(a) {
  var b = splitPath(a);
  a = b[0];
  b = b[1];
  if (!a && !b) return ".";
  b && (b = b.substr(0, b.length - 1));
  return a + b;
}

function basename(a, b) {
  a = splitPath(a)[2];
  b && a.substr(-1 * b.length) === b && (a = a.substr(0, a.length - b.length));
  return a;
}

function extname(a) {
  return splitPath(a)[3];
}

var pathModule = {
  extname,
  basename,
  dirname,
  sep,
  delimiter,
  relative,
  join,
  isAbsolute,
  normalize,
  resolve
};

function filter(a, b) {
  if (a.filter) return a.filter(b);

  for (var c = [], d = 0; d < a.length; d++) b(a[d], d, a) && c.push(a[d]);

  return c;
}

var substr = "b" === "ab".substr(-1) ? function (a, b, c) {
  return a.substr(b, c);
} : function (a, b, c) {
  0 > b && (b = a.length + b);
  return a.substr(b, c);
},
    setImmediate_1 = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  a = "function" === typeof _process.nextTick ? _process.nextTick.bind(commonjsGlobal) : setTimeout.bind(commonjsGlobal);
  b.default = a;
});
unwrapExports(setImmediate_1);
var process_1 = createCommonjsModule(function (a, b) {
  function c() {
    var a = process;
    a = a || {};
    a.getuid || (a.getuid = function () {
      return 0;
    });
    a.getgid || (a.getgid = function () {
      return 0;
    });
    a.cwd || (a.cwd = function () {
      return "/";
    });
    a.nextTick || (a.nextTick = setImmediate_1.default);
    a.emitWarning || (a.emitWarning = function (a, b) {
      console.warn("" + b + (b ? ": " : "") + a);
    });
    a.env || (a.env = {});
    return a;
  }

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  b.createProcess = c;
  b.default = c();
});
unwrapExports(process_1);
var process_2 = process_1.createProcess,
    domain;

function EventHandlers() {}

EventHandlers.prototype = Object.create(null);

function EventEmitter() {
  EventEmitter.init.call(this);
}

EventEmitter.EventEmitter = EventEmitter;
EventEmitter.usingDomains = !1;
EventEmitter.prototype.domain = void 0;
EventEmitter.prototype._events = void 0;
EventEmitter.prototype._maxListeners = void 0;
EventEmitter.defaultMaxListeners = 10;

EventEmitter.init = function () {
  this.domain = null;
  this._events && this._events !== Object.getPrototypeOf(this)._events || (this._events = new EventHandlers(), this._eventsCount = 0);
  this._maxListeners = this._maxListeners || void 0;
};

EventEmitter.prototype.setMaxListeners = function (a) {
  if ("number" !== typeof a || 0 > a || isNaN(a)) throw new TypeError("\"n\" argument must be a positive number");
  this._maxListeners = a;
  return this;
};

function $getMaxListeners(a) {
  return void 0 === a._maxListeners ? EventEmitter.defaultMaxListeners : a._maxListeners;
}

EventEmitter.prototype.getMaxListeners = function () {
  return $getMaxListeners(this);
};

function emitNone(a, b, c) {
  if (b) a.call(c);else {
    b = a.length;
    a = arrayClone(a, b);

    for (var d = 0; d < b; ++d) a[d].call(c);
  }
}

function emitOne(a, b, c, d) {
  if (b) a.call(c, d);else {
    b = a.length;
    a = arrayClone(a, b);

    for (var e = 0; e < b; ++e) a[e].call(c, d);
  }
}

function emitTwo(a, b, c, d, e) {
  if (b) a.call(c, d, e);else {
    b = a.length;
    a = arrayClone(a, b);

    for (var f = 0; f < b; ++f) a[f].call(c, d, e);
  }
}

function emitThree(a, b, c, d, e, f) {
  if (b) a.call(c, d, e, f);else {
    b = a.length;
    a = arrayClone(a, b);

    for (var g = 0; g < b; ++g) a[g].call(c, d, e, f);
  }
}

function emitMany(a, b, c, d) {
  if (b) a.apply(c, d);else {
    b = a.length;
    a = arrayClone(a, b);

    for (var e = 0; e < b; ++e) a[e].apply(c, d);
  }
}

EventEmitter.prototype.emit = function (a) {
  var b, c;
  var d = "error" === a;
  if (b = this._events) d = d && null == b.error;else if (!d) return !1;
  var e = this.domain;

  if (d) {
    b = arguments[1];
    if (e) b || (b = Error("Uncaught, unspecified \"error\" event")), b.domainEmitter = this, b.domain = e, b.domainThrown = !1, e.emit("error", b);else {
      if (b instanceof Error) throw b;
      e = Error("Uncaught, unspecified \"error\" event. (" + b + ")");
      e.context = b;
      throw e;
    }
    return !1;
  }

  e = b[a];
  if (!e) return !1;
  var f = "function" === typeof e;
  b = arguments.length;

  switch (b) {
    case 1:
      emitNone(e, f, this);
      break;

    case 2:
      emitOne(e, f, this, arguments[1]);
      break;

    case 3:
      emitTwo(e, f, this, arguments[1], arguments[2]);
      break;

    case 4:
      emitThree(e, f, this, arguments[1], arguments[2], arguments[3]);
      break;

    default:
      d = Array(b - 1);

      for (c = 1; c < b; c++) d[c - 1] = arguments[c];

      emitMany(e, f, this, d);
  }

  return !0;
};

function _addListener(a, b, c, d) {
  var e;
  if ("function" !== typeof c) throw new TypeError("\"listener\" argument must be a function");

  if (e = a._events) {
    e.newListener && (a.emit("newListener", b, c.listener ? c.listener : c), e = a._events);
    var f = e[b];
  } else e = a._events = new EventHandlers(), a._eventsCount = 0;

  f ? ("function" === typeof f ? f = e[b] = d ? [c, f] : [f, c] : d ? f.unshift(c) : f.push(c), f.warned || (c = $getMaxListeners(a)) && 0 < c && f.length > c && (f.warned = !0, c = Error("Possible EventEmitter memory leak detected. " + f.length + " " + b + " listeners added. Use emitter.setMaxListeners() to increase limit"), c.name = "MaxListenersExceededWarning", c.emitter = a, c.type = b, c.count = f.length, emitWarning(c))) : (e[b] = c, ++a._eventsCount);
  return a;
}

function emitWarning(a) {
  "function" === typeof console.warn ? console.warn(a) : console.log(a);
}

EventEmitter.prototype.addListener = function (a, b) {
  return _addListener(this, a, b, !1);
};

EventEmitter.prototype.on = EventEmitter.prototype.addListener;

EventEmitter.prototype.prependListener = function (a, b) {
  return _addListener(this, a, b, !0);
};

function _onceWrap(a, b, c) {
  function d() {
    a.removeListener(b, d);
    e || (e = !0, c.apply(a, arguments));
  }

  var e = !1;
  d.listener = c;
  return d;
}

EventEmitter.prototype.once = function (a, b) {
  if ("function" !== typeof b) throw new TypeError("\"listener\" argument must be a function");
  this.on(a, _onceWrap(this, a, b));
  return this;
};

EventEmitter.prototype.prependOnceListener = function (a, b) {
  if ("function" !== typeof b) throw new TypeError("\"listener\" argument must be a function");
  this.prependListener(a, _onceWrap(this, a, b));
  return this;
};

EventEmitter.prototype.removeListener = function (a, b) {
  var c;
  if ("function" !== typeof b) throw new TypeError("\"listener\" argument must be a function");
  var d = this._events;
  if (!d) return this;
  var e = d[a];
  if (!e) return this;
  if (e === b || e.listener && e.listener === b) 0 === --this._eventsCount ? this._events = new EventHandlers() : (delete d[a], d.removeListener && this.emit("removeListener", a, e.listener || b));else if ("function" !== typeof e) {
    var f = -1;

    for (c = e.length; 0 < c--;) if (e[c] === b || e[c].listener && e[c].listener === b) {
      var g = e[c].listener;
      f = c;
      break;
    }

    if (0 > f) return this;

    if (1 === e.length) {
      e[0] = void 0;
      if (0 === --this._eventsCount) return this._events = new EventHandlers(), this;
      delete d[a];
    } else spliceOne(e, f);

    d.removeListener && this.emit("removeListener", a, g || b);
  }
  return this;
};

EventEmitter.prototype.removeAllListeners = function (a) {
  var b = this._events;
  if (!b) return this;
  if (!b.removeListener) return 0 === arguments.length ? (this._events = new EventHandlers(), this._eventsCount = 0) : b[a] && (0 === --this._eventsCount ? this._events = new EventHandlers() : delete b[a]), this;

  if (0 === arguments.length) {
    b = Object.keys(b);

    for (var c = 0, d; c < b.length; ++c) d = b[c], "removeListener" !== d && this.removeAllListeners(d);

    this.removeAllListeners("removeListener");
    this._events = new EventHandlers();
    this._eventsCount = 0;
    return this;
  }

  b = b[a];
  if ("function" === typeof b) this.removeListener(a, b);else if (b) {
    do this.removeListener(a, b[b.length - 1]); while (b[0]);
  }
  return this;
};

EventEmitter.prototype.listeners = function (a) {
  var b = this._events;
  a = b ? (a = b[a]) ? "function" === typeof a ? [a.listener || a] : unwrapListeners(a) : [] : [];
  return a;
};

EventEmitter.listenerCount = function (a, b) {
  return "function" === typeof a.listenerCount ? a.listenerCount(b) : listenerCount.call(a, b);
};

EventEmitter.prototype.listenerCount = listenerCount;

function listenerCount(a) {
  var b = this._events;

  if (b) {
    a = b[a];
    if ("function" === typeof a) return 1;
    if (a) return a.length;
  }

  return 0;
}

EventEmitter.prototype.eventNames = function () {
  return 0 < this._eventsCount ? Reflect.ownKeys(this._events) : [];
};

function spliceOne(a, b) {
  for (var c = b + 1, d = a.length; c < d; b += 1, c += 1) a[b] = a[c];

  a.pop();
}

function arrayClone(a, b) {
  for (var c = Array(b); b--;) c[b] = a[b];

  return c;
}

function unwrapListeners(a) {
  for (var b = Array(a.length), c = 0; c < b.length; ++c) b[c] = a[c].listener || a[c];

  return b;
}

var node = createCommonjsModule(function (a, b) {
  var c = commonjsGlobal && commonjsGlobal.__extends || function () {
    var a = function (b, c) {
      a = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (a, b) {
        a.__proto__ = b;
      } || function (a, b) {
        for (var c in b) b.hasOwnProperty(c) && (a[c] = b[c]);
      };

      return a(b, c);
    };

    return function (b, c) {
      function d() {
        this.constructor = b;
      }

      a(b, c);
      b.prototype = null === c ? Object.create(c) : (d.prototype = c.prototype, new d());
    };
  }();

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  var d = constants.constants.S_IFMT,
      e = constants.constants.S_IFDIR,
      f = constants.constants.S_IFREG,
      g = constants.constants.S_IFLNK,
      h = constants.constants.O_APPEND;
  b.SEP = "/";

  a = function (a) {
    function b(b, c) {
      void 0 === c && (c = 438);
      var d = a.call(this) || this;
      d.uid = process_1.default.getuid();
      d.gid = process_1.default.getgid();
      d.atime = new Date();
      d.mtime = new Date();
      d.ctime = new Date();
      d.perm = 438;
      d.mode = f;
      d.nlink = 1;
      d.perm = c;
      d.mode |= c;
      d.ino = b;
      return d;
    }

    c(b, a);

    b.prototype.getString = function (a) {
      void 0 === a && (a = "utf8");
      return this.getBuffer().toString(a);
    };

    b.prototype.setString = function (a) {
      this.buf = buffer.bufferFrom(a, "utf8");
      this.touch();
    };

    b.prototype.getBuffer = function () {
      this.buf || this.setBuffer(buffer.bufferAllocUnsafe(0));
      return buffer.bufferFrom(this.buf);
    };

    b.prototype.setBuffer = function (a) {
      this.buf = buffer.bufferFrom(a);
      this.touch();
    };

    b.prototype.getSize = function () {
      return this.buf ? this.buf.length : 0;
    };

    b.prototype.setModeProperty = function (a) {
      this.mode = this.mode & ~d | a;
    };

    b.prototype.setIsFile = function () {
      this.setModeProperty(f);
    };

    b.prototype.setIsDirectory = function () {
      this.setModeProperty(e);
    };

    b.prototype.setIsSymlink = function () {
      this.setModeProperty(g);
    };

    b.prototype.isFile = function () {
      return (this.mode & d) === f;
    };

    b.prototype.isDirectory = function () {
      return (this.mode & d) === e;
    };

    b.prototype.isSymlink = function () {
      return (this.mode & d) === g;
    };

    b.prototype.makeSymlink = function (a) {
      this.symlink = a;
      this.setIsSymlink();
    };

    b.prototype.write = function (a, b, c, d) {
      void 0 === b && (b = 0);
      void 0 === c && (c = a.length);
      void 0 === d && (d = 0);
      this.buf || (this.buf = buffer.bufferAllocUnsafe(0));

      if (d + c > this.buf.length) {
        var e = buffer.bufferAllocUnsafe(d + c);
        this.buf.copy(e, 0, 0, this.buf.length);
        this.buf = e;
      }

      a.copy(this.buf, d, b, b + c);
      this.touch();
      return c;
    };

    b.prototype.read = function (a, b, c, d) {
      void 0 === b && (b = 0);
      void 0 === c && (c = a.byteLength);
      void 0 === d && (d = 0);
      this.buf || (this.buf = buffer.bufferAllocUnsafe(0));
      c > a.byteLength && (c = a.byteLength);
      c + d > this.buf.length && (c = this.buf.length - d);
      this.buf.copy(a, b, d, d + c);
      return c;
    };

    b.prototype.truncate = function (a) {
      void 0 === a && (a = 0);
      if (a) {
        if (this.buf || (this.buf = buffer.bufferAllocUnsafe(0)), a <= this.buf.length) this.buf = this.buf.slice(0, a);else {
          var b = buffer.bufferAllocUnsafe(0);
          this.buf.copy(b);
          b.fill(0, a);
        }
      } else this.buf = buffer.bufferAllocUnsafe(0);
      this.touch();
    };

    b.prototype.chmod = function (a) {
      this.perm = a;
      this.mode = this.mode & -512 | a;
      this.touch();
    };

    b.prototype.chown = function (a, b) {
      this.uid = a;
      this.gid = b;
      this.touch();
    };

    b.prototype.touch = function () {
      this.mtime = new Date();
      this.emit("change", this);
    };

    b.prototype.canRead = function (a, b) {
      void 0 === a && (a = process_1.default.getuid());
      void 0 === b && (b = process_1.default.getgid());
      return this.perm & 4 || b === this.gid && this.perm & 32 || a === this.uid && this.perm & 256 ? !0 : !1;
    };

    b.prototype.canWrite = function (a, b) {
      void 0 === a && (a = process_1.default.getuid());
      void 0 === b && (b = process_1.default.getgid());
      return this.perm & 2 || b === this.gid && this.perm & 16 || a === this.uid && this.perm & 128 ? !0 : !1;
    };

    b.prototype.del = function () {
      this.emit("delete", this);
    };

    b.prototype.toJSON = function () {
      return {
        ino: this.ino,
        uid: this.uid,
        gid: this.gid,
        atime: this.atime.getTime(),
        mtime: this.mtime.getTime(),
        ctime: this.ctime.getTime(),
        perm: this.perm,
        mode: this.mode,
        nlink: this.nlink,
        symlink: this.symlink,
        data: this.getString()
      };
    };

    return b;
  }(EventEmitter.EventEmitter);

  b.Node = a;

  a = function (a) {
    function d(b, c, d) {
      var e = a.call(this) || this;
      e.children = {};
      e.steps = [];
      e.ino = 0;
      e.length = 0;
      e.vol = b;
      e.parent = c;
      e.steps = c ? c.steps.concat([d]) : [d];
      return e;
    }

    c(d, a);

    d.prototype.setNode = function (a) {
      this.node = a;
      this.ino = a.ino;
    };

    d.prototype.getNode = function () {
      return this.node;
    };

    d.prototype.createChild = function (a, b) {
      void 0 === b && (b = this.vol.createNode());
      var c = new d(this.vol, this, a);
      c.setNode(b);
      b.isDirectory();
      this.setChild(a, c);
      return c;
    };

    d.prototype.setChild = function (a, b) {
      void 0 === b && (b = new d(this.vol, this, a));
      this.children[a] = b;
      b.parent = this;
      this.length++;
      this.emit("child:add", b, this);
      return b;
    };

    d.prototype.deleteChild = function (a) {
      delete this.children[a.getName()];
      this.length--;
      this.emit("child:delete", a, this);
    };

    d.prototype.getChild = function (a) {
      if (Object.hasOwnProperty.call(this.children, a)) return this.children[a];
    };

    d.prototype.getPath = function () {
      return this.steps.join(b.SEP);
    };

    d.prototype.getName = function () {
      return this.steps[this.steps.length - 1];
    };

    d.prototype.walk = function (a, b, c) {
      void 0 === b && (b = a.length);
      void 0 === c && (c = 0);
      if (c >= a.length || c >= b) return this;
      var d = this.getChild(a[c]);
      return d ? d.walk(a, b, c + 1) : null;
    };

    d.prototype.toJSON = function () {
      return {
        steps: this.steps,
        ino: this.ino,
        children: Object.keys(this.children)
      };
    };

    return d;
  }(EventEmitter.EventEmitter);

  b.Link = a;

  a = function () {
    function a(a, b, c, d) {
      this.position = 0;
      this.link = a;
      this.node = b;
      this.flags = c;
      this.fd = d;
    }

    a.prototype.getString = function (a) {
      return this.node.getString();
    };

    a.prototype.setString = function (a) {
      this.node.setString(a);
    };

    a.prototype.getBuffer = function () {
      return this.node.getBuffer();
    };

    a.prototype.setBuffer = function (a) {
      this.node.setBuffer(a);
    };

    a.prototype.getSize = function () {
      return this.node.getSize();
    };

    a.prototype.truncate = function (a) {
      this.node.truncate(a);
    };

    a.prototype.seekTo = function (a) {
      this.position = a;
    };

    a.prototype.stats = function () {
      return Stats_1.default.build(this.node);
    };

    a.prototype.write = function (a, b, c, d) {
      void 0 === b && (b = 0);
      void 0 === c && (c = a.length);
      "number" !== typeof d && (d = this.position);
      this.flags & h && (d = this.getSize());
      a = this.node.write(a, b, c, d);
      this.position = d + a;
      return a;
    };

    a.prototype.read = function (a, b, c, d) {
      void 0 === b && (b = 0);
      void 0 === c && (c = a.byteLength);
      "number" !== typeof d && (d = this.position);
      a = this.node.read(a, b, c, d);
      this.position = d + a;
      return a;
    };

    a.prototype.chmod = function (a) {
      this.node.chmod(a);
    };

    a.prototype.chown = function (a, b) {
      this.node.chown(a, b);
    };

    return a;
  }();

  b.File = a;
});
unwrapExports(node);
var node_1 = node.SEP,
    node_2 = node.Node,
    node_3 = node.Link,
    node_4 = node.File,
    setTimeoutUnref_1 = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });

  b.default = function (a, b, e) {
    var c = setTimeout.apply(null, arguments);
    c && "object" === typeof c && "function" === typeof c.unref && c.unref();
    return c;
  };
});
unwrapExports(setTimeoutUnref_1);

function BufferList() {
  this.tail = this.head = null;
  this.length = 0;
}

BufferList.prototype.push = function (a) {
  a = {
    data: a,
    next: null
  };
  0 < this.length ? this.tail.next = a : this.head = a;
  this.tail = a;
  ++this.length;
};

BufferList.prototype.unshift = function (a) {
  a = {
    data: a,
    next: this.head
  };
  0 === this.length && (this.tail = a);
  this.head = a;
  ++this.length;
};

BufferList.prototype.shift = function () {
  if (0 !== this.length) {
    var a = this.head.data;
    this.head = 1 === this.length ? this.tail = null : this.head.next;
    --this.length;
    return a;
  }
};

BufferList.prototype.clear = function () {
  this.head = this.tail = null;
  this.length = 0;
};

BufferList.prototype.join = function (a) {
  if (0 === this.length) return "";

  for (var b = this.head, c = "" + b.data; b = b.next;) c += a + b.data;

  return c;
};

BufferList.prototype.concat = function (a) {
  if (0 === this.length) return Buffer.alloc(0);
  if (1 === this.length) return this.head.data;
  a = Buffer.allocUnsafe(a >>> 0);

  for (var b = this.head, c = 0; b;) b.data.copy(a, c), c += b.data.length, b = b.next;

  return a;
};

var isBufferEncoding = Buffer.isEncoding || function (a) {
  switch (a && a.toLowerCase()) {
    case "hex":
    case "utf8":
    case "utf-8":
    case "ascii":
    case "binary":
    case "base64":
    case "ucs2":
    case "ucs-2":
    case "utf16le":
    case "utf-16le":
    case "raw":
      return !0;

    default:
      return !1;
  }
};

function assertEncoding(a) {
  if (a && !isBufferEncoding(a)) throw Error("Unknown encoding: " + a);
}

function StringDecoder(a) {
  this.encoding = (a || "utf8").toLowerCase().replace(/[-_]/, "");
  assertEncoding(a);

  switch (this.encoding) {
    case "utf8":
      this.surrogateSize = 3;
      break;

    case "ucs2":
    case "utf16le":
      this.surrogateSize = 2;
      this.detectIncompleteChar = utf16DetectIncompleteChar;
      break;

    case "base64":
      this.surrogateSize = 3;
      this.detectIncompleteChar = base64DetectIncompleteChar;
      break;

    default:
      this.write = passThroughWrite;
      return;
  }

  this.charBuffer = new Buffer(6);
  this.charLength = this.charReceived = 0;
}

StringDecoder.prototype.write = function (a) {
  for (var b = ""; this.charLength;) {
    b = a.length >= this.charLength - this.charReceived ? this.charLength - this.charReceived : a.length;
    a.copy(this.charBuffer, this.charReceived, 0, b);
    this.charReceived += b;
    if (this.charReceived < this.charLength) return "";
    a = a.slice(b, a.length);
    b = this.charBuffer.slice(0, this.charLength).toString(this.encoding);
    var c = b.charCodeAt(b.length - 1);
    if (55296 <= c && 56319 >= c) this.charLength += this.surrogateSize, b = "";else {
      this.charReceived = this.charLength = 0;
      if (0 === a.length) return b;
      break;
    }
  }

  this.detectIncompleteChar(a);
  var d = a.length;
  this.charLength && (a.copy(this.charBuffer, 0, a.length - this.charReceived, d), d -= this.charReceived);
  b += a.toString(this.encoding, 0, d);
  d = b.length - 1;
  c = b.charCodeAt(d);
  return 55296 <= c && 56319 >= c ? (c = this.surrogateSize, this.charLength += c, this.charReceived += c, this.charBuffer.copy(this.charBuffer, c, 0, c), a.copy(this.charBuffer, 0, 0, c), b.substring(0, d)) : b;
};

StringDecoder.prototype.detectIncompleteChar = function (a) {
  for (var b = 3 <= a.length ? 3 : a.length; 0 < b; b--) {
    var c = a[a.length - b];

    if (1 == b && 6 == c >> 5) {
      this.charLength = 2;
      break;
    }

    if (2 >= b && 14 == c >> 4) {
      this.charLength = 3;
      break;
    }

    if (3 >= b && 30 == c >> 3) {
      this.charLength = 4;
      break;
    }
  }

  this.charReceived = b;
};

StringDecoder.prototype.end = function (a) {
  var b = "";
  a && a.length && (b = this.write(a));
  this.charReceived && (a = this.encoding, b += this.charBuffer.slice(0, this.charReceived).toString(a));
  return b;
};

function passThroughWrite(a) {
  return a.toString(this.encoding);
}

function utf16DetectIncompleteChar(a) {
  this.charLength = (this.charReceived = a.length % 2) ? 2 : 0;
}

function base64DetectIncompleteChar(a) {
  this.charLength = (this.charReceived = a.length % 3) ? 3 : 0;
}

Readable.ReadableState = ReadableState;
var debug = debuglog("stream");
inherits$1(Readable, EventEmitter);

function prependListener(a, b, c) {
  if ("function" === typeof a.prependListener) return a.prependListener(b, c);
  if (a._events && a._events[b]) Array.isArray(a._events[b]) ? a._events[b].unshift(c) : a._events[b] = [c, a._events[b]];else a.on(b, c);
}

function listenerCount$1(a, b) {
  return a.listeners(b).length;
}

function ReadableState(a, b) {
  a = a || {};
  this.objectMode = !!a.objectMode;
  b instanceof Duplex && (this.objectMode = this.objectMode || !!a.readableObjectMode);
  b = a.highWaterMark;
  var c = this.objectMode ? 16 : 16384;
  this.highWaterMark = b || 0 === b ? b : c;
  this.highWaterMark = ~~this.highWaterMark;
  this.buffer = new BufferList();
  this.length = 0;
  this.pipes = null;
  this.pipesCount = 0;
  this.flowing = null;
  this.reading = this.endEmitted = this.ended = !1;
  this.sync = !0;
  this.resumeScheduled = this.readableListening = this.emittedReadable = this.needReadable = !1;
  this.defaultEncoding = a.defaultEncoding || "utf8";
  this.ranOut = !1;
  this.awaitDrain = 0;
  this.readingMore = !1;
  this.encoding = this.decoder = null;
  a.encoding && (this.decoder = new StringDecoder(a.encoding), this.encoding = a.encoding);
}

function Readable(a) {
  if (!(this instanceof Readable)) return new Readable(a);
  this._readableState = new ReadableState(a, this);
  this.readable = !0;
  a && "function" === typeof a.read && (this._read = a.read);
  EventEmitter.call(this);
}

Readable.prototype.push = function (a, b) {
  var c = this._readableState;
  c.objectMode || "string" !== typeof a || (b = b || c.defaultEncoding, b !== c.encoding && (a = Buffer.from(a, b), b = ""));
  return readableAddChunk(this, c, a, b, !1);
};

Readable.prototype.unshift = function (a) {
  return readableAddChunk(this, this._readableState, a, "", !0);
};

Readable.prototype.isPaused = function () {
  return !1 === this._readableState.flowing;
};

function readableAddChunk(a, b, c, d, e) {
  var f = chunkInvalid(b, c);
  if (f) a.emit("error", f);else if (null === c) b.reading = !1, onEofChunk(a, b);else if (b.objectMode || c && 0 < c.length) {
    if (b.ended && !e) a.emit("error", Error("stream.push() after EOF"));else if (b.endEmitted && e) a.emit("error", Error("stream.unshift() after end event"));else {
      if (b.decoder && !e && !d) {
        c = b.decoder.write(c);
        var g = !b.objectMode && 0 === c.length;
      }

      e || (b.reading = !1);
      g || (b.flowing && 0 === b.length && !b.sync ? (a.emit("data", c), a.read(0)) : (b.length += b.objectMode ? 1 : c.length, e ? b.buffer.unshift(c) : b.buffer.push(c), b.needReadable && emitReadable(a)));
      maybeReadMore(a, b);
    }
  } else e || (b.reading = !1);
  return needMoreData(b);
}

function needMoreData(a) {
  return !a.ended && (a.needReadable || a.length < a.highWaterMark || 0 === a.length);
}

Readable.prototype.setEncoding = function (a) {
  this._readableState.decoder = new StringDecoder(a);
  this._readableState.encoding = a;
  return this;
};

var MAX_HWM = 8388608;

function computeNewHighWaterMark(a) {
  a >= MAX_HWM ? a = MAX_HWM : (a--, a |= a >>> 1, a |= a >>> 2, a |= a >>> 4, a |= a >>> 8, a |= a >>> 16, a++);
  return a;
}

function howMuchToRead(a, b) {
  if (0 >= a || 0 === b.length && b.ended) return 0;
  if (b.objectMode) return 1;
  if (a !== a) return b.flowing && b.length ? b.buffer.head.data.length : b.length;
  a > b.highWaterMark && (b.highWaterMark = computeNewHighWaterMark(a));
  return a <= b.length ? a : b.ended ? b.length : (b.needReadable = !0, 0);
}

Readable.prototype.read = function (a) {
  debug("read", a);
  a = parseInt(a, 10);
  var b = this._readableState,
      c = a;
  0 !== a && (b.emittedReadable = !1);
  if (0 === a && b.needReadable && (b.length >= b.highWaterMark || b.ended)) return debug("read: emitReadable", b.length, b.ended), 0 === b.length && b.ended ? endReadable(this) : emitReadable(this), null;
  a = howMuchToRead(a, b);
  if (0 === a && b.ended) return 0 === b.length && endReadable(this), null;
  var d = b.needReadable;
  debug("need readable", d);
  if (0 === b.length || b.length - a < b.highWaterMark) d = !0, debug("length less than watermark", d);
  b.ended || b.reading ? debug("reading or ended", !1) : d && (debug("do read"), b.reading = !0, b.sync = !0, 0 === b.length && (b.needReadable = !0), this._read(b.highWaterMark), b.sync = !1, b.reading || (a = howMuchToRead(c, b)));
  d = 0 < a ? fromList(a, b) : null;
  null === d ? (b.needReadable = !0, a = 0) : b.length -= a;
  0 === b.length && (b.ended || (b.needReadable = !0), c !== a && b.ended && endReadable(this));
  null !== d && this.emit("data", d);
  return d;
};

function chunkInvalid(a, b) {
  var c = null;
  isBuffer(b) || "string" === typeof b || null === b || void 0 === b || a.objectMode || (c = new TypeError("Invalid non-string/buffer chunk"));
  return c;
}

function onEofChunk(a, b) {
  if (!b.ended) {
    if (b.decoder) {
      var c = b.decoder.end();
      c && c.length && (b.buffer.push(c), b.length += b.objectMode ? 1 : c.length);
    }

    b.ended = !0;
    emitReadable(a);
  }
}

function emitReadable(a) {
  var b = a._readableState;
  b.needReadable = !1;
  b.emittedReadable || (debug("emitReadable", b.flowing), b.emittedReadable = !0, b.sync ? nextTick(emitReadable_, a) : emitReadable_(a));
}

function emitReadable_(a) {
  debug("emit readable");
  a.emit("readable");
  flow(a);
}

function maybeReadMore(a, b) {
  b.readingMore || (b.readingMore = !0, nextTick(maybeReadMore_, a, b));
}

function maybeReadMore_(a, b) {
  for (var c = b.length; !b.reading && !b.flowing && !b.ended && b.length < b.highWaterMark && (debug("maybeReadMore read 0"), a.read(0), c !== b.length);) c = b.length;

  b.readingMore = !1;
}

Readable.prototype._read = function (a) {
  this.emit("error", Error("not implemented"));
};

Readable.prototype.pipe = function (a, b) {
  function c(a) {
    debug("onunpipe");
    a === m && e();
  }

  function d() {
    debug("onend");
    a.end();
  }

  function e() {
    debug("cleanup");
    a.removeListener("close", h);
    a.removeListener("finish", l);
    a.removeListener("drain", r);
    a.removeListener("error", g);
    a.removeListener("unpipe", c);
    m.removeListener("end", d);
    m.removeListener("end", e);
    m.removeListener("data", f);
    u = !0;
    !p.awaitDrain || a._writableState && !a._writableState.needDrain || r();
  }

  function f(b) {
    debug("ondata");
    B = !1;
    !1 !== a.write(b) || B || ((1 === p.pipesCount && p.pipes === a || 1 < p.pipesCount && -1 !== indexOf(p.pipes, a)) && !u && (debug("false write response, pause", m._readableState.awaitDrain), m._readableState.awaitDrain++, B = !0), m.pause());
  }

  function g(b) {
    debug("onerror", b);
    k();
    a.removeListener("error", g);
    0 === listenerCount$1(a, "error") && a.emit("error", b);
  }

  function h() {
    a.removeListener("finish", l);
    k();
  }

  function l() {
    debug("onfinish");
    a.removeListener("close", h);
    k();
  }

  function k() {
    debug("unpipe");
    m.unpipe(a);
  }

  var m = this,
      p = this._readableState;

  switch (p.pipesCount) {
    case 0:
      p.pipes = a;
      break;

    case 1:
      p.pipes = [p.pipes, a];
      break;

    default:
      p.pipes.push(a);
  }

  p.pipesCount += 1;
  debug("pipe count=%d opts=%j", p.pipesCount, b);
  b = b && !1 === b.end ? e : d;
  if (p.endEmitted) nextTick(b);else m.once("end", b);
  a.on("unpipe", c);
  var r = pipeOnDrain(m);
  a.on("drain", r);
  var u = !1,
      B = !1;
  m.on("data", f);
  prependListener(a, "error", g);
  a.once("close", h);
  a.once("finish", l);
  a.emit("pipe", m);
  p.flowing || (debug("pipe resume"), m.resume());
  return a;
};

function pipeOnDrain(a) {
  return function () {
    var b = a._readableState;
    debug("pipeOnDrain", b.awaitDrain);
    b.awaitDrain && b.awaitDrain--;
    0 === b.awaitDrain && a.listeners("data").length && (b.flowing = !0, flow(a));
  };
}

Readable.prototype.unpipe = function (a) {
  var b = this._readableState;
  if (0 === b.pipesCount) return this;

  if (1 === b.pipesCount) {
    if (a && a !== b.pipes) return this;
    a || (a = b.pipes);
    b.pipes = null;
    b.pipesCount = 0;
    b.flowing = !1;
    a && a.emit("unpipe", this);
    return this;
  }

  if (!a) {
    a = b.pipes;
    var c = b.pipesCount;
    b.pipes = null;
    b.pipesCount = 0;
    b.flowing = !1;

    for (b = 0; b < c; b++) a[b].emit("unpipe", this);

    return this;
  }

  c = indexOf(b.pipes, a);
  if (-1 === c) return this;
  b.pipes.splice(c, 1);
  --b.pipesCount;
  1 === b.pipesCount && (b.pipes = b.pipes[0]);
  a.emit("unpipe", this);
  return this;
};

Readable.prototype.on = function (a, b) {
  b = EventEmitter.prototype.on.call(this, a, b);
  "data" === a ? !1 !== this._readableState.flowing && this.resume() : "readable" === a && (a = this._readableState, a.endEmitted || a.readableListening || (a.readableListening = a.needReadable = !0, a.emittedReadable = !1, a.reading ? a.length && emitReadable(this) : nextTick(nReadingNextTick, this)));
  return b;
};

Readable.prototype.addListener = Readable.prototype.on;

function nReadingNextTick(a) {
  debug("readable nexttick read 0");
  a.read(0);
}

Readable.prototype.resume = function () {
  var a = this._readableState;
  a.flowing || (debug("resume"), a.flowing = !0, resume(this, a));
  return this;
};

function resume(a, b) {
  b.resumeScheduled || (b.resumeScheduled = !0, nextTick(resume_, a, b));
}

function resume_(a, b) {
  b.reading || (debug("resume read 0"), a.read(0));
  b.resumeScheduled = !1;
  b.awaitDrain = 0;
  a.emit("resume");
  flow(a);
  b.flowing && !b.reading && a.read(0);
}

Readable.prototype.pause = function () {
  debug("call pause flowing=%j", this._readableState.flowing);
  !1 !== this._readableState.flowing && (debug("pause"), this._readableState.flowing = !1, this.emit("pause"));
  return this;
};

function flow(a) {
  var b = a._readableState;

  for (debug("flow", b.flowing); b.flowing && null !== a.read(););
}

Readable.prototype.wrap = function (a) {
  var b = this._readableState,
      c = !1,
      d = this;
  a.on("end", function () {
    debug("wrapped end");

    if (b.decoder && !b.ended) {
      var a = b.decoder.end();
      a && a.length && d.push(a);
    }

    d.push(null);
  });
  a.on("data", function (e) {
    debug("wrapped data");
    b.decoder && (e = b.decoder.write(e));
    b.objectMode && (null === e || void 0 === e) || !(b.objectMode || e && e.length) || d.push(e) || (c = !0, a.pause());
  });

  for (var e in a) void 0 === this[e] && "function" === typeof a[e] && (this[e] = function (b) {
    return function () {
      return a[b].apply(a, arguments);
    };
  }(e));

  forEach(["error", "close", "destroy", "pause", "resume"], function (b) {
    a.on(b, d.emit.bind(d, b));
  });

  d._read = function (b) {
    debug("wrapped _read", b);
    c && (c = !1, a.resume());
  };

  return d;
};

Readable._fromList = fromList;

function fromList(a, b) {
  if (0 === b.length) return null;
  b.objectMode ? a = b.buffer.shift() : !a || a >= b.length ? (a = b.decoder ? b.buffer.join("") : 1 === b.buffer.length ? b.buffer.head.data : b.buffer.concat(b.length), b.buffer.clear()) : a = fromListPartial(a, b.buffer, b.decoder);
  return a;
}

function fromListPartial(a, b, c) {
  a < b.head.data.length ? (c = b.head.data.slice(0, a), b.head.data = b.head.data.slice(a)) : c = a === b.head.data.length ? b.shift() : c ? copyFromBufferString(a, b) : copyFromBuffer(a, b);
  return c;
}

function copyFromBufferString(a, b) {
  var c = b.head,
      d = 1,
      e = c.data;

  for (a -= e.length; c = c.next;) {
    var f = c.data,
        g = a > f.length ? f.length : a;
    e = g === f.length ? e + f : e + f.slice(0, a);
    a -= g;

    if (0 === a) {
      g === f.length ? (++d, b.head = c.next ? c.next : b.tail = null) : (b.head = c, c.data = f.slice(g));
      break;
    }

    ++d;
  }

  b.length -= d;
  return e;
}

function copyFromBuffer(a, b) {
  var c = Buffer.allocUnsafe(a),
      d = b.head,
      e = 1;
  d.data.copy(c);

  for (a -= d.data.length; d = d.next;) {
    var f = d.data,
        g = a > f.length ? f.length : a;
    f.copy(c, c.length - a, 0, g);
    a -= g;

    if (0 === a) {
      g === f.length ? (++e, b.head = d.next ? d.next : b.tail = null) : (b.head = d, d.data = f.slice(g));
      break;
    }

    ++e;
  }

  b.length -= e;
  return c;
}

function endReadable(a) {
  var b = a._readableState;
  if (0 < b.length) throw Error("\"endReadable()\" called on non-empty stream");
  b.endEmitted || (b.ended = !0, nextTick(endReadableNT, b, a));
}

function endReadableNT(a, b) {
  a.endEmitted || 0 !== a.length || (a.endEmitted = !0, b.readable = !1, b.emit("end"));
}

function forEach(a, b) {
  for (var c = 0, d = a.length; c < d; c++) b(a[c], c);
}

function indexOf(a, b) {
  for (var c = 0, d = a.length; c < d; c++) if (a[c] === b) return c;

  return -1;
}

Writable.WritableState = WritableState;
inherits$1(Writable, EventEmitter);

function nop() {}

function WriteReq(a, b, c) {
  this.chunk = a;
  this.encoding = b;
  this.callback = c;
  this.next = null;
}

function WritableState(a, b) {
  Object.defineProperty(this, "buffer", {
    get: deprecate(function () {
      return this.getBuffer();
    }, "_writableState.buffer is deprecated. Use _writableState.getBuffer instead.")
  });
  a = a || {};
  this.objectMode = !!a.objectMode;
  b instanceof Duplex && (this.objectMode = this.objectMode || !!a.writableObjectMode);
  var c = a.highWaterMark,
      d = this.objectMode ? 16 : 16384;
  this.highWaterMark = c || 0 === c ? c : d;
  this.highWaterMark = ~~this.highWaterMark;
  this.finished = this.ended = this.ending = this.needDrain = !1;
  this.decodeStrings = !1 !== a.decodeStrings;
  this.defaultEncoding = a.defaultEncoding || "utf8";
  this.length = 0;
  this.writing = !1;
  this.corked = 0;
  this.sync = !0;
  this.bufferProcessing = !1;

  this.onwrite = function (a) {
    onwrite(b, a);
  };

  this.writecb = null;
  this.writelen = 0;
  this.lastBufferedRequest = this.bufferedRequest = null;
  this.pendingcb = 0;
  this.errorEmitted = this.prefinished = !1;
  this.bufferedRequestCount = 0;
  this.corkedRequestsFree = new CorkedRequest(this);
}

WritableState.prototype.getBuffer = function () {
  for (var a = this.bufferedRequest, b = []; a;) b.push(a), a = a.next;

  return b;
};

function Writable(a) {
  if (!(this instanceof Writable || this instanceof Duplex)) return new Writable(a);
  this._writableState = new WritableState(a, this);
  this.writable = !0;
  a && ("function" === typeof a.write && (this._write = a.write), "function" === typeof a.writev && (this._writev = a.writev));
  EventEmitter.call(this);
}

Writable.prototype.pipe = function () {
  this.emit("error", Error("Cannot pipe, not readable"));
};

function writeAfterEnd(a, b) {
  var c = Error("write after end");
  a.emit("error", c);
  nextTick(b, c);
}

function validChunk(a, b, c, d) {
  var e = !0,
      f = !1;
  null === c ? f = new TypeError("May not write null values to stream") : Buffer.isBuffer(c) || "string" === typeof c || void 0 === c || b.objectMode || (f = new TypeError("Invalid non-string/buffer chunk"));
  f && (a.emit("error", f), nextTick(d, f), e = !1);
  return e;
}

Writable.prototype.write = function (a, b, c) {
  var d = this._writableState,
      e = !1;
  "function" === typeof b && (c = b, b = null);
  Buffer.isBuffer(a) ? b = "buffer" : b || (b = d.defaultEncoding);
  "function" !== typeof c && (c = nop);
  d.ended ? writeAfterEnd(this, c) : validChunk(this, d, a, c) && (d.pendingcb++, e = writeOrBuffer(this, d, a, b, c));
  return e;
};

Writable.prototype.cork = function () {
  this._writableState.corked++;
};

Writable.prototype.uncork = function () {
  var a = this._writableState;
  a.corked && (a.corked--, a.writing || a.corked || a.finished || a.bufferProcessing || !a.bufferedRequest || clearBuffer(this, a));
};

Writable.prototype.setDefaultEncoding = function (a) {
  "string" === typeof a && (a = a.toLowerCase());
  if (!(-1 < "hex utf8 utf-8 ascii binary base64 ucs2 ucs-2 utf16le utf-16le raw".split(" ").indexOf((a + "").toLowerCase()))) throw new TypeError("Unknown encoding: " + a);
  this._writableState.defaultEncoding = a;
  return this;
};

function decodeChunk(a, b, c) {
  a.objectMode || !1 === a.decodeStrings || "string" !== typeof b || (b = Buffer.from(b, c));
  return b;
}

function writeOrBuffer(a, b, c, d, e) {
  c = decodeChunk(b, c, d);
  Buffer.isBuffer(c) && (d = "buffer");
  var f = b.objectMode ? 1 : c.length;
  b.length += f;
  var g = b.length < b.highWaterMark;
  g || (b.needDrain = !0);
  b.writing || b.corked ? (a = b.lastBufferedRequest, b.lastBufferedRequest = new WriteReq(c, d, e), a ? a.next = b.lastBufferedRequest : b.bufferedRequest = b.lastBufferedRequest, b.bufferedRequestCount += 1) : doWrite(a, b, !1, f, c, d, e);
  return g;
}

function doWrite(a, b, c, d, e, f, g) {
  b.writelen = d;
  b.writecb = g;
  b.writing = !0;
  b.sync = !0;
  c ? a._writev(e, b.onwrite) : a._write(e, f, b.onwrite);
  b.sync = !1;
}

function onwriteError(a, b, c, d, e) {
  --b.pendingcb;
  c ? nextTick(e, d) : e(d);
  a._writableState.errorEmitted = !0;
  a.emit("error", d);
}

function onwriteStateUpdate(a) {
  a.writing = !1;
  a.writecb = null;
  a.length -= a.writelen;
  a.writelen = 0;
}

function onwrite(a, b) {
  var c = a._writableState,
      d = c.sync,
      e = c.writecb;
  onwriteStateUpdate(c);
  b ? onwriteError(a, c, d, b, e) : ((b = needFinish(c)) || c.corked || c.bufferProcessing || !c.bufferedRequest || clearBuffer(a, c), d ? nextTick(afterWrite, a, c, b, e) : afterWrite(a, c, b, e));
}

function afterWrite(a, b, c, d) {
  c || onwriteDrain(a, b);
  b.pendingcb--;
  d();
  finishMaybe(a, b);
}

function onwriteDrain(a, b) {
  0 === b.length && b.needDrain && (b.needDrain = !1, a.emit("drain"));
}

function clearBuffer(a, b) {
  b.bufferProcessing = !0;
  var c = b.bufferedRequest;

  if (a._writev && c && c.next) {
    var d = Array(b.bufferedRequestCount),
        e = b.corkedRequestsFree;
    e.entry = c;

    for (var f = 0; c;) d[f] = c, c = c.next, f += 1;

    doWrite(a, b, !0, b.length, d, "", e.finish);
    b.pendingcb++;
    b.lastBufferedRequest = null;
    e.next ? (b.corkedRequestsFree = e.next, e.next = null) : b.corkedRequestsFree = new CorkedRequest(b);
  } else {
    for (; c && (d = c.chunk, doWrite(a, b, !1, b.objectMode ? 1 : d.length, d, c.encoding, c.callback), c = c.next, !b.writing););

    null === c && (b.lastBufferedRequest = null);
  }

  b.bufferedRequestCount = 0;
  b.bufferedRequest = c;
  b.bufferProcessing = !1;
}

Writable.prototype._write = function (a, b, c) {
  c(Error("not implemented"));
};

Writable.prototype._writev = null;

Writable.prototype.end = function (a, b, c) {
  var d = this._writableState;
  "function" === typeof a ? (c = a, b = a = null) : "function" === typeof b && (c = b, b = null);
  null !== a && void 0 !== a && this.write(a, b);
  d.corked && (d.corked = 1, this.uncork());
  d.ending || d.finished || endWritable(this, d, c);
};

function needFinish(a) {
  return a.ending && 0 === a.length && null === a.bufferedRequest && !a.finished && !a.writing;
}

function prefinish(a, b) {
  b.prefinished || (b.prefinished = !0, a.emit("prefinish"));
}

function finishMaybe(a, b) {
  var c = needFinish(b);
  c && (0 === b.pendingcb ? (prefinish(a, b), b.finished = !0, a.emit("finish")) : prefinish(a, b));
  return c;
}

function endWritable(a, b, c) {
  b.ending = !0;
  finishMaybe(a, b);
  if (c) if (b.finished) nextTick(c);else a.once("finish", c);
  b.ended = !0;
  a.writable = !1;
}

function CorkedRequest(a) {
  var b = this;
  this.entry = this.next = null;

  this.finish = function (c) {
    var d = b.entry;

    for (b.entry = null; d;) {
      var e = d.callback;
      a.pendingcb--;
      e(c);
      d = d.next;
    }

    a.corkedRequestsFree ? a.corkedRequestsFree.next = b : a.corkedRequestsFree = b;
  };
}

inherits$1(Duplex, Readable);

for (var keys = Object.keys(Writable.prototype), v = 0; v < keys.length; v++) {
  var method = keys[v];
  Duplex.prototype[method] || (Duplex.prototype[method] = Writable.prototype[method]);
}

function Duplex(a) {
  if (!(this instanceof Duplex)) return new Duplex(a);
  Readable.call(this, a);
  Writable.call(this, a);
  a && !1 === a.readable && (this.readable = !1);
  a && !1 === a.writable && (this.writable = !1);
  this.allowHalfOpen = !0;
  a && !1 === a.allowHalfOpen && (this.allowHalfOpen = !1);
  this.once("end", onend);
}

function onend() {
  this.allowHalfOpen || this._writableState.ended || nextTick(onEndNT, this);
}

function onEndNT(a) {
  a.end();
}

inherits$1(Transform, Duplex);

function TransformState(a) {
  this.afterTransform = function (b, c) {
    return afterTransform(a, b, c);
  };

  this.transforming = this.needTransform = !1;
  this.writeencoding = this.writechunk = this.writecb = null;
}

function afterTransform(a, b, c) {
  var d = a._transformState;
  d.transforming = !1;
  var e = d.writecb;
  if (!e) return a.emit("error", Error("no writecb in Transform class"));
  d.writechunk = null;
  d.writecb = null;
  null !== c && void 0 !== c && a.push(c);
  e(b);
  b = a._readableState;
  b.reading = !1;
  (b.needReadable || b.length < b.highWaterMark) && a._read(b.highWaterMark);
}

function Transform(a) {
  if (!(this instanceof Transform)) return new Transform(a);
  Duplex.call(this, a);
  this._transformState = new TransformState(this);
  var b = this;
  this._readableState.needReadable = !0;
  this._readableState.sync = !1;
  a && ("function" === typeof a.transform && (this._transform = a.transform), "function" === typeof a.flush && (this._flush = a.flush));
  this.once("prefinish", function () {
    "function" === typeof this._flush ? this._flush(function (a) {
      done(b, a);
    }) : done(b);
  });
}

Transform.prototype.push = function (a, b) {
  this._transformState.needTransform = !1;
  return Duplex.prototype.push.call(this, a, b);
};

Transform.prototype._transform = function (a, b, c) {
  throw Error("Not implemented");
};

Transform.prototype._write = function (a, b, c) {
  var d = this._transformState;
  d.writecb = c;
  d.writechunk = a;
  d.writeencoding = b;
  d.transforming || (a = this._readableState, (d.needTransform || a.needReadable || a.length < a.highWaterMark) && this._read(a.highWaterMark));
};

Transform.prototype._read = function (a) {
  a = this._transformState;
  null !== a.writechunk && a.writecb && !a.transforming ? (a.transforming = !0, this._transform(a.writechunk, a.writeencoding, a.afterTransform)) : a.needTransform = !0;
};

function done(a, b) {
  if (b) return a.emit("error", b);
  b = a._transformState;
  if (a._writableState.length) throw Error("Calling transform done when ws.length != 0");
  if (b.transforming) throw Error("Calling transform done when still transforming");
  return a.push(null);
}

inherits$1(PassThrough, Transform);

function PassThrough(a) {
  if (!(this instanceof PassThrough)) return new PassThrough(a);
  Transform.call(this, a);
}

PassThrough.prototype._transform = function (a, b, c) {
  c(null, a);
};

inherits$1(Stream, EventEmitter);
Stream.Readable = Readable;
Stream.Writable = Writable;
Stream.Duplex = Duplex;
Stream.Transform = Transform;
Stream.PassThrough = PassThrough;
Stream.Stream = Stream;

function Stream() {
  EventEmitter.call(this);
}

Stream.prototype.pipe = function (a, b) {
  function c(b) {
    a.writable && !1 === a.write(b) && l.pause && l.pause();
  }

  function d() {
    l.readable && l.resume && l.resume();
  }

  function e() {
    k || (k = !0, a.end());
  }

  function f() {
    k || (k = !0, "function" === typeof a.destroy && a.destroy());
  }

  function g(a) {
    h();
    if (0 === EventEmitter.listenerCount(this, "error")) throw a;
  }

  function h() {
    l.removeListener("data", c);
    a.removeListener("drain", d);
    l.removeListener("end", e);
    l.removeListener("close", f);
    l.removeListener("error", g);
    a.removeListener("error", g);
    l.removeListener("end", h);
    l.removeListener("close", h);
    a.removeListener("close", h);
  }

  var l = this;
  l.on("data", c);
  a.on("drain", d);
  a._isStdio || b && !1 === b.end || (l.on("end", e), l.on("close", f));
  var k = !1;
  l.on("error", g);
  a.on("error", g);
  l.on("end", h);
  l.on("close", h);
  a.on("close", h);
  a.emit("pipe", l);
  return a;
};

var slice = Array.prototype.slice,
    extend = function extend(a, b) {
  for (var d in b) a[d] = b[d];

  return 3 > arguments.length ? a : extend.apply(null, [a].concat(slice.call(arguments, 2)));
},
    fastExtend = {
  extend
},
    promises = createCommonjsModule(function (a, b) {
  function c(a, b, c) {
    void 0 === c && (c = function (a) {
      return a;
    });
    return function () {
      for (var e = [], f = 0; f < arguments.length; f++) e[f] = arguments[f];

      return new Promise(function (f, g) {
        a[b].bind(a).apply(void 0, d(e, [function (a, b) {
          return a ? g(a) : f(c(b));
        }]));
      });
    };
  }

  var d = commonjsGlobal && commonjsGlobal.__spreadArrays || function () {
    for (var a = 0, b = 0, c = arguments.length; b < c; b++) a += arguments[b].length;

    a = Array(a);
    var d = 0;

    for (b = 0; b < c; b++) for (var e = arguments[b], m = 0, p = e.length; m < p; m++, d++) a[d] = e[m];

    return a;
  };

  Object.defineProperty(b, "__esModule", {
    value: !0
  });

  var e = function () {
    function a(a, b) {
      this.vol = a;
      this.fd = b;
    }

    a.prototype.appendFile = function (a, b) {
      return c(this.vol, "appendFile")(this.fd, a, b);
    };

    a.prototype.chmod = function (a) {
      return c(this.vol, "fchmod")(this.fd, a);
    };

    a.prototype.chown = function (a, b) {
      return c(this.vol, "fchown")(this.fd, a, b);
    };

    a.prototype.close = function () {
      return c(this.vol, "close")(this.fd);
    };

    a.prototype.datasync = function () {
      return c(this.vol, "fdatasync")(this.fd);
    };

    a.prototype.read = function (a, b, d, e) {
      return c(this.vol, "read", function (b) {
        return {
          bytesRead: b,
          buffer: a
        };
      })(this.fd, a, b, d, e);
    };

    a.prototype.readFile = function (a) {
      return c(this.vol, "readFile")(this.fd, a);
    };

    a.prototype.stat = function (a) {
      return c(this.vol, "fstat")(this.fd, a);
    };

    a.prototype.sync = function () {
      return c(this.vol, "fsync")(this.fd);
    };

    a.prototype.truncate = function (a) {
      return c(this.vol, "ftruncate")(this.fd, a);
    };

    a.prototype.utimes = function (a, b) {
      return c(this.vol, "futimes")(this.fd, a, b);
    };

    a.prototype.write = function (a, b, d, e) {
      return c(this.vol, "write", function (b) {
        return {
          bytesWritten: b,
          buffer: a
        };
      })(this.fd, a, b, d, e);
    };

    a.prototype.writeFile = function (a, b) {
      return c(this.vol, "writeFile")(this.fd, a, b);
    };

    return a;
  }();

  b.FileHandle = e;

  b.default = function (a) {
    return "undefined" === typeof Promise ? null : {
      FileHandle: e,
      access: function (b, d) {
        return c(a, "access")(b, d);
      },
      appendFile: function (b, d, f) {
        return c(a, "appendFile")(b instanceof e ? b.fd : b, d, f);
      },
      chmod: function (b, d) {
        return c(a, "chmod")(b, d);
      },
      chown: function (b, d, e) {
        return c(a, "chown")(b, d, e);
      },
      copyFile: function (b, d, e) {
        return c(a, "copyFile")(b, d, e);
      },
      lchmod: function (b, d) {
        return c(a, "lchmod")(b, d);
      },
      lchown: function (b, d, e) {
        return c(a, "lchown")(b, d, e);
      },
      link: function (b, d) {
        return c(a, "link")(b, d);
      },
      lstat: function (b, d) {
        return c(a, "lstat")(b, d);
      },
      mkdir: function (b, d) {
        return c(a, "mkdir")(b, d);
      },
      mkdtemp: function (b, d) {
        return c(a, "mkdtemp")(b, d);
      },
      open: function (b, d, f) {
        return c(a, "open", function (b) {
          return new e(a, b);
        })(b, d, f);
      },
      readdir: function (b, d) {
        return c(a, "readdir")(b, d);
      },
      readFile: function (b, d) {
        return c(a, "readFile")(b instanceof e ? b.fd : b, d);
      },
      readlink: function (b, d) {
        return c(a, "readlink")(b, d);
      },
      realpath: function (b, d) {
        return c(a, "realpath")(b, d);
      },
      rename: function (b, d) {
        return c(a, "rename")(b, d);
      },
      rmdir: function (b) {
        return c(a, "rmdir")(b);
      },
      stat: function (b, d) {
        return c(a, "stat")(b, d);
      },
      symlink: function (b, d, e) {
        return c(a, "symlink")(b, d, e);
      },
      truncate: function (b, d) {
        return c(a, "truncate")(b, d);
      },
      unlink: function (b) {
        return c(a, "unlink")(b);
      },
      utimes: function (b, d, e) {
        return c(a, "utimes")(b, d, e);
      },
      writeFile: function (b, d, f) {
        return c(a, "writeFile")(b instanceof e ? b.fd : b, d, f);
      }
    };
  };
});

unwrapExports(promises);
var promises_1 = promises.FileHandle,
    maxInt = 2147483647,
    base = 36,
    tMin = 1,
    tMax = 26,
    skew = 38,
    damp = 700,
    initialBias = 72,
    initialN = 128,
    delimiter$1 = "-",
    regexNonASCII = /[^\x20-\x7E]/,
    regexSeparators = /[\x2E\u3002\uFF0E\uFF61]/g,
    errors$1 = {
  overflow: "Overflow: input needs wider integers to process",
  "not-basic": "Illegal input >= 0x80 (not a basic code point)",
  "invalid-input": "Invalid input"
},
    baseMinusTMin = base - tMin,
    floor = Math.floor,
    stringFromCharCode = String.fromCharCode;

function error(a) {
  throw new RangeError(errors$1[a]);
}

function map(a, b) {
  for (var c = a.length, d = []; c--;) d[c] = b(a[c]);

  return d;
}

function mapDomain(a, b) {
  var c = a.split("@"),
      d = "";
  1 < c.length && (d = c[0] + "@", a = c[1]);
  a = a.replace(regexSeparators, ".");
  a = a.split(".");
  b = map(a, b).join(".");
  return d + b;
}

function ucs2decode(a) {
  for (var b = [], c = 0, d = a.length, e, f; c < d;) e = a.charCodeAt(c++), 55296 <= e && 56319 >= e && c < d ? (f = a.charCodeAt(c++), 56320 == (f & 64512) ? b.push(((e & 1023) << 10) + (f & 1023) + 65536) : (b.push(e), c--)) : b.push(e);

  return b;
}

function digitToBasic(a, b) {
  return a + 22 + 75 * (26 > a) - ((0 != b) << 5);
}

function adapt(a, b, c) {
  var d = 0;
  a = c ? floor(a / damp) : a >> 1;

  for (a += floor(a / b); a > baseMinusTMin * tMax >> 1; d += base) a = floor(a / baseMinusTMin);

  return floor(d + (baseMinusTMin + 1) * a / (a + skew));
}

function encode(a) {
  var b,
      c,
      d,
      e = [];
  a = ucs2decode(a);
  var f = a.length;
  var g = initialN;
  var h = 0;
  var l = initialBias;

  for (d = 0; d < f; ++d) {
    var k = a[d];
    128 > k && e.push(stringFromCharCode(k));
  }

  for ((b = c = e.length) && e.push(delimiter$1); b < f;) {
    var m = maxInt;

    for (d = 0; d < f; ++d) k = a[d], k >= g && k < m && (m = k);

    var p = b + 1;
    m - g > floor((maxInt - h) / p) && error("overflow");
    h += (m - g) * p;
    g = m;

    for (d = 0; d < f; ++d) if (k = a[d], k < g && ++h > maxInt && error("overflow"), k == g) {
      var r = h;

      for (m = base;; m += base) {
        k = m <= l ? tMin : m >= l + tMax ? tMax : m - l;
        if (r < k) break;
        var u = r - k;
        r = base - k;
        e.push(stringFromCharCode(digitToBasic(k + u % r, 0)));
        r = floor(u / r);
      }

      e.push(stringFromCharCode(digitToBasic(r, 0)));
      l = adapt(h, p, b == c);
      h = 0;
      ++b;
    }

    ++h;
    ++g;
  }

  return e.join("");
}

function toASCII(a) {
  return mapDomain(a, function (a) {
    return regexNonASCII.test(a) ? "xn--" + encode(a) : a;
  });
}

function hasOwnProperty$1(a, b) {
  return Object.prototype.hasOwnProperty.call(a, b);
}

var isArray$2 = Array.isArray || function (a) {
  return "[object Array]" === Object.prototype.toString.call(a);
};

function stringifyPrimitive(a) {
  switch (typeof a) {
    case "string":
      return a;

    case "boolean":
      return a ? "true" : "false";

    case "number":
      return isFinite(a) ? a : "";

    default:
      return "";
  }
}

function stringify(a, b, c, d) {
  b = b || "&";
  c = c || "=";
  null === a && (a = void 0);
  return "object" === typeof a ? map$1(objectKeys$1(a), function (d) {
    var e = encodeURIComponent(stringifyPrimitive(d)) + c;
    return isArray$2(a[d]) ? map$1(a[d], function (a) {
      return e + encodeURIComponent(stringifyPrimitive(a));
    }).join(b) : e + encodeURIComponent(stringifyPrimitive(a[d]));
  }).join(b) : d ? encodeURIComponent(stringifyPrimitive(d)) + c + encodeURIComponent(stringifyPrimitive(a)) : "";
}

function map$1(a, b) {
  if (a.map) return a.map(b);

  for (var c = [], d = 0; d < a.length; d++) c.push(b(a[d], d));

  return c;
}

var objectKeys$1 = Object.keys || function (a) {
  var b = [],
      c;

  for (c in a) Object.prototype.hasOwnProperty.call(a, c) && b.push(c);

  return b;
};

function parse(a, b, c, d) {
  c = c || "=";
  var e = {};
  if ("string" !== typeof a || 0 === a.length) return e;
  var f = /\+/g;
  a = a.split(b || "&");
  b = 1000;
  d && "number" === typeof d.maxKeys && (b = d.maxKeys);
  d = a.length;
  0 < b && d > b && (d = b);

  for (b = 0; b < d; ++b) {
    var g = a[b].replace(f, "%20"),
        h = g.indexOf(c);

    if (0 <= h) {
      var l = g.substr(0, h);
      g = g.substr(h + 1);
    } else l = g, g = "";

    l = decodeURIComponent(l);
    g = decodeURIComponent(g);
    hasOwnProperty$1(e, l) ? isArray$2(e[l]) ? e[l].push(g) : e[l] = [e[l], g] : e[l] = g;
  }

  return e;
}

var require$$1 = {
  parse: urlParse,
  resolve: urlResolve,
  resolveObject: urlResolveObject,
  format: urlFormat,
  Url
};

function Url() {
  this.href = this.path = this.pathname = this.query = this.search = this.hash = this.hostname = this.port = this.host = this.auth = this.slashes = this.protocol = null;
}

var protocolPattern = /^([a-z0-9.+-]+:)/i,
    portPattern = /:[0-9]*$/,
    simplePathPattern = /^(\/\/?(?!\/)[^\?\s]*)(\?[^\s]*)?$/,
    delims = "<>\"` \r\n\t".split(""),
    unwise = "{}|\\^`".split("").concat(delims),
    autoEscape = ["'"].concat(unwise),
    nonHostChars = ["%", "/", "?", ";", "#"].concat(autoEscape),
    hostEndingChars = ["/", "?", "#"],
    hostnameMaxLen = 255,
    hostnamePartPattern = /^[+a-z0-9A-Z_-]{0,63}$/,
    hostnamePartStart = /^([+a-z0-9A-Z_-]{0,63})(.*)$/,
    unsafeProtocol = {
  javascript: !0,
  "javascript:": !0
},
    hostlessProtocol = {
  javascript: !0,
  "javascript:": !0
},
    slashedProtocol = {
  http: !0,
  https: !0,
  ftp: !0,
  gopher: !0,
  file: !0,
  "http:": !0,
  "https:": !0,
  "ftp:": !0,
  "gopher:": !0,
  "file:": !0
};

function urlParse(a, b, c) {
  if (a && isObject(a) && a instanceof Url) return a;
  var d = new Url();
  d.parse(a, b, c);
  return d;
}

Url.prototype.parse = function (a, b, c) {
  return parse$1(this, a, b, c);
};

function parse$1(a, b, c, d) {
  if (!isString(b)) throw new TypeError("Parameter 'url' must be a string, not " + typeof b);
  var e = b.indexOf("?");
  e = -1 !== e && e < b.indexOf("#") ? "?" : "#";
  b = b.split(e);
  b[0] = b[0].replace(/\\/g, "/");
  b = b.join(e);
  e = b.trim();
  if (!d && 1 === b.split("#").length && (b = simplePathPattern.exec(e))) return a.path = e, a.href = e, a.pathname = b[1], b[2] ? (a.search = b[2], a.query = c ? parse(a.search.substr(1)) : a.search.substr(1)) : c && (a.search = "", a.query = {}), a;

  if (b = protocolPattern.exec(e)) {
    b = b[0];
    var f = b.toLowerCase();
    a.protocol = f;
    e = e.substr(b.length);
  }

  if (d || b || e.match(/^\/\/[^@\/]+@[^@\/]+/)) {
    var g = "//" === e.substr(0, 2);
    !g || b && hostlessProtocol[b] || (e = e.substr(2), a.slashes = !0);
  }

  if (!hostlessProtocol[b] && (g || b && !slashedProtocol[b])) {
    b = -1;

    for (d = 0; d < hostEndingChars.length; d++) g = e.indexOf(hostEndingChars[d]), -1 !== g && (-1 === b || g < b) && (b = g);

    g = -1 === b ? e.lastIndexOf("@") : e.lastIndexOf("@", b);
    -1 !== g && (d = e.slice(0, g), e = e.slice(g + 1), a.auth = decodeURIComponent(d));
    b = -1;

    for (d = 0; d < nonHostChars.length; d++) g = e.indexOf(nonHostChars[d]), -1 !== g && (-1 === b || g < b) && (b = g);

    -1 === b && (b = e.length);
    a.host = e.slice(0, b);
    e = e.slice(b);
    parseHost(a);
    a.hostname = a.hostname || "";
    g = "[" === a.hostname[0] && "]" === a.hostname[a.hostname.length - 1];

    if (!g) {
      var h = a.hostname.split(/\./);
      d = 0;

      for (b = h.length; d < b; d++) {
        var l = h[d];

        if (l && !l.match(hostnamePartPattern)) {
          for (var k = "", m = 0, p = l.length; m < p; m++) k = 127 < l.charCodeAt(m) ? k + "x" : k + l[m];

          if (!k.match(hostnamePartPattern)) {
            b = h.slice(0, d);
            d = h.slice(d + 1);
            if (l = l.match(hostnamePartStart)) b.push(l[1]), d.unshift(l[2]);
            d.length && (e = "/" + d.join(".") + e);
            a.hostname = b.join(".");
            break;
          }
        }
      }
    }

    a.hostname = a.hostname.length > hostnameMaxLen ? "" : a.hostname.toLowerCase();
    g || (a.hostname = toASCII(a.hostname));
    d = a.port ? ":" + a.port : "";
    a.host = (a.hostname || "") + d;
    a.href += a.host;
    g && (a.hostname = a.hostname.substr(1, a.hostname.length - 2), "/" !== e[0] && (e = "/" + e));
  }

  if (!unsafeProtocol[f]) for (d = 0, b = autoEscape.length; d < b; d++) g = autoEscape[d], -1 !== e.indexOf(g) && (l = encodeURIComponent(g), l === g && (l = escape(g)), e = e.split(g).join(l));
  d = e.indexOf("#");
  -1 !== d && (a.hash = e.substr(d), e = e.slice(0, d));
  d = e.indexOf("?");
  -1 !== d ? (a.search = e.substr(d), a.query = e.substr(d + 1), c && (a.query = parse(a.query)), e = e.slice(0, d)) : c && (a.search = "", a.query = {});
  e && (a.pathname = e);
  slashedProtocol[f] && a.hostname && !a.pathname && (a.pathname = "/");
  if (a.pathname || a.search) d = a.pathname || "", a.path = d + (a.search || "");
  a.href = format$1(a);
  return a;
}

function urlFormat(a) {
  isString(a) && (a = parse$1({}, a));
  return format$1(a);
}

function format$1(a) {
  var b = a.auth || "";
  b && (b = encodeURIComponent(b), b = b.replace(/%3A/i, ":"), b += "@");
  var c = a.protocol || "",
      d = a.pathname || "",
      e = a.hash || "",
      f = !1,
      g = "";
  a.host ? f = b + a.host : a.hostname && (f = b + (-1 === a.hostname.indexOf(":") ? a.hostname : "[" + this.hostname + "]"), a.port && (f += ":" + a.port));
  a.query && isObject(a.query) && Object.keys(a.query).length && (g = stringify(a.query));
  b = a.search || g && "?" + g || "";
  c && ":" !== c.substr(-1) && (c += ":");
  a.slashes || (!c || slashedProtocol[c]) && !1 !== f ? (f = "//" + (f || ""), d && "/" !== d.charAt(0) && (d = "/" + d)) : f || (f = "");
  e && "#" !== e.charAt(0) && (e = "#" + e);
  b && "?" !== b.charAt(0) && (b = "?" + b);
  d = d.replace(/[?#]/g, function (a) {
    return encodeURIComponent(a);
  });
  b = b.replace("#", "%23");
  return c + f + d + b + e;
}

Url.prototype.format = function () {
  return format$1(this);
};

function urlResolve(a, b) {
  return urlParse(a, !1, !0).resolve(b);
}

Url.prototype.resolve = function (a) {
  return this.resolveObject(urlParse(a, !1, !0)).format();
};

function urlResolveObject(a, b) {
  return a ? urlParse(a, !1, !0).resolveObject(b) : b;
}

Url.prototype.resolveObject = function (a) {
  if (isString(a)) {
    var b = new Url();
    b.parse(a, !1, !0);
    a = b;
  }

  b = new Url();

  for (var c = Object.keys(this), d = 0; d < c.length; d++) {
    var e = c[d];
    b[e] = this[e];
  }

  b.hash = a.hash;
  if ("" === a.href) return b.href = b.format(), b;

  if (a.slashes && !a.protocol) {
    c = Object.keys(a);

    for (d = 0; d < c.length; d++) e = c[d], "protocol" !== e && (b[e] = a[e]);

    slashedProtocol[b.protocol] && b.hostname && !b.pathname && (b.path = b.pathname = "/");
    b.href = b.format();
    return b;
  }

  var f;

  if (a.protocol && a.protocol !== b.protocol) {
    if (!slashedProtocol[a.protocol]) {
      c = Object.keys(a);

      for (d = 0; d < c.length; d++) e = c[d], b[e] = a[e];

      b.href = b.format();
      return b;
    }

    b.protocol = a.protocol;
    if (a.host || hostlessProtocol[a.protocol]) b.pathname = a.pathname;else {
      for (f = (a.pathname || "").split("/"); f.length && !(a.host = f.shift()););

      a.host || (a.host = "");
      a.hostname || (a.hostname = "");
      "" !== f[0] && f.unshift("");
      2 > f.length && f.unshift("");
      b.pathname = f.join("/");
    }
    b.search = a.search;
    b.query = a.query;
    b.host = a.host || "";
    b.auth = a.auth;
    b.hostname = a.hostname || a.host;
    b.port = a.port;
    if (b.pathname || b.search) b.path = (b.pathname || "") + (b.search || "");
    b.slashes = b.slashes || a.slashes;
    b.href = b.format();
    return b;
  }

  c = b.pathname && "/" === b.pathname.charAt(0);
  var g = a.host || a.pathname && "/" === a.pathname.charAt(0),
      h = c = g || c || b.host && a.pathname;
  d = b.pathname && b.pathname.split("/") || [];
  e = b.protocol && !slashedProtocol[b.protocol];
  f = a.pathname && a.pathname.split("/") || [];
  e && (b.hostname = "", b.port = null, b.host && ("" === d[0] ? d[0] = b.host : d.unshift(b.host)), b.host = "", a.protocol && (a.hostname = null, a.port = null, a.host && ("" === f[0] ? f[0] = a.host : f.unshift(a.host)), a.host = null), c = c && ("" === f[0] || "" === d[0]));
  if (g) b.host = a.host || "" === a.host ? a.host : b.host, b.hostname = a.hostname || "" === a.hostname ? a.hostname : b.hostname, b.search = a.search, b.query = a.query, d = f;else if (f.length) d || (d = []), d.pop(), d = d.concat(f), b.search = a.search, b.query = a.query;else if (!isNullOrUndefined(a.search)) return e && (b.hostname = b.host = d.shift(), e = b.host && 0 < b.host.indexOf("@") ? b.host.split("@") : !1) && (b.auth = e.shift(), b.host = b.hostname = e.shift()), b.search = a.search, b.query = a.query, isNull(b.pathname) && isNull(b.search) || (b.path = (b.pathname ? b.pathname : "") + (b.search ? b.search : "")), b.href = b.format(), b;
  if (!d.length) return b.pathname = null, b.path = b.search ? "/" + b.search : null, b.href = b.format(), b;
  g = d.slice(-1)[0];
  f = (b.host || a.host || 1 < d.length) && ("." === g || ".." === g) || "" === g;

  for (var l = 0, k = d.length; 0 <= k; k--) g = d[k], "." === g ? d.splice(k, 1) : ".." === g ? (d.splice(k, 1), l++) : l && (d.splice(k, 1), l--);

  if (!c && !h) for (; l--; l) d.unshift("..");
  !c || "" === d[0] || d[0] && "/" === d[0].charAt(0) || d.unshift("");
  f && "/" !== d.join("/").substr(-1) && d.push("");
  h = "" === d[0] || d[0] && "/" === d[0].charAt(0);
  e && (b.hostname = b.host = h ? "" : d.length ? d.shift() : "", e = b.host && 0 < b.host.indexOf("@") ? b.host.split("@") : !1) && (b.auth = e.shift(), b.host = b.hostname = e.shift());
  (c = c || b.host && d.length) && !h && d.unshift("");
  d.length ? b.pathname = d.join("/") : (b.pathname = null, b.path = null);
  isNull(b.pathname) && isNull(b.search) || (b.path = (b.pathname ? b.pathname : "") + (b.search ? b.search : ""));
  b.auth = a.auth || b.auth;
  b.slashes = b.slashes || a.slashes;
  b.href = b.format();
  return b;
};

Url.prototype.parseHost = function () {
  return parseHost(this);
};

function parseHost(a) {
  var b = a.host,
      c = portPattern.exec(b);
  c && (c = c[0], ":" !== c && (a.port = c.substr(1)), b = b.substr(0, b.length - c.length));
  b && (a.hostname = b);
}

var correctPath_1 = createCommonjsModule(function (a, b) {
  function c(a, b) {
    a = a[b];
    return 0 < b && ("/" === a || e && "\\" === a);
  }

  function d(a) {
    var b = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : !0;

    if (e) {
      var d = a;
      if ("string" !== typeof d) throw new TypeError("expected a string");
      d = d.replace(/[\\\/]+/g, "/");
      if (!1 !== b) if (b = d, d = b.length - 1, 2 > d) d = b;else {
        for (; c(b, d);) d--;

        d = b.substr(0, d + 1);
      }
      return d.replace(/^([a-zA-Z]+:|\.\/)/, "");
    }

    return a;
  }

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  b.unixify = d;

  b.correctPath = function (a) {
    return d(a.replace(/^\\\\\?\\.:\\/, "\\"));
  };

  var e = "win32" === process.platform;
});
unwrapExports(correctPath_1);
var correctPath_2 = correctPath_1.unixify,
    correctPath_3 = correctPath_1.correctPath,
    volume = createCommonjsModule(function (a, b) {
  function c(a, b, c, d) {
    void 0 === b && (b = "");
    void 0 === c && (c = "");
    void 0 === d && (d = "");
    var e = "";
    c && (e = " '" + c + "'");
    d && (e += " -> '" + d + "'");

    switch (a) {
      case "ENOENT":
        return "ENOENT: no such file or directory, " + b + e;

      case "EBADF":
        return "EBADF: bad file descriptor, " + b + e;

      case "EINVAL":
        return "EINVAL: invalid argument, " + b + e;

      case "EPERM":
        return "EPERM: operation not permitted, " + b + e;

      case "EPROTO":
        return "EPROTO: protocol error, " + b + e;

      case "EEXIST":
        return "EEXIST: file already exists, " + b + e;

      case "ENOTDIR":
        return "ENOTDIR: not a directory, " + b + e;

      case "EISDIR":
        return "EISDIR: illegal operation on a directory, " + b + e;

      case "EACCES":
        return "EACCES: permission denied, " + b + e;

      case "ENOTEMPTY":
        return "ENOTEMPTY: directory not empty, " + b + e;

      case "EMFILE":
        return "EMFILE: too many open files, " + b + e;

      case "ENOSYS":
        return "ENOSYS: function not implemented, " + b + e;

      default:
        return a + ": error occurred, " + b + e;
    }
  }

  function d(a, b, d, e, f) {
    void 0 === b && (b = "");
    void 0 === d && (d = "");
    void 0 === e && (e = "");
    void 0 === f && (f = Error);
    b = new f(c(a, b, d, e));
    b.code = a;
    return b;
  }

  function e(a) {
    if ("number" === typeof a) return a;

    if ("string" === typeof a) {
      var b = I[a];
      if ("undefined" !== typeof b) return b;
    }

    throw new errors.TypeError("ERR_INVALID_OPT_VALUE", "flags", a);
  }

  function f(a, b) {
    if (b) {
      var c = typeof b;

      switch (c) {
        case "string":
          a = C({}, a, {
            encoding: b
          });
          break;

        case "object":
          a = C({}, a, b);
          break;

        default:
          throw TypeError("Expected options to be either an object or a string, but got " + c + " instead");
      }
    } else return a;

    "buffer" !== a.encoding && encoding.assertEncoding(a.encoding);
    return a;
  }

  function g(a) {
    return function (b) {
      return f(a, b);
    };
  }

  function h(a) {
    if ("function" !== typeof a) throw TypeError(E.CB);
    return a;
  }

  function l(a) {
    return function (b, c) {
      return "function" === typeof b ? [a(), b] : [a(b), h(c)];
    };
  }

  function k(a) {
    if ("string" !== typeof a && !buffer.Buffer.isBuffer(a)) {
      try {
        if (!(a instanceof require$$1.URL)) throw new TypeError(E.PATH_STR);
      } catch (G) {
        throw new TypeError(E.PATH_STR);
      }

      if ("" !== a.hostname) throw new errors.TypeError("ERR_INVALID_FILE_URL_HOST", process_1.default.platform);
      a = a.pathname;

      for (var b = 0; b < a.length; b++) if ("%" === a[b]) {
        var c = a.codePointAt(b + 2) | 32;
        if ("2" === a[b + 1] && 102 === c) throw new errors.TypeError("ERR_INVALID_FILE_URL_PATH", "must not include encoded / characters");
      }

      a = decodeURIComponent(a);
    }

    a = String(a);
    B(a);
    return a;
  }

  function m(a, b) {
    return (a = T(a, b).substr(1)) ? a.split(y) : [];
  }

  function p(a) {
    return m(k(a));
  }

  function r(a, b) {
    void 0 === b && (b = encoding.ENCODING_UTF8);
    return buffer.Buffer.isBuffer(a) ? a : a instanceof Uint8Array ? buffer.bufferFrom(a) : buffer.bufferFrom(String(a), b);
  }

  function u(a, b) {
    return b && "buffer" !== b ? a.toString(b) : a;
  }

  function B(a, b) {
    if (-1 !== ("" + a).indexOf("\0")) {
      a = Error("Path must be a string without null bytes");
      a.code = "ENOENT";
      if ("function" !== typeof b) throw a;
      process_1.default.nextTick(b, a);
      return !1;
    }

    return !0;
  }

  function t(a, b) {
    a = "number" === typeof a ? a : "string" === typeof a ? parseInt(a, 8) : b ? t(b) : void 0;
    if ("number" !== typeof a || isNaN(a)) throw new TypeError(E.MODE_INT);
    return a;
  }

  function O(a) {
    if (a >>> 0 !== a) throw TypeError(E.FD);
  }

  function D(a) {
    if ("string" === typeof a && +a == a) return +a;
    if (a instanceof Date) return a.getTime() / 1000;
    if (isFinite(a)) return 0 > a ? Date.now() / 1000 : a;
    throw Error("Cannot parse time: " + a);
  }

  function K(a) {
    if ("number" !== typeof a) throw TypeError(E.UID);
  }

  function L(a) {
    if ("number" !== typeof a) throw TypeError(E.GID);
  }

  function sa(a) {
    a.emit("stop");
  }

  function z(a, b, c) {
    if (!(this instanceof z)) return new z(a, b, c);
    this._vol = a;
    c = C({}, f(c, {}));
    void 0 === c.highWaterMark && (c.highWaterMark = 65536);
    Stream.Readable.call(this, c);
    this.path = k(b);
    this.fd = void 0 === c.fd ? null : c.fd;
    this.flags = void 0 === c.flags ? "r" : c.flags;
    this.mode = void 0 === c.mode ? 438 : c.mode;
    this.start = c.start;
    this.end = c.end;
    this.autoClose = void 0 === c.autoClose ? !0 : c.autoClose;
    this.pos = void 0;
    this.bytesRead = 0;

    if (void 0 !== this.start) {
      if ("number" !== typeof this.start) throw new TypeError("\"start\" option must be a Number");
      if (void 0 === this.end) this.end = Infinity;else if ("number" !== typeof this.end) throw new TypeError("\"end\" option must be a Number");
      if (this.start > this.end) throw Error("\"start\" option must be <= \"end\" option");
      this.pos = this.start;
    }

    "number" !== typeof this.fd && this.open();
    this.on("end", function () {
      this.autoClose && this.destroy && this.destroy();
    });
  }

  function ta(a) {
    this.close();
  }

  function x(a, b, c) {
    if (!(this instanceof x)) return new x(a, b, c);
    this._vol = a;
    c = C({}, f(c, {}));
    Stream.Writable.call(this, c);
    this.path = k(b);
    this.fd = void 0 === c.fd ? null : c.fd;
    this.flags = void 0 === c.flags ? "w" : c.flags;
    this.mode = void 0 === c.mode ? 438 : c.mode;
    this.start = c.start;
    this.autoClose = void 0 === c.autoClose ? !0 : !!c.autoClose;
    this.pos = void 0;
    this.bytesWritten = 0;

    if (void 0 !== this.start) {
      if ("number" !== typeof this.start) throw new TypeError("\"start\" option must be a Number");
      if (0 > this.start) throw Error("\"start\" must be >= zero");
      this.pos = this.start;
    }

    c.encoding && this.setDefaultEncoding(c.encoding);
    "number" !== typeof this.fd && this.open();
    this.once("finish", function () {
      this.autoClose && this.close();
    });
  }

  var M = commonjsGlobal && commonjsGlobal.__extends || function () {
    var a = function (b, c) {
      a = Object.setPrototypeOf || {
        __proto__: []
      } instanceof Array && function (a, b) {
        a.__proto__ = b;
      } || function (a, b) {
        for (var c in b) b.hasOwnProperty(c) && (a[c] = b[c]);
      };

      return a(b, c);
    };

    return function (b, c) {
      function d() {
        this.constructor = b;
      }

      a(b, c);
      b.prototype = null === c ? Object.create(c) : (d.prototype = c.prototype, new d());
    };
  }(),
      X = commonjsGlobal && commonjsGlobal.__spreadArrays || function () {
    for (var a = 0, b = 0, c = arguments.length; b < c; b++) a += arguments[b].length;

    a = Array(a);
    var d = 0;

    for (b = 0; b < c; b++) for (var e = arguments[b], f = 0, g = e.length; f < g; f++, d++) a[d] = e[f];

    return a;
  };

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  var C = fastExtend.extend,
      ua = pathModule.resolve,
      U = constants.constants.O_RDONLY,
      N = constants.constants.O_WRONLY,
      H = constants.constants.O_RDWR,
      A = constants.constants.O_CREAT,
      V = constants.constants.O_EXCL,
      P = constants.constants.O_TRUNC,
      Q = constants.constants.O_APPEND,
      ba = constants.constants.O_SYNC,
      va = constants.constants.O_DIRECTORY,
      ca = constants.constants.F_OK,
      wa = constants.constants.COPYFILE_EXCL,
      xa = constants.constants.COPYFILE_FICLONE_FORCE;
  var y = pathModule.sep;
  var da = pathModule.relative;
  var Y = "win32" === process_1.default.platform,
      E = {
    PATH_STR: "path must be a string or Buffer",
    FD: "fd must be a file descriptor",
    MODE_INT: "mode must be an int",
    CB: "callback must be a function",
    UID: "uid must be an unsigned int",
    GID: "gid must be an unsigned int",
    LEN: "len must be an integer",
    ATIME: "atime must be an integer",
    MTIME: "mtime must be an integer",
    PREFIX: "filename prefix is required",
    BUFFER: "buffer must be an instance of Buffer or StaticBuffer",
    OFFSET: "offset must be an integer",
    LENGTH: "length must be an integer",
    POSITION: "position must be an integer"
  },
      I;

  (function (a) {
    a[a.r = U] = "r";
    a[a["r+"] = H] = "r+";
    a[a.rs = U | ba] = "rs";
    a[a.sr = a.rs] = "sr";
    a[a["rs+"] = H | ba] = "rs+";
    a[a["sr+"] = a["rs+"]] = "sr+";
    a[a.w = N | A | P] = "w";
    a[a.wx = N | A | P | V] = "wx";
    a[a.xw = a.wx] = "xw";
    a[a["w+"] = H | A | P] = "w+";
    a[a["wx+"] = H | A | P | V] = "wx+";
    a[a["xw+"] = a["wx+"]] = "xw+";
    a[a.a = N | Q | A] = "a";
    a[a.ax = N | Q | A | V] = "ax";
    a[a.xa = a.ax] = "xa";
    a[a["a+"] = H | Q | A] = "a+";
    a[a["ax+"] = H | Q | A | V] = "ax+";
    a[a["xa+"] = a["ax+"]] = "xa+";
  })(I = b.FLAGS || (b.FLAGS = {}));

  b.flagsToNumber = e;
  a = {
    encoding: "utf8"
  };

  var W = g(a),
      ea = l(W),
      fa = g({
    flag: "r"
  }),
      ha = {
    encoding: "utf8",
    mode: 438,
    flag: I[I.w]
  },
      ia = g(ha),
      ja = {
    encoding: "utf8",
    mode: 438,
    flag: I[I.a]
  },
      ka = g(ja),
      ya = l(ka),
      la = g(a),
      za = l(la),
      ma = {
    mode: 511,
    recursive: !1
  },
      na = function (a) {
    return "number" === typeof a ? C({}, ma, {
      mode: a
    }) : C({}, ma, a);
  },
      oa = {
    recursive: !1
  },
      pa = g({
    encoding: "utf8",
    withFileTypes: !1
  }),
      Aa = l(pa),
      Ba = {
    bigint: !1
  },
      R = function (a) {
    void 0 === a && (a = {});
    return C({}, Ba, a);
  },
      Z = function (a, b) {
    return "function" === typeof a ? [R(), a] : [R(a), h(b)];
  };

  b.pathToFilename = k;

  var T = function (a, b) {
    void 0 === b && (b = process_1.default.cwd());
    return ua(b, a);
  };

  if (Y) {
    var Ca = T,
        Da = correctPath_1.unixify;

    T = function (a, b) {
      return Da(Ca(a, b));
    };
  }

  b.filenameToSteps = m;
  b.pathToSteps = p;

  b.dataToStr = function (a, b) {
    void 0 === b && (b = encoding.ENCODING_UTF8);
    return buffer.Buffer.isBuffer(a) ? a.toString(b) : a instanceof Uint8Array ? buffer.bufferFrom(a).toString(b) : String(a);
  };

  b.dataToBuffer = r;
  b.bufferToEncoding = u;
  b.toUnixTimestamp = D;

  a = function () {
    function a(a) {
      void 0 === a && (a = {});
      this.ino = 0;
      this.inodes = {};
      this.releasedInos = [];
      this.fds = {};
      this.releasedFds = [];
      this.maxFiles = 10000;
      this.openFiles = 0;
      this.promisesApi = promises.default(this);
      this.statWatchers = {};
      this.props = C({
        Node: node.Node,
        Link: node.Link,
        File: node.File
      }, a);
      a = this.createLink();
      a.setNode(this.createNode(!0));
      var b = this;

      this.StatWatcher = function (a) {
        function c() {
          return a.call(this, b) || this;
        }

        M(c, a);
        return c;
      }(qa);

      this.ReadStream = function (a) {
        function c() {
          for (var c = [], d = 0; d < arguments.length; d++) c[d] = arguments[d];

          return a.apply(this, X([b], c)) || this;
        }

        M(c, a);
        return c;
      }(z);

      this.WriteStream = function (a) {
        function c() {
          for (var c = [], d = 0; d < arguments.length; d++) c[d] = arguments[d];

          return a.apply(this, X([b], c)) || this;
        }

        M(c, a);
        return c;
      }(x);

      this.FSWatcher = function (a) {
        function c() {
          return a.call(this, b) || this;
        }

        M(c, a);
        return c;
      }(ra);

      this.root = a;
    }

    a.fromJSON = function (b, c) {
      var d = new a();
      d.fromJSON(b, c);
      return d;
    };

    Object.defineProperty(a.prototype, "promises", {
      get: function () {
        if (null === this.promisesApi) throw Error("Promise is not supported in this environment.");
        return this.promisesApi;
      },
      enumerable: !0,
      configurable: !0
    });

    a.prototype.createLink = function (a, b, c, d) {
      void 0 === c && (c = !1);
      if (!a) return new this.props.Link(this, null, "");
      if (!b) throw Error("createLink: name cannot be empty");
      return a.createChild(b, this.createNode(c, d));
    };

    a.prototype.deleteLink = function (a) {
      var b = a.parent;
      return b ? (b.deleteChild(a), !0) : !1;
    };

    a.prototype.newInoNumber = function () {
      var a = this.releasedInos.pop();
      return a ? a : this.ino = (this.ino + 1) % 4294967295;
    };

    a.prototype.newFdNumber = function () {
      var b = this.releasedFds.pop();
      return "number" === typeof b ? b : a.fd--;
    };

    a.prototype.createNode = function (a, b) {
      void 0 === a && (a = !1);
      b = new this.props.Node(this.newInoNumber(), b);
      a && b.setIsDirectory();
      return this.inodes[b.ino] = b;
    };

    a.prototype.getNode = function (a) {
      return this.inodes[a];
    };

    a.prototype.deleteNode = function (a) {
      a.del();
      delete this.inodes[a.ino];
      this.releasedInos.push(a.ino);
    };

    a.prototype.genRndStr = function () {
      var a = (Math.random() + 1).toString(36).substr(2, 6);
      return 6 === a.length ? a : this.genRndStr();
    };

    a.prototype.getLink = function (a) {
      return this.root.walk(a);
    };

    a.prototype.getLinkOrThrow = function (a, b) {
      var c = m(a);
      c = this.getLink(c);
      if (!c) throw d("ENOENT", b, a);
      return c;
    };

    a.prototype.getResolvedLink = function (a) {
      a = "string" === typeof a ? m(a) : a;

      for (var b = this.root, c = 0; c < a.length;) {
        b = b.getChild(a[c]);
        if (!b) return null;
        var d = b.getNode();
        d.isSymlink() ? (a = d.symlink.concat(a.slice(c + 1)), b = this.root, c = 0) : c++;
      }

      return b;
    };

    a.prototype.getResolvedLinkOrThrow = function (a, b) {
      var c = this.getResolvedLink(a);
      if (!c) throw d("ENOENT", b, a);
      return c;
    };

    a.prototype.resolveSymlinks = function (a) {
      return this.getResolvedLink(a.steps.slice(1));
    };

    a.prototype.getLinkAsDirOrThrow = function (a, b) {
      var c = this.getLinkOrThrow(a, b);
      if (!c.getNode().isDirectory()) throw d("ENOTDIR", b, a);
      return c;
    };

    a.prototype.getLinkParent = function (a) {
      return this.root.walk(a, a.length - 1);
    };

    a.prototype.getLinkParentAsDirOrThrow = function (a, b) {
      a = a instanceof Array ? a : m(a);
      var c = this.getLinkParent(a);
      if (!c) throw d("ENOENT", b, y + a.join(y));
      if (!c.getNode().isDirectory()) throw d("ENOTDIR", b, y + a.join(y));
      return c;
    };

    a.prototype.getFileByFd = function (a) {
      return this.fds[String(a)];
    };

    a.prototype.getFileByFdOrThrow = function (a, b) {
      if (a >>> 0 !== a) throw TypeError(E.FD);
      a = this.getFileByFd(a);
      if (!a) throw d("EBADF", b);
      return a;
    };

    a.prototype.getNodeByIdOrCreate = function (a, b, c) {
      if ("number" === typeof a) {
        a = this.getFileByFd(a);
        if (!a) throw Error("File nto found");
        return a.node;
      }

      var e = p(a),
          n = this.getLink(e);
      if (n) return n.getNode();
      if (b & A && (b = this.getLinkParent(e))) return n = this.createLink(b, e[e.length - 1], !1, c), n.getNode();
      throw d("ENOENT", "getNodeByIdOrCreate", k(a));
    };

    a.prototype.wrapAsync = function (a, b, c) {
      var d = this;
      h(c);
      setImmediate_1.default(function () {
        try {
          c(null, a.apply(d, b));
        } catch (F) {
          c(F);
        }
      });
    };

    a.prototype._toJSON = function (a, b, c) {
      var d;
      void 0 === a && (a = this.root);
      void 0 === b && (b = {});
      var e = !0,
          n = a.children;
      a.getNode().isFile() && (n = (d = {}, d[a.getName()] = a.parent.getChild(a.getName()), d), a = a.parent);

      for (var q in n) {
        e = !1;
        n = a.getChild(q);
        if (!n) throw Error("_toJSON: unexpected undefined");
        d = n.getNode();
        d.isFile() ? (n = n.getPath(), c && (n = da(c, n)), b[n] = d.getString()) : d.isDirectory() && this._toJSON(n, b, c);
      }

      a = a.getPath();
      c && (a = da(c, a));
      a && e && (b[a] = null);
      return b;
    };

    a.prototype.toJSON = function (a, b, c) {
      void 0 === b && (b = {});
      void 0 === c && (c = !1);
      var d = [];

      if (a) {
        a instanceof Array || (a = [a]);

        for (var e = 0; e < a.length; e++) {
          var n = k(a[e]);
          (n = this.getResolvedLink(n)) && d.push(n);
        }
      } else d.push(this.root);

      if (!d.length) return b;

      for (e = 0; e < d.length; e++) n = d[e], this._toJSON(n, b, c ? n.getPath() : "");

      return b;
    };

    a.prototype.fromJSON = function (a, b) {
      void 0 === b && (b = process_1.default.cwd());

      for (var c in a) {
        var d = a[c];

        if ("string" === typeof d) {
          c = T(c, b);
          var e = m(c);
          1 < e.length && (e = y + e.slice(0, e.length - 1).join(y), this.mkdirpBase(e, 511));
          this.writeFileSync(c, d);
        } else this.mkdirpBase(c, 511);
      }
    };

    a.prototype.reset = function () {
      this.ino = 0;
      this.inodes = {};
      this.releasedInos = [];
      this.fds = {};
      this.releasedFds = [];
      this.openFiles = 0;
      this.root = this.createLink();
      this.root.setNode(this.createNode(!0));
    };

    a.prototype.mountSync = function (a, b) {
      this.fromJSON(b, a);
    };

    a.prototype.openLink = function (a, b, c) {
      void 0 === c && (c = !0);
      if (this.openFiles >= this.maxFiles) throw d("EMFILE", "open", a.getPath());
      var e = a;
      c && (e = this.resolveSymlinks(a));
      if (!e) throw d("ENOENT", "open", a.getPath());
      c = e.getNode();

      if (c.isDirectory()) {
        if ((b & (U | H | N)) !== U) throw d("EISDIR", "open", a.getPath());
      } else if (b & va) throw d("ENOTDIR", "open", a.getPath());

      if (!(b & N || c.canRead())) throw d("EACCES", "open", a.getPath());
      a = new this.props.File(a, c, b, this.newFdNumber());
      this.fds[a.fd] = a;
      this.openFiles++;
      b & P && a.truncate();
      return a;
    };

    a.prototype.openFile = function (a, b, c, e) {
      void 0 === e && (e = !0);
      var n = m(a),
          q = e ? this.getResolvedLink(n) : this.getLink(n);

      if (!q && b & A) {
        var G = this.getResolvedLink(n.slice(0, n.length - 1));
        if (!G) throw d("ENOENT", "open", y + n.join(y));
        b & A && "number" === typeof c && (q = this.createLink(G, n[n.length - 1], !1, c));
      }

      if (q) return this.openLink(q, b, e);
      throw d("ENOENT", "open", a);
    };

    a.prototype.openBase = function (a, b, c, e) {
      void 0 === e && (e = !0);
      b = this.openFile(a, b, c, e);
      if (!b) throw d("ENOENT", "open", a);
      return b.fd;
    };

    a.prototype.openSync = function (a, b, c) {
      void 0 === c && (c = 438);
      c = t(c);
      a = k(a);
      b = e(b);
      return this.openBase(a, b, c);
    };

    a.prototype.open = function (a, b, c, d) {
      var n = c;
      "function" === typeof c && (n = 438, d = c);
      c = t(n || 438);
      a = k(a);
      b = e(b);
      this.wrapAsync(this.openBase, [a, b, c], d);
    };

    a.prototype.closeFile = function (a) {
      this.fds[a.fd] && (this.openFiles--, delete this.fds[a.fd], this.releasedFds.push(a.fd));
    };

    a.prototype.closeSync = function (a) {
      O(a);
      a = this.getFileByFdOrThrow(a, "close");
      this.closeFile(a);
    };

    a.prototype.close = function (a, b) {
      O(a);
      this.wrapAsync(this.closeSync, [a], b);
    };

    a.prototype.openFileOrGetById = function (a, b, c) {
      if ("number" === typeof a) {
        a = this.fds[a];
        if (!a) throw d("ENOENT");
        return a;
      }

      return this.openFile(k(a), b, c);
    };

    a.prototype.readBase = function (a, b, c, d, e) {
      return this.getFileByFdOrThrow(a).read(b, Number(c), Number(d), e);
    };

    a.prototype.readSync = function (a, b, c, d, e) {
      O(a);
      return this.readBase(a, b, c, d, e);
    };

    a.prototype.read = function (a, b, c, d, e, f) {
      var n = this;
      h(f);
      if (0 === d) return process_1.default.nextTick(function () {
        f && f(null, 0, b);
      });
      setImmediate_1.default(function () {
        try {
          var q = n.readBase(a, b, c, d, e);
          f(null, q, b);
        } catch (Ea) {
          f(Ea);
        }
      });
    };

    a.prototype.readFileBase = function (a, b, c) {
      var e = "number" === typeof a && a >>> 0 === a;

      if (!e) {
        var n = k(a);
        n = m(n);
        if ((n = this.getResolvedLink(n)) && n.getNode().isDirectory()) throw d("EISDIR", "open", n.getPath());
        a = this.openSync(a, b);
      }

      try {
        var q = u(this.getFileByFdOrThrow(a).getBuffer(), c);
      } finally {
        e || this.closeSync(a);
      }

      return q;
    };

    a.prototype.readFileSync = function (a, b) {
      b = fa(b);
      var c = e(b.flag);
      return this.readFileBase(a, c, b.encoding);
    };

    a.prototype.readFile = function (a, b, c) {
      c = l(fa)(b, c);
      b = c[0];
      c = c[1];
      var d = e(b.flag);
      this.wrapAsync(this.readFileBase, [a, d, b.encoding], c);
    };

    a.prototype.writeBase = function (a, b, c, d, e) {
      return this.getFileByFdOrThrow(a, "write").write(b, c, d, e);
    };

    a.prototype.writeSync = function (a, b, c, d, e) {
      O(a);
      var n = "string" !== typeof b;

      if (n) {
        var q = (c || 0) | 0;
        var f = d;
        c = e;
      } else var G = d;

      b = r(b, G);
      n ? "undefined" === typeof f && (f = b.length) : (q = 0, f = b.length);
      return this.writeBase(a, b, q, f, c);
    };

    a.prototype.write = function (a, b, c, d, e, f) {
      var n = this;
      O(a);
      var q = typeof b,
          G = typeof c,
          g = typeof d,
          aa = typeof e;
      if ("string" !== q) {
        if ("function" === G) var k = c;else if ("function" === g) {
          var l = c | 0;
          k = d;
        } else if ("function" === aa) {
          l = c | 0;
          var m = d;
          k = e;
        } else {
          l = c | 0;
          m = d;
          var F = e;
          k = f;
        }
      } else if ("function" === G) k = c;else if ("function" === g) F = c, k = d;else if ("function" === aa) {
        F = c;
        var p = d;
        k = e;
      }
      var S = r(b, p);
      "string" !== q ? "undefined" === typeof m && (m = S.length) : (l = 0, m = S.length);
      var J = h(k);
      setImmediate_1.default(function () {
        try {
          var c = n.writeBase(a, S, l, m, F);
          "string" !== q ? J(null, c, S) : J(null, c, b);
        } catch (Fa) {
          J(Fa);
        }
      });
    };

    a.prototype.writeFileBase = function (a, b, c, d) {
      var e = "number" === typeof a;
      a = e ? a : this.openBase(k(a), c, d);
      d = 0;
      var n = b.length;
      c = c & Q ? void 0 : 0;

      try {
        for (; 0 < n;) {
          var q = this.writeSync(a, b, d, n, c);
          d += q;
          n -= q;
          void 0 !== c && (c += q);
        }
      } finally {
        e || this.closeSync(a);
      }
    };

    a.prototype.writeFileSync = function (a, b, c) {
      var d = ia(c);
      c = e(d.flag);
      var n = t(d.mode);
      b = r(b, d.encoding);
      this.writeFileBase(a, b, c, n);
    };

    a.prototype.writeFile = function (a, b, c, d) {
      var n = c;
      "function" === typeof c && (n = ha, d = c);
      c = h(d);
      var q = ia(n);
      n = e(q.flag);
      d = t(q.mode);
      b = r(b, q.encoding);
      this.wrapAsync(this.writeFileBase, [a, b, n, d], c);
    };

    a.prototype.linkBase = function (a, b) {
      var c = m(a),
          e = this.getLink(c);
      if (!e) throw d("ENOENT", "link", a, b);
      var n = m(b);
      c = this.getLinkParent(n);
      if (!c) throw d("ENOENT", "link", a, b);
      n = n[n.length - 1];
      if (c.getChild(n)) throw d("EEXIST", "link", a, b);
      a = e.getNode();
      a.nlink++;
      c.createChild(n, a);
    };

    a.prototype.copyFileBase = function (a, b, c) {
      var e = this.readFileSync(a);
      if (c & wa && this.existsSync(b)) throw d("EEXIST", "copyFile", a, b);
      if (c & xa) throw d("ENOSYS", "copyFile", a, b);
      this.writeFileBase(b, e, I.w, 438);
    };

    a.prototype.copyFileSync = function (a, b, c) {
      a = k(a);
      b = k(b);
      return this.copyFileBase(a, b, (c || 0) | 0);
    };

    a.prototype.copyFile = function (a, b, c, d) {
      a = k(a);
      b = k(b);
      if ("function" === typeof c) var e = 0;else e = c, c = d;
      h(c);
      this.wrapAsync(this.copyFileBase, [a, b, e], c);
    };

    a.prototype.linkSync = function (a, b) {
      a = k(a);
      b = k(b);
      this.linkBase(a, b);
    };

    a.prototype.link = function (a, b, c) {
      a = k(a);
      b = k(b);
      this.wrapAsync(this.linkBase, [a, b], c);
    };

    a.prototype.unlinkBase = function (a) {
      var b = m(a);
      b = this.getLink(b);
      if (!b) throw d("ENOENT", "unlink", a);
      if (b.length) throw Error("Dir not empty...");
      this.deleteLink(b);
      a = b.getNode();
      a.nlink--;
      0 >= a.nlink && this.deleteNode(a);
    };

    a.prototype.unlinkSync = function (a) {
      a = k(a);
      this.unlinkBase(a);
    };

    a.prototype.unlink = function (a, b) {
      a = k(a);
      this.wrapAsync(this.unlinkBase, [a], b);
    };

    a.prototype.symlinkBase = function (a, b) {
      var c = m(b),
          e = this.getLinkParent(c);
      if (!e) throw d("ENOENT", "symlink", a, b);
      c = c[c.length - 1];
      if (e.getChild(c)) throw d("EEXIST", "symlink", a, b);
      b = e.createChild(c);
      b.getNode().makeSymlink(m(a));
      return b;
    };

    a.prototype.symlinkSync = function (a, b, c) {
      a = k(a);
      b = k(b);
      this.symlinkBase(a, b);
    };

    a.prototype.symlink = function (a, b, c, d) {
      c = h("function" === typeof c ? c : d);
      a = k(a);
      b = k(b);
      this.wrapAsync(this.symlinkBase, [a, b], c);
    };

    a.prototype.realpathBase = function (a, b) {
      var c = m(a);
      c = this.getResolvedLink(c);
      if (!c) throw d("ENOENT", "realpath", a);
      return encoding.strToEncoding(c.getPath(), b);
    };

    a.prototype.realpathSync = function (a, b) {
      return this.realpathBase(k(a), la(b).encoding);
    };

    a.prototype.realpath = function (a, b, c) {
      c = za(b, c);
      b = c[0];
      c = c[1];
      a = k(a);
      this.wrapAsync(this.realpathBase, [a, b.encoding], c);
    };

    a.prototype.lstatBase = function (a, b) {
      void 0 === b && (b = !1);
      var c = this.getLink(m(a));
      if (!c) throw d("ENOENT", "lstat", a);
      return Stats_1.default.build(c.getNode(), b);
    };

    a.prototype.lstatSync = function (a, b) {
      return this.lstatBase(k(a), R(b).bigint);
    };

    a.prototype.lstat = function (a, b, c) {
      c = Z(b, c);
      b = c[0];
      c = c[1];
      this.wrapAsync(this.lstatBase, [k(a), b.bigint], c);
    };

    a.prototype.statBase = function (a, b) {
      void 0 === b && (b = !1);
      var c = this.getResolvedLink(m(a));
      if (!c) throw d("ENOENT", "stat", a);
      return Stats_1.default.build(c.getNode(), b);
    };

    a.prototype.statSync = function (a, b) {
      return this.statBase(k(a), R(b).bigint);
    };

    a.prototype.stat = function (a, b, c) {
      c = Z(b, c);
      b = c[0];
      c = c[1];
      this.wrapAsync(this.statBase, [k(a), b.bigint], c);
    };

    a.prototype.fstatBase = function (a, b) {
      void 0 === b && (b = !1);
      a = this.getFileByFd(a);
      if (!a) throw d("EBADF", "fstat");
      return Stats_1.default.build(a.node, b);
    };

    a.prototype.fstatSync = function (a, b) {
      return this.fstatBase(a, R(b).bigint);
    };

    a.prototype.fstat = function (a, b, c) {
      b = Z(b, c);
      this.wrapAsync(this.fstatBase, [a, b[0].bigint], b[1]);
    };

    a.prototype.renameBase = function (a, b) {
      var c = this.getLink(m(a));
      if (!c) throw d("ENOENT", "rename", a, b);
      var e = m(b),
          f = this.getLinkParent(e);
      if (!f) throw d("ENOENT", "rename", a, b);
      (a = c.parent) && a.deleteChild(c);
      c.steps = X(f.steps, [e[e.length - 1]]);
      f.setChild(c.getName(), c);
    };

    a.prototype.renameSync = function (a, b) {
      a = k(a);
      b = k(b);
      this.renameBase(a, b);
    };

    a.prototype.rename = function (a, b, c) {
      a = k(a);
      b = k(b);
      this.wrapAsync(this.renameBase, [a, b], c);
    };

    a.prototype.existsBase = function (a) {
      return !!this.statBase(a);
    };

    a.prototype.existsSync = function (a) {
      try {
        return this.existsBase(k(a));
      } catch (q) {
        return !1;
      }
    };

    a.prototype.exists = function (a, b) {
      var c = this,
          d = k(a);
      if ("function" !== typeof b) throw Error(E.CB);
      setImmediate_1.default(function () {
        try {
          b(c.existsBase(d));
        } catch (F) {
          b(!1);
        }
      });
    };

    a.prototype.accessBase = function (a, b) {
      this.getLinkOrThrow(a, "access");
    };

    a.prototype.accessSync = function (a, b) {
      void 0 === b && (b = ca);
      a = k(a);
      this.accessBase(a, b | 0);
    };

    a.prototype.access = function (a, b, c) {
      var d = ca;
      "function" !== typeof b && (d = b | 0, b = h(c));
      a = k(a);
      this.wrapAsync(this.accessBase, [a, d], b);
    };

    a.prototype.appendFileSync = function (a, b, c) {
      void 0 === c && (c = ja);
      c = ka(c);
      c.flag && a >>> 0 !== a || (c.flag = "a");
      this.writeFileSync(a, b, c);
    };

    a.prototype.appendFile = function (a, b, c, d) {
      d = ya(c, d);
      c = d[0];
      d = d[1];
      c.flag && a >>> 0 !== a || (c.flag = "a");
      this.writeFile(a, b, c, d);
    };

    a.prototype.readdirBase = function (a, b) {
      var c = m(a);
      c = this.getResolvedLink(c);
      if (!c) throw d("ENOENT", "readdir", a);
      if (!c.getNode().isDirectory()) throw d("ENOTDIR", "scandir", a);

      if (b.withFileTypes) {
        var e = [];

        for (f in c.children) (a = c.getChild(f)) && e.push(Dirent_1.default.build(a, b.encoding));

        Y || "buffer" === b.encoding || e.sort(function (a, b) {
          return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
        });
        return e;
      }

      var f = [];

      for (e in c.children) f.push(encoding.strToEncoding(e, b.encoding));

      Y || "buffer" === b.encoding || f.sort();
      return f;
    };

    a.prototype.readdirSync = function (a, b) {
      b = pa(b);
      a = k(a);
      return this.readdirBase(a, b);
    };

    a.prototype.readdir = function (a, b, c) {
      c = Aa(b, c);
      b = c[0];
      c = c[1];
      a = k(a);
      this.wrapAsync(this.readdirBase, [a, b], c);
    };

    a.prototype.readlinkBase = function (a, b) {
      var c = this.getLinkOrThrow(a, "readlink").getNode();
      if (!c.isSymlink()) throw d("EINVAL", "readlink", a);
      a = y + c.symlink.join(y);
      return encoding.strToEncoding(a, b);
    };

    a.prototype.readlinkSync = function (a, b) {
      b = W(b);
      a = k(a);
      return this.readlinkBase(a, b.encoding);
    };

    a.prototype.readlink = function (a, b, c) {
      c = ea(b, c);
      b = c[0];
      c = c[1];
      a = k(a);
      this.wrapAsync(this.readlinkBase, [a, b.encoding], c);
    };

    a.prototype.fsyncBase = function (a) {
      this.getFileByFdOrThrow(a, "fsync");
    };

    a.prototype.fsyncSync = function (a) {
      this.fsyncBase(a);
    };

    a.prototype.fsync = function (a, b) {
      this.wrapAsync(this.fsyncBase, [a], b);
    };

    a.prototype.fdatasyncBase = function (a) {
      this.getFileByFdOrThrow(a, "fdatasync");
    };

    a.prototype.fdatasyncSync = function (a) {
      this.fdatasyncBase(a);
    };

    a.prototype.fdatasync = function (a, b) {
      this.wrapAsync(this.fdatasyncBase, [a], b);
    };

    a.prototype.ftruncateBase = function (a, b) {
      this.getFileByFdOrThrow(a, "ftruncate").truncate(b);
    };

    a.prototype.ftruncateSync = function (a, b) {
      this.ftruncateBase(a, b);
    };

    a.prototype.ftruncate = function (a, b, c) {
      var d = "number" === typeof b ? b : 0;
      b = h("number" === typeof b ? c : b);
      this.wrapAsync(this.ftruncateBase, [a, d], b);
    };

    a.prototype.truncateBase = function (a, b) {
      a = this.openSync(a, "r+");

      try {
        this.ftruncateSync(a, b);
      } finally {
        this.closeSync(a);
      }
    };

    a.prototype.truncateSync = function (a, b) {
      if (a >>> 0 === a) return this.ftruncateSync(a, b);
      this.truncateBase(a, b);
    };

    a.prototype.truncate = function (a, b, c) {
      var d = "number" === typeof b ? b : 0;
      b = h("number" === typeof b ? c : b);
      if (a >>> 0 === a) return this.ftruncate(a, d, b);
      this.wrapAsync(this.truncateBase, [a, d], b);
    };

    a.prototype.futimesBase = function (a, b, c) {
      a = this.getFileByFdOrThrow(a, "futimes").node;
      a.atime = new Date(1000 * b);
      a.mtime = new Date(1000 * c);
    };

    a.prototype.futimesSync = function (a, b, c) {
      this.futimesBase(a, D(b), D(c));
    };

    a.prototype.futimes = function (a, b, c, d) {
      this.wrapAsync(this.futimesBase, [a, D(b), D(c)], d);
    };

    a.prototype.utimesBase = function (a, b, c) {
      a = this.openSync(a, "r+");

      try {
        this.futimesBase(a, b, c);
      } finally {
        this.closeSync(a);
      }
    };

    a.prototype.utimesSync = function (a, b, c) {
      this.utimesBase(k(a), D(b), D(c));
    };

    a.prototype.utimes = function (a, b, c, d) {
      this.wrapAsync(this.utimesBase, [k(a), D(b), D(c)], d);
    };

    a.prototype.mkdirBase = function (a, b) {
      var c = m(a);
      if (!c.length) throw d("EISDIR", "mkdir", a);
      var e = this.getLinkParentAsDirOrThrow(a, "mkdir");
      c = c[c.length - 1];
      if (e.getChild(c)) throw d("EEXIST", "mkdir", a);
      e.createChild(c, this.createNode(!0, b));
    };

    a.prototype.mkdirpBase = function (a, b) {
      a = m(a);

      for (var c = this.root, e = 0; e < a.length; e++) {
        var f = a[e];
        if (!c.getNode().isDirectory()) throw d("ENOTDIR", "mkdir", c.getPath());
        var g = c.getChild(f);
        if (g) {
          if (g.getNode().isDirectory()) c = g;else throw d("ENOTDIR", "mkdir", g.getPath());
        } else c = c.createChild(f, this.createNode(!0, b));
      }
    };

    a.prototype.mkdirSync = function (a, b) {
      b = na(b);
      var c = t(b.mode, 511);
      a = k(a);
      b.recursive ? this.mkdirpBase(a, c) : this.mkdirBase(a, c);
    };

    a.prototype.mkdir = function (a, b, c) {
      var d = na(b);
      b = h("function" === typeof b ? b : c);
      c = t(d.mode, 511);
      a = k(a);
      d.recursive ? this.wrapAsync(this.mkdirpBase, [a, c], b) : this.wrapAsync(this.mkdirBase, [a, c], b);
    };

    a.prototype.mkdirpSync = function (a, b) {
      this.mkdirSync(a, {
        mode: b,
        recursive: !0
      });
    };

    a.prototype.mkdirp = function (a, b, c) {
      var d = "function" === typeof b ? void 0 : b;
      b = h("function" === typeof b ? b : c);
      this.mkdir(a, {
        mode: d,
        recursive: !0
      }, b);
    };

    a.prototype.mkdtempBase = function (a, b, c) {
      void 0 === c && (c = 5);
      var d = a + this.genRndStr();

      try {
        return this.mkdirBase(d, 511), encoding.strToEncoding(d, b);
      } catch (F) {
        if ("EEXIST" === F.code) {
          if (1 < c) return this.mkdtempBase(a, b, c - 1);
          throw Error("Could not create temp dir.");
        }

        throw F;
      }
    };

    a.prototype.mkdtempSync = function (a, b) {
      b = W(b).encoding;
      if (!a || "string" !== typeof a) throw new TypeError("filename prefix is required");
      B(a);
      return this.mkdtempBase(a, b);
    };

    a.prototype.mkdtemp = function (a, b, c) {
      c = ea(b, c);
      b = c[0].encoding;
      c = c[1];
      if (!a || "string" !== typeof a) throw new TypeError("filename prefix is required");
      B(a) && this.wrapAsync(this.mkdtempBase, [a, b], c);
    };

    a.prototype.rmdirBase = function (a, b) {
      b = C({}, oa, b);
      var c = this.getLinkAsDirOrThrow(a, "rmdir");
      if (c.length && !b.recursive) throw d("ENOTEMPTY", "rmdir", a);
      this.deleteLink(c);
    };

    a.prototype.rmdirSync = function (a, b) {
      this.rmdirBase(k(a), b);
    };

    a.prototype.rmdir = function (a, b, c) {
      var d = C({}, oa, b);
      b = h("function" === typeof b ? b : c);
      this.wrapAsync(this.rmdirBase, [k(a), d], b);
    };

    a.prototype.fchmodBase = function (a, b) {
      this.getFileByFdOrThrow(a, "fchmod").chmod(b);
    };

    a.prototype.fchmodSync = function (a, b) {
      this.fchmodBase(a, t(b));
    };

    a.prototype.fchmod = function (a, b, c) {
      this.wrapAsync(this.fchmodBase, [a, t(b)], c);
    };

    a.prototype.chmodBase = function (a, b) {
      a = this.openSync(a, "r+");

      try {
        this.fchmodBase(a, b);
      } finally {
        this.closeSync(a);
      }
    };

    a.prototype.chmodSync = function (a, b) {
      b = t(b);
      a = k(a);
      this.chmodBase(a, b);
    };

    a.prototype.chmod = function (a, b, c) {
      b = t(b);
      a = k(a);
      this.wrapAsync(this.chmodBase, [a, b], c);
    };

    a.prototype.lchmodBase = function (a, b) {
      a = this.openBase(a, H, 0, !1);

      try {
        this.fchmodBase(a, b);
      } finally {
        this.closeSync(a);
      }
    };

    a.prototype.lchmodSync = function (a, b) {
      b = t(b);
      a = k(a);
      this.lchmodBase(a, b);
    };

    a.prototype.lchmod = function (a, b, c) {
      b = t(b);
      a = k(a);
      this.wrapAsync(this.lchmodBase, [a, b], c);
    };

    a.prototype.fchownBase = function (a, b, c) {
      this.getFileByFdOrThrow(a, "fchown").chown(b, c);
    };

    a.prototype.fchownSync = function (a, b, c) {
      K(b);
      L(c);
      this.fchownBase(a, b, c);
    };

    a.prototype.fchown = function (a, b, c, d) {
      K(b);
      L(c);
      this.wrapAsync(this.fchownBase, [a, b, c], d);
    };

    a.prototype.chownBase = function (a, b, c) {
      this.getResolvedLinkOrThrow(a, "chown").getNode().chown(b, c);
    };

    a.prototype.chownSync = function (a, b, c) {
      K(b);
      L(c);
      this.chownBase(k(a), b, c);
    };

    a.prototype.chown = function (a, b, c, d) {
      K(b);
      L(c);
      this.wrapAsync(this.chownBase, [k(a), b, c], d);
    };

    a.prototype.lchownBase = function (a, b, c) {
      this.getLinkOrThrow(a, "lchown").getNode().chown(b, c);
    };

    a.prototype.lchownSync = function (a, b, c) {
      K(b);
      L(c);
      this.lchownBase(k(a), b, c);
    };

    a.prototype.lchown = function (a, b, c, d) {
      K(b);
      L(c);
      this.wrapAsync(this.lchownBase, [k(a), b, c], d);
    };

    a.prototype.watchFile = function (a, b, c) {
      a = k(a);
      var d = b;
      "function" === typeof d && (c = b, d = null);
      if ("function" !== typeof c) throw Error("\"watchFile()\" requires a listener function");
      b = 5007;
      var e = !0;
      d && "object" === typeof d && ("number" === typeof d.interval && (b = d.interval), "boolean" === typeof d.persistent && (e = d.persistent));
      d = this.statWatchers[a];
      d || (d = new this.StatWatcher(), d.start(a, e, b), this.statWatchers[a] = d);
      d.addListener("change", c);
      return d;
    };

    a.prototype.unwatchFile = function (a, b) {
      a = k(a);
      var c = this.statWatchers[a];
      c && ("function" === typeof b ? c.removeListener("change", b) : c.removeAllListeners("change"), 0 === c.listenerCount("change") && (c.stop(), delete this.statWatchers[a]));
    };

    a.prototype.createReadStream = function (a, b) {
      return new this.ReadStream(a, b);
    };

    a.prototype.createWriteStream = function (a, b) {
      return new this.WriteStream(a, b);
    };

    a.prototype.watch = function (a, b, c) {
      a = k(a);
      var d = b;
      "function" === typeof b && (c = b, d = null);
      var e = W(d);
      b = e.persistent;
      d = e.recursive;
      e = e.encoding;
      void 0 === b && (b = !0);
      void 0 === d && (d = !1);
      var f = new this.FSWatcher();
      f.start(a, b, d, e);
      c && f.addListener("change", c);
      return f;
    };

    a.fd = 2147483647;
    return a;
  }();

  b.Volume = a;

  var qa = function (a) {
    function b(b) {
      var c = a.call(this) || this;

      c.onInterval = function () {
        try {
          var a = c.vol.statSync(c.filename);
          c.hasChanged(a) && (c.emit("change", a, c.prev), c.prev = a);
        } finally {
          c.loop();
        }
      };

      c.vol = b;
      return c;
    }

    M(b, a);

    b.prototype.loop = function () {
      this.timeoutRef = this.setTimeout(this.onInterval, this.interval);
    };

    b.prototype.hasChanged = function (a) {
      return a.mtimeMs > this.prev.mtimeMs || a.nlink !== this.prev.nlink ? !0 : !1;
    };

    b.prototype.start = function (a, b, c) {
      void 0 === b && (b = !0);
      void 0 === c && (c = 5007);
      this.filename = k(a);
      this.setTimeout = b ? setTimeout : setTimeoutUnref_1.default;
      this.interval = c;
      this.prev = this.vol.statSync(this.filename);
      this.loop();
    };

    b.prototype.stop = function () {
      clearTimeout(this.timeoutRef);
      process_1.default.nextTick(sa, this);
    };

    return b;
  }(EventEmitter.EventEmitter);

  b.StatWatcher = qa;
  var w;
  util.inherits(z, Stream.Readable);
  b.ReadStream = z;

  z.prototype.open = function () {
    var a = this;

    this._vol.open(this.path, this.flags, this.mode, function (b, c) {
      b ? (a.autoClose && a.destroy && a.destroy(), a.emit("error", b)) : (a.fd = c, a.emit("open", c), a.read());
    });
  };

  z.prototype._read = function (a) {
    if ("number" !== typeof this.fd) return this.once("open", function () {
      this._read(a);
    });

    if (!this.destroyed) {
      if (!w || 128 > w.length - w.used) w = buffer.bufferAllocUnsafe(this._readableState.highWaterMark), w.used = 0;
      var b = w,
          c = Math.min(w.length - w.used, a),
          d = w.used;
      void 0 !== this.pos && (c = Math.min(this.end - this.pos + 1, c));
      if (0 >= c) return this.push(null);
      var e = this;

      this._vol.read(this.fd, w, w.used, c, this.pos, function (a, c) {
        a ? (e.autoClose && e.destroy && e.destroy(), e.emit("error", a)) : (a = null, 0 < c && (e.bytesRead += c, a = b.slice(d, d + c)), e.push(a));
      });

      void 0 !== this.pos && (this.pos += c);
      w.used += c;
    }
  };

  z.prototype._destroy = function (a, b) {
    this.close(function (c) {
      b(a || c);
    });
  };

  z.prototype.close = function (a) {
    var b = this;
    if (a) this.once("close", a);

    if (this.closed || "number" !== typeof this.fd) {
      if ("number" !== typeof this.fd) {
        this.once("open", ta);
        return;
      }

      return process_1.default.nextTick(function () {
        return b.emit("close");
      });
    }

    this.closed = !0;

    this._vol.close(this.fd, function (a) {
      a ? b.emit("error", a) : b.emit("close");
    });

    this.fd = null;
  };

  util.inherits(x, Stream.Writable);
  b.WriteStream = x;

  x.prototype.open = function () {
    this._vol.open(this.path, this.flags, this.mode, function (a, b) {
      a ? (this.autoClose && this.destroy && this.destroy(), this.emit("error", a)) : (this.fd = b, this.emit("open", b));
    }.bind(this));
  };

  x.prototype._write = function (a, b, c) {
    if (!(a instanceof buffer.Buffer)) return this.emit("error", Error("Invalid data"));
    if ("number" !== typeof this.fd) return this.once("open", function () {
      this._write(a, b, c);
    });
    var d = this;

    this._vol.write(this.fd, a, 0, a.length, this.pos, function (a, b) {
      if (a) return d.autoClose && d.destroy && d.destroy(), c(a);
      d.bytesWritten += b;
      c();
    });

    void 0 !== this.pos && (this.pos += a.length);
  };

  x.prototype._writev = function (a, b) {
    if ("number" !== typeof this.fd) return this.once("open", function () {
      this._writev(a, b);
    });

    for (var c = this, d = a.length, e = Array(d), f = 0, g = 0; g < d; g++) {
      var h = a[g].chunk;
      e[g] = h;
      f += h.length;
    }

    d = buffer.Buffer.concat(e);

    this._vol.write(this.fd, d, 0, d.length, this.pos, function (a, d) {
      if (a) return c.destroy && c.destroy(), b(a);
      c.bytesWritten += d;
      b();
    });

    void 0 !== this.pos && (this.pos += f);
  };

  x.prototype._destroy = z.prototype._destroy;
  x.prototype.close = z.prototype.close;
  x.prototype.destroySoon = x.prototype.end;

  var ra = function (a) {
    function b(b) {
      var c = a.call(this) || this;
      c._filename = "";
      c._filenameEncoded = "";
      c._recursive = !1;
      c._encoding = encoding.ENCODING_UTF8;

      c._onNodeChange = function () {
        c._emit("change");
      };

      c._onParentChild = function (a) {
        a.getName() === c._getName() && c._emit("rename");
      };

      c._emit = function (a) {
        c.emit("change", a, c._filenameEncoded);
      };

      c._persist = function () {
        c._timer = setTimeout(c._persist, 1000000);
      };

      c._vol = b;
      return c;
    }

    M(b, a);

    b.prototype._getName = function () {
      return this._steps[this._steps.length - 1];
    };

    b.prototype.start = function (a, b, c, d) {
      void 0 === b && (b = !0);
      void 0 === c && (c = !1);
      void 0 === d && (d = encoding.ENCODING_UTF8);
      this._filename = k(a);
      this._steps = m(this._filename);
      this._filenameEncoded = encoding.strToEncoding(this._filename);
      this._recursive = c;
      this._encoding = d;

      try {
        this._link = this._vol.getLinkOrThrow(this._filename, "FSWatcher");
      } catch (J) {
        throw b = Error("watch " + this._filename + " " + J.code), b.code = J.code, b.errno = J.code, b;
      }

      this._link.getNode().on("change", this._onNodeChange);

      this._link.on("child:add", this._onNodeChange);

      this._link.on("child:delete", this._onNodeChange);

      if (a = this._link.parent) a.setMaxListeners(a.getMaxListeners() + 1), a.on("child:delete", this._onParentChild);
      b && this._persist();
    };

    b.prototype.close = function () {
      clearTimeout(this._timer);

      this._link.getNode().removeListener("change", this._onNodeChange);

      var a = this._link.parent;
      a && a.removeListener("child:delete", this._onParentChild);
    };

    return b;
  }(EventEmitter.EventEmitter);

  b.FSWatcher = ra;
});
unwrapExports(volume);
var volume_1 = volume.FLAGS,
    volume_2 = volume.flagsToNumber,
    volume_3 = volume.pathToFilename,
    volume_4 = volume.filenameToSteps,
    volume_5 = volume.pathToSteps,
    volume_6 = volume.dataToStr,
    volume_7 = volume.dataToBuffer,
    volume_8 = volume.bufferToEncoding,
    volume_9 = volume.toUnixTimestamp,
    volume_10 = volume.Volume,
    volume_11 = volume.StatWatcher,
    volume_12 = volume.ReadStream,
    volume_13 = volume.WriteStream,
    volume_14 = volume.FSWatcher,
    lists = createCommonjsModule(function (a, b) {
  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  b.fsProps = "constants F_OK R_OK W_OK X_OK Stats".split(" ");
  b.fsSyncMethods = "renameSync ftruncateSync truncateSync chownSync fchownSync lchownSync chmodSync fchmodSync lchmodSync statSync lstatSync fstatSync linkSync symlinkSync readlinkSync realpathSync unlinkSync rmdirSync mkdirSync mkdirpSync readdirSync closeSync openSync utimesSync futimesSync fsyncSync writeSync readSync readFileSync writeFileSync appendFileSync existsSync accessSync fdatasyncSync mkdtempSync copyFileSync createReadStream createWriteStream".split(" ");
  b.fsAsyncMethods = "rename ftruncate truncate chown fchown lchown chmod fchmod lchmod stat lstat fstat link symlink readlink realpath unlink rmdir mkdir mkdirp readdir close open utimes futimes fsync write read readFile writeFile appendFile exists access fdatasync mkdtemp copyFile watchFile unwatchFile watch".split(" ");
});
unwrapExports(lists);
var lists_1 = lists.fsProps,
    lists_2 = lists.fsSyncMethods,
    lists_3 = lists.fsAsyncMethods,
    lib = createCommonjsModule(function (a, b) {
  function c(a) {
    for (var b = {
      F_OK: g,
      R_OK: h,
      W_OK: l,
      X_OK: k,
      constants: constants.constants,
      Stats: Stats_1.default,
      Dirent: Dirent_1.default
    }, c = 0, d = e; c < d.length; c++) {
      var m = d[c];
      "function" === typeof a[m] && (b[m] = a[m].bind(a));
    }

    c = 0;

    for (d = f; c < d.length; c++) m = d[c], "function" === typeof a[m] && (b[m] = a[m].bind(a));

    b.StatWatcher = a.StatWatcher;
    b.FSWatcher = a.FSWatcher;
    b.WriteStream = a.WriteStream;
    b.ReadStream = a.ReadStream;
    b.promises = a.promises;
    b._toUnixTimestamp = volume.toUnixTimestamp;
    return b;
  }

  var d = commonjsGlobal && commonjsGlobal.__assign || function () {
    d = Object.assign || function (a) {
      for (var b, c = 1, d = arguments.length; c < d; c++) {
        b = arguments[c];

        for (var e in b) Object.prototype.hasOwnProperty.call(b, e) && (a[e] = b[e]);
      }

      return a;
    };

    return d.apply(this, arguments);
  };

  Object.defineProperty(b, "__esModule", {
    value: !0
  });
  var e = lists.fsSyncMethods,
      f = lists.fsAsyncMethods,
      g = constants.constants.F_OK,
      h = constants.constants.R_OK,
      l = constants.constants.W_OK,
      k = constants.constants.X_OK;
  b.Volume = volume.Volume;
  b.vol = new volume.Volume();
  b.createFsFromVolume = c;
  b.fs = c(b.vol);
  a.exports = d(d({}, a.exports), b.fs);
  a.exports.semantic = !0;
});
unwrapExports(lib);
var lib_1 = lib.Volume,
    lib_2 = lib.vol,
    lib_3 = lib.createFsFromVolume,
    lib_4 = lib.fs,
    lib_5 = lib.semantic;

node_2.prototype.emit = function (a) {
  for (var b, c, d = [], e = 1; e < arguments.length; e++) d[e - 1] = arguments[e];

  e = this.listeners(a);

  try {
    for (var f = __values(e), g = f.next(); !g.done; g = f.next()) {
      var h = g.value;

      try {
        h.apply(void 0, __spread(d));
      } catch (l) {
        console.error(l);
      }
    }
  } catch (l) {
    b = {
      error: l
    };
  } finally {
    try {
      g && !g.done && (c = f.return) && c.call(f);
    } finally {
      if (b) throw b.error;
    }
  }

  return 0 < e.length;
};

var assert$1 = function (a, b) {
  if (!a) throw Error(b);
},
    WasmFsDefault = function () {
  function a() {
    this.volume = new volume_10();
    this.fs = lib_3(this.volume);
    this.fromJSON({
      "/dev/stdin": "",
      "/dev/stdout": "",
      "/dev/stderr": ""
    });
  }

  a.prototype._toJSON = function (a, c, d) {
    void 0 === c && (c = {});
    var b = !0,
        f;

    for (f in a.children) {
      b = !1;
      var g = a.getChild(f);

      if (g) {
        var h = g.getNode();
        h && h.isFile() ? (g = g.getPath(), d && (g = relative(d, g)), c[g] = h.getBuffer()) : h && h.isDirectory() && this._toJSON(g, c, d);
      }
    }

    a = a.getPath();
    d && (a = relative(d, a));
    a && b && (c[a] = null);
    return c;
  };

  a.prototype.toJSON = function (a, c, d) {
    var b, f;
    void 0 === c && (c = {});
    void 0 === d && (d = !1);
    var g = [];

    if (a) {
      a instanceof Array || (a = [a]);

      try {
        for (var h = __values(a), l = h.next(); !l.done; l = h.next()) {
          var k = volume_3(l.value),
              m = this.volume.getResolvedLink(k);
          m && g.push(m);
        }
      } catch (t) {
        var p = {
          error: t
        };
      } finally {
        try {
          l && !l.done && (b = h.return) && b.call(h);
        } finally {
          if (p) throw p.error;
        }
      }
    } else g.push(this.volume.root);

    if (!g.length) return c;

    try {
      for (var r = __values(g), u = r.next(); !u.done; u = r.next()) m = u.value, this._toJSON(m, c, d ? m.getPath() : "");
    } catch (t) {
      var B = {
        error: t
      };
    } finally {
      try {
        u && !u.done && (f = r.return) && f.call(r);
      } finally {
        if (B) throw B.error;
      }
    }

    return c;
  };

  a.prototype.fromJSONFixed = function (a, c) {
    for (var b in c) {
      var e = c[b];

      if (e ? null !== Object.getPrototypeOf(e) : null !== e) {
        var f = volume_4(b);
        1 < f.length && (f = "/" + f.slice(0, f.length - 1).join("/"), a.mkdirpBase(f, 511));
        a.writeFileSync(b, e || "");
      } else a.mkdirpBase(b, 511);
    }
  };

  a.prototype.fromJSON = function (a) {
    this.volume = new volume_10();
    this.fromJSONFixed(this.volume, a);
    this.fs = lib_3(this.volume);
    this.volume.releasedFds = [0, 1, 2];
    a = this.volume.openSync("/dev/stderr", "w");
    var b = this.volume.openSync("/dev/stdout", "w"),
        d = this.volume.openSync("/dev/stdin", "r");
    assert$1(2 === a, "invalid handle for stderr: " + a);
    assert$1(1 === b, "invalid handle for stdout: " + b);
    assert$1(0 === d, "invalid handle for stdin: " + d);
  };

  a.prototype.getStdOut = function () {
    return __awaiter(this, void 0, void 0, function () {
      var a,
          c = this;
      return __generator(this, function (b) {
        a = new Promise(function (a) {
          a(c.fs.readFileSync("/dev/stdout", "utf8"));
        });
        return [2, a];
      });
    });
  };

  return a;
}(),
    WasmFs = WasmFsDefault;

exports.WasmFs = WasmFs;
exports.default = WasmFsDefault;
export default exports;
export const __esModule = exports.__esModule;
const _WasmFs = exports.WasmFs;
export { _WasmFs as WasmFs };